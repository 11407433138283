/* eslint-disable */
import { Apprising } from 'src/../../Common/Model/apprising';

export const getSidebarApprisingCount = (apprs: Apprising[], href: string): number => {
  return apprs.filter((appr) => appr.href === href).length;
};

export const getNavbarApprisingCount = (apprs: Apprising[], href: string): number => {
  return apprs.filter((appr) => appr.href.startsWith(href)).length;
};
