import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { RFQ, Contract, Proposal, PO, Schedule, Invoice } from 'src/../../Common/Model/procurement';
import type { Request } from 'src/../../Common/Model/request';
import { deepCopy } from 'src/utils/copyObject';
import { ACCZIOM_TEAM } from 'src/globals';

interface ProcurementState {
  rfqReload: boolean,
  RFQs: RFQ[],
  fromAssetIds: string[],
  contractReload: boolean,
  contracts: Contract[],
  fromRequest: Request,
  fromProposal: Proposal,
  poReload: boolean,
  POs: PO[],
  scheduleReload: boolean,
  schedules: Schedule[],
  invoiceReload: boolean,
  invoices: Invoice[]
}

const initialState: ProcurementState = {
  rfqReload: true,
  RFQs: [],
  fromAssetIds: [],
  contractReload: true,
  contracts: [],
  fromRequest: null,
  fromProposal: null,
  poReload: true,
  POs: [],
  scheduleReload: true,
  schedules: [],
  invoiceReload: true,
  invoices: []
};

const slice = createSlice({
  name: 'procurement',
  initialState,
  reducers: {
    clearState(state: ProcurementState) {
      state.RFQs.splice(0, state.RFQs.length);
      state.rfqReload = true;
      state.fromAssetIds.splice(0, state.fromAssetIds.length);
      state.contracts.splice(0, state.contracts.length);
      state.contractReload = true;
      state.fromRequest = null;
      state.fromProposal = null;
      state.POs.splice(0, state.POs.length);
      state.poReload = true;
      state.schedules.splice(0, state.schedules.length);
      state.scheduleReload = true;
      state.invoices.splice(0, state.invoices.length);
      state.invoiceReload = true;
    },
    setRFQs(state: ProcurementState, action: PayloadAction<{ rfqs: RFQ[]; }>) {
      const { rfqs } = action.payload;
      state.RFQs = [...rfqs];
      state.rfqReload = false;
    },
    addNewRFQ(state: ProcurementState, action: PayloadAction<{ rfq: RFQ }>) {
      const { rfq } = action.payload;
      state.RFQs = [rfq].concat(state.RFQs);
    },
    updateRFQ(state: ProcurementState, action: PayloadAction<{ rfq: RFQ; }>) {
      const { rfq } = action.payload;
      const index = state.RFQs.findIndex((item) => item.id === rfq.id);
      if (index > -1) state.RFQs[index] = rfq;
      else state.RFQs.push(rfq);
    },
    removeRFQ(state: ProcurementState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.RFQs.findIndex((item) => item.id === id);
      state.RFQs.splice(index, 1);
    },
    setRFQReload(state: ProcurementState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.rfqReload = needReload;
    },
    setFromAssetIds(state: ProcurementState, action: PayloadAction<string[]>): void {
      state.fromAssetIds = action.payload;
    },
    clearProcurementSlice(state: ProcurementState) {
      state.RFQs = [];
      state.rfqReload = true;
      state.fromAssetIds = [];
      state.contracts = [];
      state.contractReload = true;
      state.fromRequest = null;
      state.fromProposal = null;
      state.POs = [];
      state.poReload = true;
      state.schedules = [];
      state.scheduleReload = true;
      state.invoices = [];
      state.invoiceReload = true;
    },
    setRFQAgents(state: ProcurementState, action: PayloadAction<{ orgId: string, assignedTeams: any }>): void {
      const { orgId, assignedTeams } = action.payload;
      const updatedRFQs = deepCopy(state.RFQs);
      assignedTeams.forEach(({ id, teamId }) => {
        const rfq = updatedRFQs.find((info) => info.id === id);
        const idx = rfq.partners.findIndex((part) => part.id === orgId);
        rfq.partnerAgents[idx] = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.RFQs = updatedRFQs;
    },
    setContracts(state: ProcurementState, action: PayloadAction<{ contracts: Contract[]; }>) {
      const { contracts } = action.payload;
      state.contracts = [...contracts];
      state.contractReload = false;
    },
    addNewContract(state: ProcurementState, action: PayloadAction<{ contract: Contract; }>) {
      const { contract } = action.payload;
      state.contracts = [contract].concat(state.contracts);
    },
    updateContract(state: ProcurementState, action: PayloadAction<{ contract: Contract; }>) {
      const { contract } = action.payload;
      const index = state.contracts.findIndex((item) => item.id === contract.id);
      if (index > -1) state.contracts[index] = contract;
      else state.contracts.push(contract);
    },
    removeContract(state: ProcurementState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.contracts.findIndex((item) => item.id === id);
      state.contracts.splice(index, 1);
    },
    setContractReload(state: ProcurementState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.contractReload = needReload;
    },
    updateAutoDebitContract(state: ProcurementState, action: PayloadAction<any>): void {
      const { id, checked } = action.payload;
      const contract = state.contracts.find((item) => item.id === id);
      if (contract) {
        contract.itemBriefs = {
          ...contract.itemBriefs,
          payments: {
            ...contract.itemBriefs.payments,
            paymentMethod: checked ? 2 : 1
          }
        };
        contract.payment = contract.payment.map((paymentContract) => ({
          ...paymentContract,
          paymentMethod: checked ? 2 : 1
        }));
      }
    },
    setFromRequest(state: ProcurementState, action: PayloadAction<Request>): void {
      state.fromRequest = action.payload;
    },
    setFromProposal(state: ProcurementState, action: PayloadAction<Proposal>): void {
      state.fromProposal = action.payload;
    },
    setContractCustomerAgents(state: ProcurementState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.contracts);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.customerAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.contracts = updatedList;
    },
    setContractSupplierAgents(state: ProcurementState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.contracts);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.supplierAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.contracts = updatedList;
    },
    setPOs(state: ProcurementState, action: PayloadAction<{ pos: PO[]; }>) {
      const { pos } = action.payload;
      state.POs = [...pos];
      state.poReload = false;
    },
    addNewPO(state: ProcurementState, action: PayloadAction<{ po: PO }>) {
      const { po } = action.payload;
      state.POs = [po].concat(state.POs);
    },
    updatePO(state: ProcurementState, action: PayloadAction<{ po: PO; }>) {
      const { po } = action.payload;
      const index = state.POs.findIndex((item) => item.id === po.id);
      if (index > -1) state.POs[index] = po;
      else state.POs.push(po);
    },
    removePO(state: ProcurementState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.POs.findIndex((item) => item.id === id);
      state.POs.splice(index, 1);
    },
    setPOReload(state: ProcurementState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.poReload = needReload;
    },
    setPOAgents(state: ProcurementState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.POs);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.supplierAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.POs = updatedList;
    },
    setSchedules(state: ProcurementState, action: PayloadAction<{ schedules: Schedule[]; }>) {
      const { schedules } = action.payload;
      state.schedules = [...schedules];
      state.scheduleReload = false;
    },
    addNewSchedules(state: ProcurementState, action: PayloadAction<{ schedules: Schedule[] }>) {
      const { schedules } = action.payload;
      state.schedules = schedules.concat(state.schedules);
    },
    updateSchedules(state: ProcurementState, action: PayloadAction<any[]>) {
      const updateds = action.payload;
      updateds.forEach((updated) => {
        const schedule = state.schedules.find((item) => item.id === updated.id);
        if (schedule) {
          Object.keys(updated).forEach((key) => {
            if (key !== 'id') schedule[key] = updated[key];
          });
        } else state.schedules.push(updated);
      });
    },
    removeSchedules(state: ProcurementState, action: PayloadAction<string[]>) {
      const ids = action.payload;
      state.schedules = state.schedules.filter((item) => !ids.includes(item.id));
    },
    setScheduleReload(state: ProcurementState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.scheduleReload = needReload;
    },
    updateAutoDebitSchedules(state: ProcurementState, action: PayloadAction<any>): void {
      const { ids, checked } = action.payload;
      state.schedules.forEach((schedule) => {
        if (ids.includes(schedule.id)) {
          schedule.material = {
            ...schedule.material,
            paymentMethod: checked ? 2 : 1
          };
        }
      });
    },
    setScheduleDemanderAgents(state: ProcurementState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.schedules);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.customerAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.schedules = updatedList;
    },
    setScheduleSupplierAgents(state: ProcurementState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.schedules);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.supplierAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.schedules = updatedList;
    },
    setInvoices(state: ProcurementState, action: PayloadAction<{ invoices: Invoice[]; }>) {
      const { invoices } = action.payload;
      state.invoices = [...invoices];
      state.invoiceReload = false;
    },
    addNewInvoice(state: ProcurementState, action: PayloadAction<{ invoice: Invoice }>) {
      const { invoice } = action.payload;
      state.invoices = [invoice].concat(state.invoices);
    },
    updateInvoice(state: ProcurementState, action: PayloadAction<{ invoice: Invoice }>) {
      const { invoice } = action.payload;
      const index = state.invoices.findIndex((item) => item.id === invoice.id);
      if (index > -1) state.invoices[index] = invoice;
      else state.invoices.push(invoice);
    },
    updateInvoices(state: ProcurementState, action: PayloadAction<any[]>) {
      const updateds = action.payload;
      updateds.forEach((updated) => {
        const invoice = state.invoices.find((item) => item.id === updated.id);
        if (invoice) {
          Object.keys(updated).forEach((key) => {
            if (key !== 'id') invoice[key] = updated[key];
          });
        } else state.invoices.push(updated);
      });
    },
    removeInvoice(state: ProcurementState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.invoices.findIndex((item) => item.id === id);
      state.invoices.splice(index, 1);
    },
    setInvoiceReload(state: ProcurementState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.invoiceReload = needReload;
    },
    updateAutoDebitInvoices(state: ProcurementState, action: PayloadAction<any>): void {
      const { ids, checked } = action.payload;
      state.invoices.forEach((invoice) => {
        if (ids.includes(invoice.id)) {
          invoice.material = {
            ...invoice.material,
            paymentMethod: checked ? 2 : 1
          };
        }
      });
    },
    setInvoiceAgents(state: ProcurementState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.invoices);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.customerAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.invoices = updatedList;
    }
  }
});

export const { reducer } = slice;

export const clearProcurementState = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearState());
};

export const setRFQReload = (value: boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setRFQReload({ needReload }));
};
export const loadRFQs = (rfqs: RFQ[]): AppThunk => async (dispatch) => {
  rfqs.sort((b, a) => a.createdAt && a.createdAt.localeCompare(b.createdAt));
  dispatch(slice.actions.setRFQs({ rfqs }));
};
export const addNewRFQ = (rfq: RFQ): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewRFQ({ rfq }));
};
export const removeRFQ = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeRFQ({ id }));
};
export const updateRFQ = (rfq : RFQ): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRFQ({ rfq }));
};
export const setFromAssetIds = (assetIds: string[]): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setFromAssetIds(assetIds));
};
export const setRFQAgents = (orgId: string, assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRFQAgents({ orgId, assignedTeams }));
};

export const loadContracts = (contracts: Contract[]): AppThunk => async (dispatch) => {
  contracts.sort((b, a) => a.customerSignedAt && (a.customerSignedAt as string).localeCompare(b.customerSignedAt as string));
  contracts.sort((b, a) => a.supplierSignedAt && (a.supplierSignedAt as string).localeCompare(b.supplierSignedAt as string));
  contracts.sort((b, a) => a.createdAt && a.createdAt.localeCompare(b.createdAt));
  dispatch(slice.actions.setContracts({ contracts }));
};
export const addNewContract = (contract: Contract): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewContract({ contract }));
};
export const removeContract = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeContract({ id }));
};
export const updateContract = (contract : Contract): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateContract({ contract }));
};
export const setContractReload = (value:boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setContractReload({ needReload }));
};
export const updateAutoDebitContract = (id: string, checked: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAutoDebitContract({ id, checked }));
};
export const setFromRequest = (request: Request): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setFromRequest(request));
};
export const setFromProposal = (proposal: Proposal): AppThunk => (dispatch): void => {
  dispatch(slice.actions.setFromProposal(proposal));
};
export const setContractCustomerAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setContractCustomerAgents(assignedTeams));
};
export const setContractSupplierAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setContractSupplierAgents(assignedTeams));
};

export const loadPOs = (pos: PO[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPOs({ pos }));
};
export const addNewPO = (po: PO): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewPO({ po }));
};
export const removePO = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removePO({ id }));
};
export const updatePO = (po : PO): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePO({ po }));
};
export const setPOReload = (value:boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setPOReload({ needReload }));
};
export const setPOAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPOAgents(assignedTeams));
};

export const loadSchedules = (schedules: Schedule[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSchedules({ schedules }));
};
export const addNewSchedules = (schedules: Schedule[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewSchedules({ schedules }));
};
export const removeSchedules = (ids : string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeSchedules(ids));
};
export const updateSchedules = (updateds: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSchedules(updateds));
};
export const setScheduleReload = (value: boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setScheduleReload({ needReload }));
};
export const updateAutoDebitSchedules = (ids: string[], checked: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAutoDebitSchedules({ ids, checked }));
};
export const setScheduleDemanderAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setScheduleDemanderAgents(assignedTeams));
};
export const setScheduleSupplierAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setScheduleSupplierAgents(assignedTeams));
};

export const loadInvoices = (invoices): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoices({ invoices }));
};
export const addNewInvoice = (invoice: Invoice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewInvoice({ invoice }));
};
export const removeInvoice = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeInvoice({ id }));
};
export const updateInvoice = (invoice : Invoice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateInvoice({ invoice }));
};
export const updateInvoices = (updateds: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateInvoices(updateds));
};
export const setInvoiceReload = (value: boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setInvoiceReload({ needReload }));
};
export const updateAutoDebitInvoices = (ids: string[], checked: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAutoDebitInvoices({ ids, checked }));
};
export const setInvoiceAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvoiceAgents(assignedTeams));
};

export default slice;
