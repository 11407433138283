import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Invitation, Referral } from 'src/../../Common/Model/network';
import { deepCopy } from 'src/utils/copyObject';
import { ACCZIOM_TEAM } from 'src/globals';

interface NetworkState {
  invitations: Invitation[],
  invitationNeedReload: boolean,
  referrals: Referral[],
  referralNeedReload: boolean
}

const initialState: NetworkState = {
  invitations: [],
  invitationNeedReload: true,
  referrals: [],
  referralNeedReload: true
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    clearState(state: NetworkState): void {
      state.invitationNeedReload = true;
      state.invitations.splice(0, state.invitations.length);
      state.referralNeedReload = true;
      state.referrals.splice(0, state.referrals.length);
    },
    setInvitations(state: NetworkState, action: PayloadAction<Invitation[]>) {
      const invitations = action.payload;
      state.invitations = [...invitations];
      state.invitationNeedReload = false;
    },
    addNewInvitation(state: NetworkState, action: PayloadAction<{ invitations: Invitation[] }>) {
      const { invitations } = action.payload;
      state.invitations = invitations.concat(state.invitations);
    },
    updateInvitation(state: NetworkState, action: PayloadAction<Invitation>) {
      const invitation = action.payload;
      const index = state.invitations.findIndex((item) => item.id === invitation.id);
      if (index > -1) state.invitations[index] = invitation;
      else state.invitations.push(invitation);
    },
    removeInvitation(state: NetworkState, action: PayloadAction<string>) {
      const id = action.payload;
      state.invitations = state.invitations.filter((item) => item.id !== id);
    },
    setInviterAgents(state: NetworkState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.invitations);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.inviterAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.invitations = updatedList;
    },
    setInviteeAgents(state: NetworkState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.invitations);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.inviteeAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.invitations = updatedList;
    },
    setInvitationNeedReload(state: NetworkState, action: PayloadAction<boolean>) {
      state.invitationNeedReload = action.payload;
    },
    setReferrals(state: NetworkState, action: PayloadAction<Referral[]>) {
      const referrals = action.payload;
      state.referrals = [...referrals];
      state.referralNeedReload = false;
    },
    addNewReferral(state: NetworkState, action: PayloadAction<{ referrals: Referral[] }>) {
      const { referrals } = action.payload;
      state.referrals = referrals.concat(state.referrals);
    },
    updateReferral(state: NetworkState, action: PayloadAction<Referral>) {
      const referral = action.payload;
      const index = state.referrals.findIndex((item) => item.id === referral.id);
      if (index > -1) state.referrals[index] = referral;
      else state.referrals.push(referral);
    },
    removeReferral(state: NetworkState, action: PayloadAction<string>) {
      const id = action.payload;
      state.referrals = state.referrals.filter((item) => item.id !== id);
    },
    setCreatorAgents(state: NetworkState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.referrals);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.creatorAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.referrals = updatedList;
    },
    setPartnerAgents(state: NetworkState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.referrals);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.partnerAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.referrals = updatedList;
    },
    setReferralNeedReload(state: NetworkState, action: PayloadAction<boolean>) {
      state.referralNeedReload = action.payload;
    },
  }
});

export const clearNetworkState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const loadInvitations = (invitations: Invitation[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvitations(invitations));
};
export const addNewInvitation = (invitations: Invitation[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewInvitation({ invitations }));
};
export const updateInvitation = (invitation: Invitation): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateInvitation(invitation));
};
export const removeInvitation = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeInvitation(id));
};
export const setInviterAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInviterAgents(assignedTeams));
};
export const setInviteeAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInviteeAgents(assignedTeams));
};
export const setInvitationNeedReload = (needReload: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setInvitationNeedReload(needReload));
};
export const loadReferrals = (referrals: Referral[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setReferrals(referrals));
};
export const addNewReferral = (referrals: Referral[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewReferral({ referrals }));
};
export const updateReferral = (referral: Referral): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateReferral(referral));
};
export const removeReferral = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeReferral(id));
};
export const setCreatorAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCreatorAgents(assignedTeams));
};
export const setPartnerAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPartnerAgents(assignedTeams));
};
export const setReferralNeedReload = (needReload: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setReferralNeedReload(needReload));
};

export const { reducer } = slice;

export default slice;
