import { chatApi } from 'src/apis/chatApi';
import { contactApi } from 'src/apis/contactApi';
import { lambdaGetOrgActivatedMembersOfUser, lambdaGetUserInfo, lambdaFirstLoadWallet, lambdaGetOrgBannerUrl } from 'src/aws/lambdaDispatch';
import { cleanUpChat, setMessages } from 'src/slices/chat';
import { clearContactState, setConnectStateNeedReload, setContacts, setContactsNeedReload, setSyncState, setSyncStateNeedReload } from 'src/slices/contact';
import { clearUserState, LoadingStatus, setLoadingStatus, setUserInfo } from 'src/slices/user';
import { Thread } from 'src/types/chat';
import { clearWalletState, setWalletInfo } from 'src/slices/wallet';
import { setCurOrgNeedReload, setOrgStructures, setActiveOrgId, setActiveTeamId, cleanUserMinInfos, setBannerUrl, clearOrganizationState } from 'src/slices/organization';
import intervalJobs from 'src/utils/intervalJobs';
import { clearApprisingState, loadApprising } from 'src/slices/apprising';
import { ACCZIOM_ORG } from 'src/globals';
import { fetchUrl } from 'src/utils/fileUpDownload';
import { setNeedReload as setAssetNeedReload, clearAssetState } from 'src/slices/asset';
import { setNeedReload as setPurchaseNeedReload, clearPurchaseItemState } from 'src/slices/business';
import { setNeedReload as setFavoriteNeedReload, clearFavoriteState } from 'src/slices/favorite';
import { setNeedReload as setFileNeedReload, clearFileShareState } from 'src/slices/fileshare';
import { setNeedReload as setKanbanNeedReload, clearKanbanState } from 'src/slices/kanban';
import { setNeedReload as setMSTemplateNeedReload, clearMsTemplateState } from 'src/slices/msTemplate';
import { setInvitationNeedReload, setReferralNeedReload, clearNetworkState } from 'src/slices/network';
import { setNeedReload as setPayAccountNeedReload, clearPayAccountState } from 'src/slices/payAccount';
import { setNeedReload as setRequestNeedReload, clearRequestState } from 'src/slices/request';
import { wsConnect } from 'src/apis/wsApi';
import { clearServiceRequestState } from 'src/slices/serviceRequest';
import { clearProcurementState, setContractReload, setInvoiceReload, setPOReload, setRFQReload, setScheduleReload } from 'src/slices/procurement';

let isLoading: boolean = false;

export const initChatApi = (uid: string, dispatch): Promise<boolean> => {
  dispatch(cleanUpChat());
  chatApi.cleanUp();
  dispatch(cleanUserMinInfos());
  return new Promise((resolve) => {
    chatApi.initConnection(uid, dispatch)
      .then(() => {
        const threads: Thread[] = [];
        chatApi.getConversations(threads)
          .then((_threads) => {
            _threads.forEach((item) => {
              chatApi.getMessages(item.sid)
                .then((msgs) => {
                  if (msgs.length > 0) dispatch(setMessages(msgs));
                })
                .catch(() => {});
            });
            resolve(true);
          });
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export const loadContact = async (uid: string, dispatch): Promise<void> => {
  try {
    const cs = await contactApi.loadAllContacts(uid);
    const { QBO, XERO } = await contactApi.getSyncStatus(uid);
    dispatch(setContacts(cs));
    dispatch(setSyncState(QBO, XERO));
  } catch (e) {
    console.log('Loading contact : ', JSON.stringify(e));
  }
  return Promise.resolve();
};

export const loadWallet = async (walletId: any, dispatch): Promise<void> => {
  try {
    const w = await lambdaFirstLoadWallet(walletId);
    dispatch(setWalletInfo(w));
  } catch (e) {
    console.log('Loading wallet : ', JSON.stringify(e));
  }
  return Promise.resolve();
};

// export const loadApprising = async (email: string, dispatch): Promise<void> => {
//   try {
//     const apprisings = await lambdaGetApprising(email);
//     dispatch(getApprisings(apprisings));
//   } catch (e) {
//     console.log('Loading apprisings : ', JSON.stringify(e));
//   }
//   return Promise.resolve();
// };

export const initNeedReloads = (dispatch) => {
  dispatch(setCurOrgNeedReload(true));
  dispatch(setAssetNeedReload(true));
  dispatch(setPurchaseNeedReload(true));
  dispatch(setFavoriteNeedReload(true));
  dispatch(setFileNeedReload(true));
  dispatch(setContactsNeedReload(true));
  dispatch(setConnectStateNeedReload(true));
  dispatch(setSyncStateNeedReload(true));
  dispatch(setKanbanNeedReload(true));
  dispatch(setMSTemplateNeedReload(true));
  dispatch(setInvitationNeedReload(true));
  dispatch(setReferralNeedReload(true));
  dispatch(setPayAccountNeedReload(true));
  dispatch(setRequestNeedReload(true));
  dispatch(setRFQReload(true));
  dispatch(setContractReload(true));
  dispatch(setPOReload(true));
  dispatch(setScheduleReload(true));
  dispatch(setInvoiceReload(true));
};

export const initializeStates = (dispatch) => {
  dispatch(clearKanbanState());
  dispatch(clearContactState());
  dispatch(clearFavoriteState());
  dispatch(clearFileShareState());
  dispatch(clearNetworkState());
  dispatch(clearPayAccountState());
  dispatch(clearServiceRequestState());
  dispatch(clearMsTemplateState());
  dispatch(clearAssetState());
  dispatch(clearProcurementState());
  dispatch(clearPurchaseItemState());
  dispatch(clearRequestState());
  dispatch(clearWalletState());
  dispatch(clearApprisingState());
  dispatch(clearOrganizationState());
  dispatch(clearUserState());
};

const initialLoad = (uid: string, checkLive, dispatch, domainName: string): void => {
  if (isLoading) return;

  intervalJobs(5 * 60 * 1000, checkLive, true);

  isLoading = true;
  dispatch(setLoadingStatus(LoadingStatus.LOADING));
  initializeStates(dispatch);
  lambdaGetUserInfo(uid)
    .then((ui) => {
      dispatch(setUserInfo(ui));
      lambdaGetOrgActivatedMembersOfUser(uid)
        .then((res) => {
          const { memberInfos: members, orgInfos: organizations, roleInfos: strRoles, teamInfos: teams } = res;
          const roles = [];
          strRoles.forEach((item) => {
            roles.push({
              rid: item.rid,
              oid: item.oid,
              name: item.name,
              level: item.level,
              department: JSON.parse(item.department),
              assignment: JSON.parse(item.assignment),
              production: JSON.parse(item.production),
              transaction: JSON.parse(item.transaction),
              createdAt: item.createdAt,
              updatedAt: item.updatedAt
            });
          });
          const memberInfos = [];
          const orgInfos = [];
          const roleInfos = [];
          const teamInfos = [];
          const teamIds = [];
          members.forEach((member) => {
            const role = roles.find((item) => item.rid === member.rid);
            const departmentValues = Object.values(role.department).map((obj) => Object.values(obj).includes(true));
            const assignmentValues = Object.values(role.assignment).map((obj) => Object.values(obj).includes(true));
            const teamCount = member.tids.length;
            if (departmentValues.includes(true) || assignmentValues.includes(true) || teamCount > 0) {
              memberInfos.push(member);
              const organization = organizations.find((item) => item.organizationId === member.oid);
              orgInfos.push(organization);
              roleInfos.push(role);
              member.tids.forEach((tid) => {
                if (!teamIds.includes(tid)) {
                  teamIds.push(tid);
                  const team = teams.find((item) => item.tid === tid);
                  teamInfos.push(team);
                }
              });
            }
          });
          dispatch(setOrgStructures(memberInfos, orgInfos, roleInfos, teamInfos));
          wsConnect(orgInfos.at(0));
          let firstOrg = null;
          let firstMember = null;
          if (process.env.NODE_ENV === 'production' && domainName) {
            firstOrg = orgInfos.find((organizationInfo) => organizationInfo.domainName === domainName);
            if (firstOrg) firstMember = memberInfos.find((member) => member.oid === firstOrg.organizationId);
          } else if (orgInfos.length > 0) {
            firstOrg = orgInfos.at(0);
            firstMember = memberInfos.find((member) => member.oid === firstOrg.organizationId);
          }
          if (process.env.NODE_ENV === 'production' && !domainName && firstOrg) window.location.href = `https://${firstOrg.domainName}.orgs.m.accziom.com`;
          else if (firstOrg && firstMember) {
            initChatApi(firstMember.mid, dispatch)
              .then((ret) => {
                if (ret) {
                  loadWallet({ id: firstOrg.organizationId, type: ACCZIOM_ORG }, dispatch)
                    .then(() => {
                      loadContact(firstOrg.organizationId, dispatch);
                      if (firstMember.tids.length > 0) {
                        const firstTeamId = (firstMember.tids)[0];
                        dispatch(setActiveTeamId(firstTeamId));
                        loadApprising(firstOrg.organizationId, firstTeamId);
                      } else {
                        dispatch(setActiveTeamId(null));
                        loadApprising(firstOrg.organizationId, null);
                      }
                      if (firstOrg.banner) {
                        lambdaGetOrgBannerUrl(firstOrg.banner)
                          .then((bannerUrl) => {
                            fetchUrl(bannerUrl)
                              .then((localUrl) => {
                                dispatch(setBannerUrl(localUrl));
                              })
                              .catch((err) => { console.log(JSON.stringify(err)); });
                          })
                          .catch((err) => { console.log(JSON.stringify(err)); });
                      }
                      dispatch(setActiveOrgId(firstOrg.organizationId));
                      dispatch(setLoadingStatus(LoadingStatus.COMPLETED));
                      isLoading = false;
                    })
                    .catch(() => {
                      dispatch(setActiveOrgId(null));
                      dispatch(setLoadingStatus(LoadingStatus.NONE));
                      isLoading = false;
                    });
                } else {
                  console.log('Chat initialization failed.');
                  dispatch(setActiveOrgId(null));
                  dispatch(setLoadingStatus(LoadingStatus.NONE));
                  isLoading = false;
                }
              });
          } else {
            console.log('No organizations found.');
            dispatch(setActiveOrgId(null));
            dispatch(setLoadingStatus(LoadingStatus.NONE));
            isLoading = false;
          }
        })
        .catch((e) => {
          console.log('Get organization error : ', JSON.stringify(e));
          dispatch(setActiveOrgId(null));
          dispatch(setLoadingStatus(LoadingStatus.NONE));
          isLoading = false;
        });
    })
    .catch(() => {
      dispatch(setActiveOrgId(null));
      dispatch(setLoadingStatus(LoadingStatus.NONE));
      isLoading = false;
    });
};

export default initialLoad;
