import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ItemToUser } from 'src/../../Common/Model/network';

interface ItemState {
  itemToUsers: ItemToUser[]
}

const initialState: ItemState = {
  itemToUsers: []
};

const slice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    cleanAll(state: ItemState): void {
      state.itemToUsers.splice(0, state.itemToUsers.length);
    },
    setItemToUsers(state: ItemState, action: PayloadAction<ItemToUser[]>) {
      const itemToUsers = action.payload;
      state.itemToUsers = [...itemToUsers];
    },
    addNewItemToUsers(state: ItemState, action: PayloadAction<ItemToUser[]>) {
      const itemToUsers = action.payload;
      itemToUsers.forEach((itemToUser) => {
        const index = state.itemToUsers.findIndex((item) => item.id === itemToUser.id);
        if (index > -1) state.itemToUsers[index] = itemToUser;
        else state.itemToUsers.push(itemToUser);
      });
    },
    updateItemToUser(state: ItemState, action: PayloadAction<ItemToUser>) {
      const itemToUser = action.payload;
      const index = state.itemToUsers.findIndex((item) => item.id === itemToUser.id);
      if (index > -1) state.itemToUsers[index] = itemToUser;
      else state.itemToUsers.push(itemToUser);
    },
    removeItemToUsers(state: ItemState, action: PayloadAction<string[]>) {
      const ids = action.payload;
      state.itemToUsers = state.itemToUsers.filter((item) => !ids.includes(item.id));
    }
  }
});

export const setItemToUsers = (itemToUsers: ItemToUser[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setItemToUsers(itemToUsers));
};
export const addNewItemToUsers = (itemToUsers: ItemToUser[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewItemToUsers(itemToUsers));
};
export const updateItemToUser = (itemToUser: ItemToUser): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateItemToUser(itemToUser));
};
export const removeItemToUsers = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeItemToUsers(ids));
};

export const { reducer } = slice;

export default slice;
