import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/layout/AuthGuard';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Dashboard = Loadable(lazy(() => import('src/pages/dashboard')));
const Organization = Loadable(lazy(() => import('src/pages/dashboard/organization')));
const OrganizationProfile = Loadable(lazy(() => import('src/pages/dashboard/organization/profile')));
const Contacts = Loadable(lazy(() => import('src/pages/dashboard/organization/contact')));
const NewContact = Loadable(lazy(() => import('src/pages/dashboard/organization/NewContact')));
const ImportContacts = Loadable(lazy(() => import('src/pages/dashboard/organization/ImportContacts')));
const Wallet = Loadable(lazy(() => import('src/pages/dashboard/organization/wallet')));
const ChatThreadList = Loadable(lazy(() => import('./pages/dashboard/chat/ChatThreadList')));
const ChatThread = Loadable(lazy(() => import('./pages/dashboard/chat/ChatThread')));
// const Network = Loadable(lazy(() => import('src/pages/dashboard/organization/network')));
const BusinessSearch = Loadable(lazy(() => import('src/pages/dashboard/bsearch')));
const Todo = Loadable(lazy(() => import('src/pages/dashboard/todo')));
const Fileshare = Loadable(lazy(() => import('src/pages/dashboard/fileshare/list')));
const FileshareDetail = Loadable(lazy(() => import('src/pages/dashboard/fileshare/detail')));
const ErrorPage = Loadable(lazy(() => import('src/pages/dashboard/space')));
const ProductView = Loadable(lazy(() => import('src/pages/dashboard/product/list')));
const SingleServiceRequest = Loadable(lazy(() => import('src/pages/dashboard/request/service')));
const BundleServiceRequest = Loadable(lazy(() => import('src/pages/dashboard/request/bundle')));
const BundleServiceOffer = Loadable(lazy(() => import('src/pages/dashboard/request/BundleServiceOffer')));
const SingleServiceOffer = Loadable(lazy(() => import('src/pages/dashboard/request/SingleServiceOffer')));
const StructureBoard = Loadable(lazy(() => import('src/pages/dashboard/structure/UI')));
const NetworkView = Loadable(lazy(() => import('src/pages/dashboard/network')));
const Contact = Loadable(lazy(() => import('src/pages/dashboard/organization/contact')));
const ServicePad = Loadable(lazy(() => import('src/pages/dashboard/microservice/list')));
const TicketSales = Loadable(lazy(() => import('src/pages/dashboard/ticket/sales')));
const TicketPurchases = Loadable(lazy(() => import('src/pages/dashboard/ticket/purchases')));
const TicketDetail = Loadable(lazy(() => import('src/pages/dashboard/ticket/detail')));
const SingleServiceView = Loadable(lazy(() => import('src/pages/dashboard/microservice/SingleServiceView')));
const BundleServiceView = Loadable(lazy(() => import('src/pages/dashboard/microservice/BundleServiceView')));
const SingleServiceEdit = Loadable(lazy(() => import('src/pages/dashboard/microservice/SingleServiceEdit')));
const BundleServiceEdit = Loadable(lazy(() => import('src/pages/dashboard/microservice/BundleServiceEdit')));
const AssetBoard = Loadable(lazy(() => import('src/pages/dashboard/asset')));
const AssetEditView = Loadable(lazy(() => import('src/pages/dashboard/asset/EditAsset')));
const AssetDetailView = Loadable(lazy(() => import('src/pages/dashboard/asset/AssetDetail')));
const AssetRequest = Loadable(lazy(() => import('src/pages/dashboard/asset/RequestAsset')));

const routes: RouteObject[] = [
  {
    path: '/ms',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/ms',
        element: <ServicePad />
      },
      {
        path: '/ms/service/new',
        element: <SingleServiceEdit />
      },
      {
        path: '/ms/service/edit/:serviceId',
        element: <SingleServiceEdit />
      },
      {
        path: '/ms/service/detail/:serviceId',
        element: <SingleServiceView />
      },
      {
        path: '/ms/service/offer/:serviceId',
        element: <SingleServiceOffer />
      },
      {
        path: '/ms/service-bundle/new',
        element: <BundleServiceEdit />
      },
      {
        path: '/ms/service-bundle/edit/:serviceId',
        element: <BundleServiceEdit />
      },
      {
        path: '/ms/service-bundle/detail/:serviceId',
        element: <BundleServiceView />
      },
      {
        path: '/ms/service-bundle/offer/:serviceId',
        element: <BundleServiceOffer />
      }
    ]
  },
  {
    path: '/asset',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/asset',
        element: <AssetBoard />
      },
      {
        path: '/asset/new',
        element: <AssetEditView />
      },
      {
        path: '/asset/edit/:assetId',
        element: <AssetEditView />
      },
      {
        path: '/asset/detail/:assetId',
        element: <AssetDetailView />
      }
    ]
  },
  {
    path: '/products',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/products',
        element: <ProductView />
      },
      {
        path: '/products/service/:serviceId',
        element: <SingleServiceRequest />
      },
      {
        path: '/products/service-bundle/:serviceId',
        element: <BundleServiceRequest />
      },
      {
        path: '/products/asset/:assetId',
        element: <AssetRequest />
      }
    ]
  },
  {
    path: '/tickets',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/tickets/demander',
        element: <TicketPurchases />
      },
      {
        path: '/tickets/demander/detail/:ticketId',
        element: <TicketDetail />
      },
      {
        path: '/tickets/demander/service-bundle/:ticketId',
        element: <TicketPurchases />
      },
      {
        path: '/tickets/demander/service/:ticketId',
        element: <TicketPurchases />
      },
      {
        path: '/tickets/supplier',
        element: <TicketSales />
      },
      {
        path: '/tickets/supplier/detail/:requestId',
        element: <TicketDetail />
      },
      {
        path: '/tickets/supplier/service-bundle/:ticketId',
        element: <TicketSales />
      },
      {
        path: '/tickets/supplier/service/:ticketId',
        element: <TicketSales />
      }
    ]
  },
  {
    path: '/todo',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/todo',
        element: <Todo />
      },
    ]
  },
  {
    path: '/bs',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/bs',
        element: <BusinessSearch />
      },
      {
        path: '/bs/search',
        element: <BusinessSearch />
      },
      {
        path: '/bs/anzsicsuggestion',
        element: <BusinessSearch />
      },
      {
        path: '/bs/favourites',
        element: <BusinessSearch />
      }
    ]
  },
  {
    path: '/organization',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/organization',
        element: <Organization />
      },
      {
        path: '/organization/profile',
        element: <OrganizationProfile />
      },
      {
        path: '/organization/contacts',
        element: <Contacts />
      },
      {
        path: '/organization/contacts/new',
        element: <NewContact />
      },
      {
        path: '/organization/contacts/import',
        element: <ImportContacts />
      },
      {
        path: '/organization/crypto-wallet',
        element: <Wallet />
      },
      {
        path: '/organization/myfiles',
        element: <Fileshare />
      },
      {
        path: '/organization/myfiles/detail/:id',
        element: <FileshareDetail />
      },
    ]
  },
  {
    path: '/structure',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/structure',
        element: <StructureBoard />
      },
      {
        path: '/structure/member',
        element: <StructureBoard />
      },
      {
        path: '/structure/role',
        element: <StructureBoard />
      },
      {
        path: '/structure/team',
        element: <StructureBoard />
      },
    ]
  },
  {
    path: '/network',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/network',
        element: <NetworkView />
      },
      {
        path: '/network/contacts',
        element: <Contact />
      },
    ]
  },
  {
    path: '/messaging',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/messaging',
        element: <ChatThreadList />
      },
      {
        path: '/messaging/:threadKey',
        element: <ChatThread />
      },
    ]
  },
  {
    path: '/home',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/home',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'error',
    element: <ErrorPage />
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  }
];

export default routes;
