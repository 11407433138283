import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography
} from '@material-ui/core';
import { LoginAmplify } from 'src/components/authentication/login';
import { useSelector } from 'src/store';

const Login: FC = () => {
  const { orgInfos, activeOrgId } = useSelector((state) => state.organization);
  const orgInfo = orgInfos.find((organizationInfo) => organizationInfo.organizationId === activeOrgId);

  return (
    <>
      <Helmet>
        <title>Login - Accziom</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            pt: {
              sm: 5,
              xs: 3,
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: {
                sm: 3,
                xs: 2,
              }
            }}
          >
            <img
              src="/favicon.png"
              alt="Logo"
              style={{
                height: 40,
                width: 40
              }}
            />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 3
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Container
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <div>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      align="center"
                      variant="h5"
                    >
                      {orgInfo?.tradingName ? orgInfo.tradingName : 'Sign in to Accziom'}
                    </Typography>
                  </div>
                </Container>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <LoginAmplify />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
