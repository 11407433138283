import { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Button, InputAdornment, TextField, Toolbar, ThemeProvider, Divider, Badge, Typography, Box, Avatar, Slide } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import { useSelector } from 'src/store';
import { AccountCircle } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import useAuth from 'src/hooks/useAuth';
import { getNavbarApprisingCount } from 'src/utils/getApprisingCount';
import { createCustomTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import getInitials, { getInitialBackground } from 'src/utils/getInitials';

export interface Item {
  href?: string;
  popover?: any;
  icon?: ReactNode | string;
  title: string;
  children?: any[];
  authRequired?: boolean;
  info?: ReactNode;
}
export const MeMenuItem: Item = {
  title: 'Me',
  href: '/personal',
  authRequired: true,
  icon: AccountCircle,
  popover: null
};

const getIcon = (Icon): JSX.Element => (
  <Icon />
);

interface DashboardTopbarProps extends AppBarProps {
  trigger?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: 'rgb(32, 41, 55)'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardTopbar: FC<DashboardTopbarProps> = (props) => {
  const { trigger, ...other } = props;
  const mounted = useMounted();
  const auth = useAuth();
  const { userInfo } = useSelector((state) => state.user);
  const { apprisings } = useSelector((state) => state.apprising);
  const [topMeItem, setTopMeItem] = useState<Item>(null);
  const [badgeValue, setBadgeValue] = useState(0);
  useEffect(() => {
    if (!mounted) return;
    const meItem = MeMenuItem;
    if (userInfo?.avatar) meItem.icon = userInfo.avatar;
    else if (userInfo) meItem.icon = getUserDisplayName(userInfo);
    if (auth.isAuthenticated) {
      meItem.title = 'Me';
    } else {
      meItem.title = 'Sign in';
      meItem.icon = AccountCircle;
    }
    setTopMeItem(meItem);
    setBadgeValue(getNavbarApprisingCount(apprisings, meItem.href));
  }, [userInfo]);

  const { settings } = useSettings();
  const themeSearch = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'TOPBARMOBILE'
  });

  return (
    <ThemeProvider theme={themeMobile}>
      <Slide
        appear
        direction="down"
        in={!trigger}
      >
        <DashboardNavbarRoot {...other}>
          <Toolbar
            sx={{
              minHeight: 48,
              px: 1
            }}
          >
            <ThemeProvider theme={themeSearch}>
              <TextField
                fullWidth
                size="small"
                InputProps={{
                  sx: {
                    fontSize: 15,
                    borderRadius: '36px'
                  },
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <img
                        src="/favicon.png"
                        alt="Logo"
                        style={{
                          height: 32,
                          width: 32,
                          marginLeft: '0px'
                        }}
                      />
                      <Divider
                        orientation="vertical"
                        sx={{
                          height: '100%',
                          py: 1.5,
                          pl: 1
                        }}
                      />
                    </InputAdornment>
                  )
                }}
                placeholder="Search"
              />
              {
                auth.isAuthenticated ? (
                  topMeItem && (
                    <Badge
                      badgeContent={
                        badgeValue !== 0 ? (
                          <Typography
                            variant="subtitle1"
                            style={{
                              right: '20px'
                            }}
                          >
                            {badgeValue}
                          </Typography>
                        ) : badgeValue
                      }
                      variant="dot"
                      color="error"
                      overlap="circular"
                    >
                      <Box
                        sx={{
                          height: 32,
                          width: 32,
                          backgroundColor: 'transparent',
                          color: (theme) => theme.palette.text.secondary,
                          ml: 1
                        }}
                      >
                        {(typeof topMeItem.icon === 'string') ? (
                          <Avatar
                            src={topMeItem.icon}
                            sx={{
                              height: 32,
                              width: 32,
                              bgcolor: getInitialBackground(getInitials(topMeItem.icon)),
                              color: (thm) => thm.palette.text.primary,
                              fontSize: '12px',
                              fontWeight: 'bold'
                            }}
                          >
                            {getInitials(topMeItem.icon)}
                          </Avatar>
                        ) : (
                          getIcon(topMeItem.icon)
                        )}
                      </Box>
                    </Badge>
                  )
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    component={RouterLink}
                    to="/login"
                  >
                    Sign In
                  </Button>
                )
              }
            </ThemeProvider>
          </Toolbar>
        </DashboardNavbarRoot>
      </Slide>
    </ThemeProvider>
  );
};

DashboardTopbar.propTypes = {
  trigger: PropTypes.bool
};

DashboardTopbar.defaultProps = {
  trigger: false
};

export default DashboardTopbar;
