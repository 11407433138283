/* eslint-disable no-underscore-dangle */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type {
  Template,
} from 'src/../../Common/Model/iou';
import { lambdaIOUContractPost } from 'src/aws/lambdaDispatch';

interface IOUState {
  isInitialised: boolean,
  needReload: boolean,
  needRefresh: boolean,
  templates: Template[];
  template: Template;
  xml: any;
}

const initialState: IOUState = {
  isInitialised: false,
  needReload: true,
  needRefresh: true,
  templates: [],
  template: null,
  xml: null,
};

const slice = createSlice({
  name: 'iou',
  initialState,
  reducers: {
    initializeIOUState(state: IOUState, action: PayloadAction<{ isInitialised: boolean; }>) {
      const { isInitialised } = action.payload;
      state.isInitialised = isInitialised;
    },
    setTemplates(state: IOUState, action: PayloadAction<{ templates: Template[]; }>) {
      const { templates } = action.payload;
      state.templates = templates;
      state.isInitialised = true;
    },
    setNeedReload(state: IOUState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.needReload = needReload;
    },
    setNeedRefresh(state: IOUState) {
      state.needRefresh = !state.needRefresh;
    },
    setXML(state: IOUState, action: PayloadAction<{ xml: any; }>) {
      const { xml } = action.payload;
      state.xml = xml;
      state.isInitialised = true;
    },
    setActiveTemplate(state: IOUState, action: PayloadAction<{ template: Template; }>) {
      const { template } = action.payload;
      state.template = template;
    },
    clearAllIOUSlice(state: IOUState) {
      state.isInitialised = false;
      state.template = null;
      state.templates = [];
      state.xml = null;
      state.needReload = true;
    },
  }
});

export const { reducer } = slice;

export const initializeIOUState = (value: boolean): AppThunk => async (dispatch) => {
  const isInitialised = value;
  // console.log(isInitialised);
  dispatch(slice.actions.initializeIOUState({ isInitialised }));
};

export const loadTemplates = (id: string): AppThunk => async (dispatch) => {
  const data = await lambdaIOUContractPost('templates/list', { userId: id });
  // console.log(data);
  let templates : Template[];
  if (data && (!data.errorMessage || data.errorMessage.search('timed out') === -1)) {
    templates = data;
    dispatch(slice.actions.setTemplates({ templates }));
  }
};

export const setXML = (tplID : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.initializeIOUState({ isInitialised: false }));
  lambdaIOUContractPost('templates/browse', { _id: tplID })
    .then((xml) => {
      if (xml) {
        dispatch(slice.actions.setXML({ xml }));
      }
    });
};
export const setActiveTemplate = (t: Template): AppThunk => async (dispatch) => {
  const template = t;
  dispatch(slice.actions.setActiveTemplate({ template }));
};
export const clearAllIOUSlice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAllIOUSlice());
};
export const setNeedReload = (value:boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setNeedReload({ needReload }));
};
export const setNeedRefresh = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNeedRefresh());
};
export default slice;
