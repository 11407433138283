import type { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { AccziomMenu } from './DashboardBottombar';
import { Box, Grid, Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { tenary } from 'src/globals';

interface MorePopoverProps {
  onClose: () => void;
}
const useStyles = makeStyles((theme) => ({
  button: {
    height: 84, // setting height/width is optional
    color: 'textSecondary'
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    textOverflow: 'clip'
  },
  icon: {
    fontSize: '32px !important',
    marginBottom: theme.spacing(1)
  }
}));

const MorePopover: FC<MorePopoverProps> = ({ onClose }) => {
  const classes = useStyles();
  const content = (
    <Box
      sx={{
        p: 1,
        width: '100%',
      }}
    >
      <Grid
        container
        sx={{
          width: '100%',
        }}
      >
        {AccziomMenu.map((item) => {
          const Icon = item.icon as any;
          const title = item.title === 'Purchase Order' ? 'PO' : item.title;
          return (
            <Grid
              item
              key={title}
              sx={{
                width: tenary(AccziomMenu.length > 2, '33%', tenary(AccziomMenu.length === 2, '50%', '100%')),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Button
                /* Use classes property to inject custom styles */
                classes={{
                  root: classes.button,
                  label: classes.label
                }}
                sx={{
                  color: (theme) => theme.palette.text.secondary
                }}
                variant="text"
                disableRipple
                component={item.href && RouterLink}
                to={item.href}
                onClick={onClose}
              >
                <Icon className={classes.icon} />
                {title}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

  return (
    <>
      {content}
    </>
  );
};

MorePopover.propTypes = {
  onClose: PropTypes.func,
};

export default MorePopover;
