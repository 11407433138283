import { Auth } from 'aws-amplify';
import Lambda from 'aws-sdk/clients/lambda';
import { AWS_REGION } from 'src/config';
import gstore from 'src/store';

interface AWSLambdaCallParameters {
  FunctionName: string,
  InvocationType: string,
  Payload?: string
}

export const callLambda = async (fn: string, req?: any): Promise<any> => Auth.currentCredentials()
  .then((cred) => new Promise<any>((resolve, reject) => {
    const lambda = new Lambda({
      region: AWS_REGION,
      apiVersion: '2015-03-31',
      credentials: cred
    });
    const callParams: AWSLambdaCallParameters = {
      FunctionName: fn,
      InvocationType: 'RequestResponse'
    };
    const reqWithCred = {
      ...(!req ? {} : req),
      _orgId: gstore.getState().organization.activeOrgId
    };
    callParams.Payload = JSON.stringify(reqWithCred);
    lambda.invoke(callParams).promise()
      .then((res) => {
        const payload = JSON.parse(res.Payload as string);
        resolve(payload);
      })
      .catch((err) => reject(err));
  }))
  .catch(() => null);

export const lambdaRun = (fn: string, req?: any): Promise<any> => new Promise((resolve, reject) => {
  callLambda(fn, req)
    .then((data) => {
      if (data.errorType === undefined) {
        resolve(data);
      } else {
        reject(new Error(`Failed with reject error: ${JSON.stringify(data.errorMessage)} for ${fn} by ${JSON.stringify(req)}`));
      }
    })
    .catch((err) => {
      console.error('lambda catch error:', fn, req.method);
      reject(new Error(`Failed with catch error: ${JSON.stringify(err)} for ${fn} by ${JSON.stringify(req)}`));
    });
});
