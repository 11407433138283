import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { PurchaseItem } from 'src/../../Common/Model/purchaseItem';
import { deepCopy } from 'src/utils/copyObject';
import { ACCZIOM_TEAM } from 'src/globals';

export interface ExPurchaseItem extends PurchaseItem {
  chatId: string;
}

interface PurchaseItemState {
  purchaseItems: ExPurchaseItem[];
  needReload: boolean;
}

const initialState: PurchaseItemState = {
  purchaseItems: [],
  needReload: true
};

const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    clearState(state: PurchaseItemState) {
      state.purchaseItems.splice(0, state.purchaseItems.length);
      state.needReload = true;
    },
    setNeedReload(state: PurchaseItemState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    setPurchaseItems(state: PurchaseItemState, action: PayloadAction<{ exPurchaseItems: ExPurchaseItem[]; }>) {
      const { exPurchaseItems } = action.payload;
      state.purchaseItems = [...exPurchaseItems];
      state.needReload = false;
    },
    updatePurchaseItem(state: PurchaseItemState, action: PayloadAction<{ purchaseItem: ExPurchaseItem; }>) {
      const { purchaseItem } = action.payload;
      const index = state.purchaseItems.findIndex((item) => item.id === purchaseItem.id);
      if (index > -1) state.purchaseItems[index] = purchaseItem;
      else state.purchaseItems.push(purchaseItem);
    },
    removePurchaseItem(state: PurchaseItemState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.purchaseItems.findIndex((item) => item.id === id);
      state.purchaseItems.splice(index, 1);
    },
    setCustomerAgents(state: PurchaseItemState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.purchaseItems);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.customerAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.purchaseItems = updatedList;
    },
    setSupplierAgents(state: PurchaseItemState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedList = deepCopy(state.purchaseItems);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedList.find((info) => info.id === id);
        item.supplierAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.purchaseItems = updatedList;
    }
  }
});

export const { reducer } = slice;

export const clearPurchaseItemState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const loadPurchaseItems = (exPurchaseItems): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setPurchaseItems({ exPurchaseItems }));
};

export const removePurchaseItem = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removePurchaseItem({ id }));
};

export const updatePurchaseItem = (purchaseItem : ExPurchaseItem): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatePurchaseItem({ purchaseItem }));
};

export const setCustomerAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCustomerAgents(assignedTeams));
};

export const setSupplierAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSupplierAgents(assignedTeams));
};

export default slice;
