import { createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';

interface WsSyncState {
  members: boolean;
  requests: boolean;
  fileshare: boolean;
}

const initialState: WsSyncState = {
  members: false,
  requests: false,
  fileshare: false
};

const slice = createSlice({
  name: 'wsSync',
  initialState,
  reducers: {
    membersNeedRefresh(state: WsSyncState): void {
      state.members = !state.members;
    },
    requestsNeedRefresh(state: WsSyncState): void {
      state.requests = !state.requests;
    },
    fileshareNeedRefresh(state: WsSyncState): void {
      state.fileshare = !state.fileshare;
    }
  }
});

export const { reducer } = slice;

export const membersNeedRefresh = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.membersNeedRefresh());
};

export const requestsNeedRefresh = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.requestsNeedRefresh());
};

export const fileshareNeedRefresh = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.fileshareNeedRefresh());
};
