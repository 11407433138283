import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type {
  FileManage,
  Fileshare,
  FileThread
} from 'src/../../Common/Model/fileshare';
import { ItemMetadata } from 'src/types/fileshare';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { deepCopy } from 'src/utils/copyObject';
import { ACCZIOM_TEAM } from 'src/globals';
import { Tag } from 'src/../../Common/Model/tag';
import type { Message, Contact } from 'src/types/chat';

export interface MiniChatThread {
  sid: string;
  title: string;
  messages: Message[];
  createdBy: string;
  participants: Contact[];
}

interface FileshareState {
  isInitialised: boolean,
  isInitialised_share: boolean,
  needReload: boolean,
  needReloadShare: boolean,
  needRefresh: boolean,
  fileThreads: FileThread[],
  shares: Fileshare[],
  shareThreads: FileThread[],
  manage: FileManage,
  folders: any[],
  bChanged: boolean,
  currentFolder: string,
  targetFolder: string,
  bFirst: boolean,
  tags: Tag[],
  chatThread: MiniChatThread | null
}

const initialState: FileshareState = {
  isInitialised: false,
  isInitialised_share: false,
  needReload: true,
  needReloadShare: true,
  needRefresh: true,
  fileThreads: [],
  folders: [],
  shares: [],
  shareThreads: [],
  manage: null,
  bChanged: false,
  currentFolder: null,
  targetFolder: null,
  bFirst: true,
  tags: [],
  chatThread: null
};

const slice = createSlice({
  name: 'fileshare',
  initialState,
  reducers: {
    clearState(state: FileshareState) {
      state.needReload = true;
      state.fileThreads.splice(0, state.fileThreads.length);
      state.shares.splice(0, state.shares.length);
      state.tags.splice(0, state.tags.length);
      state.chatThread = null;
    },
    setThreads(state: FileshareState, action: PayloadAction<{ threads: FileThread[]; shares: Fileshare[]; }>) {
      const { threads, shares } = action.payload;
      state.fileThreads = [...threads];
      state.shares = [...shares];
      state.needReload = false;
    },
    addFilethread(state: FileshareState, action: PayloadAction<{ filethread: FileThread; }>) {
      state.isInitialised = false;
      const { filethread } = action.payload;
      state.fileThreads = [filethread].concat(state.fileThreads);
      state.needRefresh = !state.needRefresh;
      state.isInitialised = true;
    },
    addShare(state: FileshareState, action: PayloadAction<{ share: Fileshare; }>) {
      const { share } = action.payload;
      state.shares = [share].concat(state.shares);
      // state.needRefresh = !state.needRefresh;
    },
    updateFilethread(state: FileshareState, action: PayloadAction<{ thread: FileThread; }>) {
      state.isInitialised = false;
      const { thread } = action.payload;
      const index = state.fileThreads.findIndex((item) => item.id === thread.id);
      if (index > -1) state.fileThreads[index] = thread;
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
    },
    removeShare(state: FileshareState, action: PayloadAction<{ id: string; }>) {
      state.isInitialised = false;
      const { id } = action.payload;
      const index = state.shares.findIndex((item) => item.id === id);
      if (index > -1) state.shares.splice(index, 1);
      state.isInitialised_share = true;
      state.needRefresh = !state.needRefresh;
    },
    setNeedReloadShare(state: FileshareState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.needReloadShare = needReload;
    },
    setNeedReload(state: FileshareState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.needReload = needReload;
    },
    clearAllFileshareSlice(state: FileshareState) {
      state.isInitialised = false;
      state.isInitialised_share = false;
      state.fileThreads = [];
      state.folders = [];
      state.shares = [];
      state.needReloadShare = true;
      state.needReload = true;
      state.bChanged = false;
      state.targetFolder = null;
      state.currentFolder = null;
      state.bFirst = true;
    },
    setChanged(state: FileshareState) {
      state.bChanged = true;
    },
    setFolders(state: FileshareState, action: PayloadAction<{ folders: any[]; }>) {
      const { folders } = action.payload;
      state.folders = folders;
    },
    setFilemanage(state: FileshareState, action: PayloadAction<{ manage: FileManage; }>) {
      const { manage } = action.payload;
      state.manage = manage;
    },
    setCurrentFolder(state: FileshareState, action: PayloadAction<{ folderId: string; }>) {
      const { folderId } = action.payload;
      state.currentFolder = folderId;
    },
    setTargetFolder(state: FileshareState, action: PayloadAction<{ folderId: string; }>) {
      const { folderId } = action.payload;
      state.targetFolder = folderId;
    },
    setAgents(state: FileshareState, action: PayloadAction<{ orgId: string, assignedTeams: any }>): void {
      const { orgId, assignedTeams } = action.payload;
      const updated = deepCopy(state.fileThreads);
      assignedTeams.forEach(({ id, teamId }) => {
        const thread = updated.find((info) => info.id === id);
        const idx = thread.invitees.findIndex((invitee) => invitee.id === orgId);
        thread.inviteeAgents[idx] = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.fileThreads = updated;
    },
    setTags(state: FileshareState, action: PayloadAction<Tag[]>): void {
      state.tags = action.payload;
    },
    addTags(state: FileshareState, action: PayloadAction<Tag[]>): void {
      state.tags = [...state.tags, ...action.payload];
    },
    setChatThread(state: FileshareState, action: PayloadAction<MiniChatThread>) {
      state.chatThread = deepCopy(action.payload);
    },
    initChatThread(state: FileshareState): void {
      state.chatThread = null;
    }
  }
});

export const { reducer } = slice;

export const clearFileShareState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const loadThreads = (threads: FileThread[], shares: Fileshare[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setThreads({ threads, shares }));
};

export const loadFilemanage = (manage: FileManage): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFilemanage({ manage }));
};
export const addShare = (share: Fileshare): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addShare({ share }));
};
export const setNeedReloadShare = (value: boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setNeedReloadShare({ needReload }));
};
export const setNeedReload = (value: boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setNeedReload({ needReload }));
};
export const addFilethread = (filethread: FileThread): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addFilethread({ filethread }));
};
export const updateFilethread = (thread: FileThread): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateFilethread({ thread }));
};
export const clearAllFileshareSlice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAllFileshareSlice());
};
export const setChanged = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setChanged());
};
export const setFolders = (folders: NodeModel<ItemMetadata>[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setFolders({ folders }));
};
export const setCurrentFolder = (folderId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCurrentFolder({ folderId }));
};
export const setTargetFolder = (folderId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTargetFolder({ folderId }));
};
export const setAgents = (orgId: string, assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAgents({ orgId, assignedTeams }));
};
export const setTags = (tags: Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTags(tags));
};
export const addTags = (tags: Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addTags(tags));
};
export const setChatThread = (conv: MiniChatThread): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setChatThread(conv));
};
export const initChatThread = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.initChatThread());
};

export default slice;
