import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { User } from 'src/../../Common/Model/user';
import objCopy from 'src/utils/objCopy';
import { lambdaPutUserInfo } from 'src/aws/lambdaDispatch';
import { Organization } from 'src/../../Common/Model/organization';

export enum LoadingStatus {
  NONE = 1,
  LOADING = 2,
  COMPLETED = 3
}

interface UserState {
  userInfo: User | null;
  loadingStatus: LoadingStatus;
  requestedLocation: string;
  organizationInfo: Organization | null;
  registeredVerificationInfo: any;
  jobList: any[];
  isWindowScrollBottom: string;
  menuVisible: {
    top: boolean,
    bottom: boolean
  };
  tabTicket: string;
}

const initialState: UserState = {
  userInfo: null,
  organizationInfo: null,
  loadingStatus: LoadingStatus.NONE,
  requestedLocation: null,
  registeredVerificationInfo: null,
  jobList: [],
  isWindowScrollBottom: 'false',
  menuVisible: {
    top: false,
    bottom: true
  },
  tabTicket: '0'
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState(state: UserState) {
      state.userInfo = null;
      state.loadingStatus = LoadingStatus.NONE;
      state.requestedLocation = null;
      state.registeredVerificationInfo = null;
    },
    updateUser(state: UserState, action: PayloadAction<User>): void {
      const user = action.payload;
      objCopy(user, state.userInfo);
    },
    setMenuVisible(state: UserState, action: PayloadAction<{ top: boolean, bottom: boolean }>): void {
      state.menuVisible = action.payload;
    },
    setWindowScrollBottom(state: UserState, action: PayloadAction<string>): void {
      state.isWindowScrollBottom = action.payload as string;
    },
    setUser(state: UserState, action: PayloadAction<User>): void {
      const user = action.payload as User;
      state.userInfo = user;
    },
    setAvatar(state:UserState, action: PayloadAction<string>): void {
      const avatar = action.payload as string;
      state.userInfo.avatar = avatar;
    },
    setLoadingStatus(state: UserState, action: PayloadAction<LoadingStatus>): void {
      state.loadingStatus = action.payload;
    },
    setRequestedLocation(state: UserState, action: PayloadAction<string>): void {
      state.requestedLocation = action.payload;
    },
    setVerificationStatus(state: UserState, action: PayloadAction<number>): void {
      state.userInfo.identityVerified = action.payload;
    },
    setRegisteredVerificationInfo(state: UserState, action: PayloadAction<any>): void {
      state.registeredVerificationInfo = action.payload;
    },
    setOrganizationInfo(state: UserState, action: PayloadAction<any>): void {
      state.organizationInfo = action.payload;
    },
    updateOrganizationLogo(state: UserState, action: PayloadAction<any>): void {
      state.organizationInfo.logo = action.payload;
    },
    setJobList(state: UserState, action: PayloadAction<{ jobList: any[] }>): void {
      const { jobList } = action.payload;
      state.jobList = jobList;
    },
    setTabTicket(state: UserState, action: PayloadAction<{ tab: string; }>) {
      const { tab } = action.payload;
      state.tabTicket = tab;
    },
  }
});

export const clearUserState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setUserInfo = (_body: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setUser(_body));
};

export const setVerificationStatus = (status: number) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setVerificationStatus(status));
};

export const setRegisteredVerificationInfo = (body: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setRegisteredVerificationInfo(body));
};

export const updateUserInfo = (_uid: string, _body: any) : AppThunk => async (dispatch): Promise<void> => {
  await lambdaPutUserInfo(_uid, _body);
  dispatch(slice.actions.updateUser(_body));
};

export const setUserAvatar = (image: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAvatar(image));
};

export const updateUserAvatar = (image: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAvatar(image));
};

export const setLoadingStatus = (status: LoadingStatus) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setLoadingStatus(status));
};

export const setRequestedLocation = (path: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setRequestedLocation(path));
};

export const setOrganizationInfo = (body: Organization) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setOrganizationInfo(body));
};

export const updateOrganizationLogo = (image: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateOrganizationLogo(image));
};

export const setJobList = (jobList: any[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setJobList({ jobList }));
};

export const setWindowScrollBottom = (flag: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setWindowScrollBottom(flag));
};

export const setMenuVisible = (flag: { top: boolean, bottom: boolean }) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setMenuVisible(flag));
};

export const setTabTicket = (tab: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setTabTicket({ tab }));
};

export const { reducer } = slice;

export default slice;
