const uploadFile = async (file: File | Blob, url: string): Promise<any> => fetch(url, {
  method: 'put',
  headers: {
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': ''
  },
  body: file,
})
  .then((reponse) => Promise.resolve(reponse));

export default uploadFile;
