import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Request } from 'src/../../Common/Model/request';
import { deepCopy } from 'src/utils/copyObject';
import { Tag } from 'src/../../Common/Model/tag';
import { ACCZIOM_TEAM, PAY_CREDIT_CARD, PAY_DIRECT_DEBIT } from 'src/globals';

interface RequestState {
  requests: Request[];
  tags: Tag[];
  ratedRequests: string[];
  needReload: boolean;
}

const initialState: RequestState = {
  requests: [],
  tags: [],
  ratedRequests: [],
  needReload: true
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    clearState(state: RequestState) {
      state.requests.splice(0, state.requests.length);
      state.tags.splice(0, state.tags.length);
      state.ratedRequests.splice(0, state.ratedRequests.length);
      state.needReload = true;
    },
    setNeedReload(state: RequestState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    setRatedRequests(state: RequestState, action: PayloadAction<{ ids: string[]; }>) {
      const { ids } = action.payload;
      state.ratedRequests = ids;
    },
    addRatedRequest(state: RequestState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      state.ratedRequests = [...state.ratedRequests, id];
    },
    setRequests(state: RequestState, action: PayloadAction<{ requests: Request[]; }>) {
      const { requests } = action.payload;
      state.requests = requests;
      state.needReload = false;
    },
    addRequests(state: RequestState, action: PayloadAction<{ requests: Request[]; }>) {
      const { requests } = action.payload;
      state.requests.push(...requests);
    },
    updateRequest(state: RequestState, action: PayloadAction<any>): void {
      const { requestId, props } = action.payload;
      state.requests.forEach((request) => {
        if (request.uid === requestId) {
          Object.keys(props).forEach((key) => {
            request[key] = props[key];
          });
        }
      });
    },
    updateRawRequest(state: RequestState, action: PayloadAction<Request>): void {
      const updated = action.payload;
      const request = state.requests.find((item) => item.uid === updated.uid);
      if (request) {
        Object.keys(updated).forEach((key) => {
          if (key !== 'uid') request[key] = updated[key];
        });
      } else state.requests.push(updated);
    },
    setSupplierAgents(state: RequestState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedRequests = deepCopy(state.requests);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedRequests.find((info) => info.uid === id);
        item.supplierAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.requests = updatedRequests;
    },
    setDemanderAgents(state: RequestState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedRequests = deepCopy(state.requests);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedRequests.find((info) => info.uid === id);
        item.demanderAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.requests = updatedRequests;
    },
    removeRequest(state: RequestState, action: PayloadAction<{ uid: string; }>) {
      const { uid } = action.payload;
      const index = state.requests.findIndex((item) => item.uid === uid);
      state.requests.splice(index, 1);
    },
    setTags(state: RequestState, action: PayloadAction<Tag[]>) {
      state.tags = action.payload;
    },
    updateAutoDebitRequest(state: RequestState, action: PayloadAction<any>): void {
      const { uid, checked } = action.payload;
      const request = state.requests.find((item) => item.uid === uid);
      if (request) {
        request.material = {
          ...request.material,
          payments: {
            ...request.material.payments,
            paymentMethod: checked ? PAY_DIRECT_DEBIT : PAY_CREDIT_CARD
          }
        };
      }
    }
  }
});

export const { reducer } = slice;

export const clearRequestState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const loadRatedRequests = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRatedRequests({ ids }));
};
export const addRatedRequest = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addRatedRequest({ id }));
};
export const loadRequests = (requests: Request[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRequests({ requests }));
};
export const addRequests = (requests: Request[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addRequests({ requests }));
};
export const updateRequest = (requestId: string, props: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRequest({ requestId, props }));
};
export const updateRawRequest = (request: Request): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateRawRequest(request));
};
export const setSupplierAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setSupplierAgents(assignedTeams));
};
export const setDemanderAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDemanderAgents(assignedTeams));
};
export const removeRequest = (uid : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeRequest({ uid }));
};
export const setTags = (tags : Tag[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTags(tags));
};
export const updateAutoDebitRequest = (uid: string, checked: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAutoDebitRequest({ uid, checked }));
};

export default slice;
