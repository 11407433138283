import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useMounted from 'src/hooks/useMounted';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import './login.css';
import { LoadingStatus, setLoadingStatus } from 'src/slices/user';
import { useDispatch } from 'src/store';
import Microsoft from 'src/icons/Microsoft';

const LoginAmplify: FC = (props) => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login, loginSocial } = useAuth() as any;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [socialLoginType, setSocialLoginType] = useState<number>(0);

  const loginWithGoogle = () => {
    setSocialLoginType(1);
    loginSocial('Google');
  };

  const loginWithFacebook = () => {
    setSocialLoginType(2);
    loginSocial('Facebook');
  };

  const loginWithMicrosoft = () => {
    setSocialLoginType(3);
    loginSocial('MicrosoftIdP');
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email('Must be a valid email')
              .max(255)
              .required('Email is required')
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          dispatch(setLoadingStatus(LoadingStatus.NONE));
          await login(values.email, values.password);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
            navigate('/home');
          }
        } catch (err) {
          console.error(err);

          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          autoComplete="off"
          {...props}
        >
          <Grid
            container
            spacing={1}
            sx={{
              my: {
                sm: 2,
                xs: 1,
              }
            }}
          >
            <Grid
              item
              xs={12}
              md={12}
            >
              <Button
                color="primary"
                size="large"
                disabled={socialLoginType !== 0 || isSubmitting}
                variant="outlined"
                fullWidth
                onClick={loginWithGoogle}
              >
                {socialLoginType !== 1 && (
                  <Box
                    alt="Google"
                    component="img"
                    src="/static/icons/google.svg"
                    sx={{ mr: 3 }}
                  />
                )}
                {socialLoginType === 1 && (
                  <CircularProgress
                    color="primary"
                    size={20}
                    sx={{ mr: 3 }}
                  />
                )}
                Continue with Google
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              <Button
                color="primary"
                size="large"
                disabled={socialLoginType !== 0 || isSubmitting}
                variant="outlined"
                fullWidth
                onClick={loginWithMicrosoft}
              >
                {socialLoginType !== 3 && (
                  <Box
                    sx={{
                      mr: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Microsoft
                      sx={{
                        fontSize: '24px'
                      }}
                    />
                  </Box>
                )}
                {socialLoginType === 3 && (
                  <CircularProgress
                    color="primary"
                    size={20}
                    sx={{ mr: 1 }}
                  />
                )}
                Continue with Microsoft
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
            >
              <Button
                color="primary"
                size="large"
                variant="outlined"
                disabled={socialLoginType !== 0 || isSubmitting}
                onClick={loginWithFacebook}
                fullWidth
              >
                {socialLoginType !== 2 && (
                  <Box
                    alt="Facebook"
                    component="img"
                    src="/static/icons/facebook.svg"
                    sx={{ mr: 1 }}
                  />
                )}
                {socialLoginType === 2 && (
                  <CircularProgress
                    color="primary"
                    size={20}
                    sx={{ marginRight: '8px' }}
                  />
                )}
                Continue with Facebook
              </Button>
            </Grid>
          </Grid>
          <Divider
            sx={{
              mt: {
                sm: 4,
                xs: 2,
              },
              mb: {
                sm: 2,
                xs: 0,
              },
              color: 'text.secondary',
              paddingLeft: 7,
              paddingRight: 7
            }}
          >
            or
          </Divider>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            size="small"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            size="small"
            onBlur={handleBlur}
            onChange={handleChange}
            type={showPassword ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => { setShowPassword(!showPassword); }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 1 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting || socialLoginType !== 0}
              fullWidth
              size="large"
              onClick={() => { handleSubmit(); }}
              variant="contained"
            >
              {isSubmitting && socialLoginType === 0 && (
                <CircularProgress
                  color="primary"
                  size={20}
                  sx={{ marginRight: '8px' }}
                />
              )}
              Continue with Email
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginAmplify;
