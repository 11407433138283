import { useState, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, ThemeProvider, Box } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import TopbarItem from './TopbarItem';
import MessagingIcon from 'src/icons/Messaging';
import { useSelector } from 'src/store';
// import { AccountCircle, AccountTree, Apps, Home, Menu, Work } from '@material-ui/icons';
import { AccountCircle, Home, Menu, Work } from '@material-ui/icons';
import { useLocation, matchPath } from 'react-router-dom';
import useMounted from 'src/hooks/useMounted';
import MorePopover from './MorePopover';
import useAuth from 'src/hooks/useAuth';
import { getNavbarApprisingCount } from 'src/utils/getApprisingCount';
import { createCustomTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';
import getInitials from 'src/utils/getInitials';
import { memberRoleInfo } from 'src/globals';
import BusinessSearch from 'src/icons/BusinessSearch';

export interface Item {
  href?: string;
  popover?: any;
  icon?: ReactNode | string;
  title: string;
  children?: any[];
  authRequired?: boolean;
  info?: ReactNode;
}
export const HomeMenuItem : Item = {
  title: 'Home',
  href: '/home',
  icon: Home,
  authRequired: false,
  popover: null
};
export const MoreMenuItem : Item = {
  title: 'More',
  href: '#',
  icon: Menu,
  authRequired: true,
  popover: MorePopover
};
export const MeMenuItem: Item = {
  title: 'Me',
  href: '/organization',
  authRequired: true,
  icon: AccountCircle,
  popover: null
};
export const MicroserviceItem: Item = {
  title: 'Products',
  href: '/products',
  authRequired: false,
  icon: Work,
  popover: null
};
export const MessagingItem: Item = {
  title: 'Messaging',
  href: '/messaging',
  icon: MessagingIcon,
  authRequired: true,
  popover: null
};
export const BSearchItem: Item = {
  title: 'BSearch',
  href: '/bs',
  authRequired: true,
  icon: BusinessSearch,
  popover: null
};
export const AccziomMenu : Item[] = [
  // {
  //   title: 'Structure',
  //   href: '/structure',
  //   authRequired: true,
  //   icon: AccountTree,
  //   popover: null
  // },
  {
    title: 'BSearch',
    href: '/bs',
    authRequired: true,
    icon: BusinessSearch,
    popover: null
  },
  // {
  //   title: 'Microservices',
  //   href: '/ms',
  //   authRequired: true,
  //   icon: Apps,
  //   popover: null
  // },
];

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  trigger?: boolean;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: 'rgb(32, 41, 55)'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, trigger, ...other } = props;
  const [value, setValue] = useState(-1);
  const [unreadThreadCount, setUnreadThreadCount] = useState<number>(0);
  const { pathname } = useLocation();
  const mounted = useMounted();
  const auth = useAuth();
  const { convs } = useSelector((state) => state.chat);
  const { apprisings } = useSelector((state) => state.apprising);
  const [items, setItems] = useState<Item[]>([]);
  const { memberInfos, orgInfos, roleInfos, teamInfos, activeOrgId, activeTeamId } = useSelector((state) => state.organization);

  const handleChange = (newValue: number) => {
    if (value === -2) setValue(newValue);
  };

  const setTopbarItems = () => {
    if (!mounted.current) return;
    const hrefs: string[] = [];
    const activeRoleId = memberInfos.find((memberInfo) => memberInfo.oid === activeOrgId).rid;
    const activeRole = roleInfos.find((roleInfo) => roleInfo.rid === activeRoleId);
    memberRoleInfo.forEach((firstItem) => {
      const firstKey = firstItem.key;
      if (firstKey === 'department' || firstKey === 'assignment' || ((firstKey === 'production' || firstKey === 'transaction') && !!activeTeamId)) {
        firstItem.value.forEach((secondItem) => {
          const secondKey = secondItem.key;
          secondItem.value.forEach((thirdItem) => {
            const thirdKey = thirdItem.key;
            const thirdHref = thirdItem.href;
            if (activeRole[firstKey][secondKey][thirdKey]) {
              thirdHref.forEach((href) => {
                if (!hrefs.includes(href)) hrefs.push(href);
              });
            }
          });
        });
      }
    });
    const mm : Item[] = [];
    mm.push(HomeMenuItem);
    mm.push(MicroserviceItem);
    const meItem = MeMenuItem;
    const orgInfo = orgInfos.find((org) => org.organizationId === activeOrgId);
    meItem.icon = orgInfo?.logo;
    if (auth.isAuthenticated) {
      meItem.title = getInitials(orgInfo?.tradingName);
      if (activeTeamId) {
        const activeTeam = teamInfos.find((team) => team.tid === activeTeamId);
        meItem.title += `-${activeTeam.name}`;
      }
    } else {
      meItem.title = 'Sign in';
      meItem.icon = AccountCircle;
    }
    // const moreChildren = AccziomMenu.filter((item) => (item.href === '/messaging' || item.href === '/user' || hrefs.filter((href) => href.startsWith(item.href)).length > 0));
    // mm.push({
    //   ...MoreMenuItem,
    //   children: moreChildren
    // });
    mm.push(BSearchItem); // BSearchItem
    mm.push(MessagingItem); // Messaging
    mm.push(meItem);
    setItems(mm);
  };

  useEffect(() => {
    setUnreadThreadCount(convs.filter((_conv) => _conv.unreadCount > 0).length);
  }, [convs]);

  useEffect(() => {
    setTopbarItems();
  }, [activeTeamId, activeOrgId]);

  function isSamePath(href): boolean {
    if (href === '/home' && pathname === '/') return true;
    return !!matchPath({
      path: href,
      caseSensitive: false,
      end: false
    }, pathname);
  }

  const { settings } = useSettings();
  const themeSearch = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });
  const themeMobile = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'TOPBARMOBILE'
  });

  const isApple = /webOS|iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);

  return (
    <ThemeProvider theme={themeMobile}>
      <DashboardNavbarRoot
        sx={{
          top: 'auto',
          bottom: 0,
        }}
        {...other}
      >
        <Toolbar
          sx={{
            minHeight: isApple && trigger ? '72px' : '48px',
            mt: 0.3,
            display: 'flex',
            alignItems: 'flex-start',
            width: '100%',
            px: 1
          }}
        >
          <ThemeProvider theme={themeSearch}>
            {
              items.map((item, i) => (
                <Box
                  key={item.title}
                  sx={{
                    width: '20%',
                    flexDirection: 'row'
                  }}
                >
                  <TopbarItem
                    index={i}
                    icon={item.icon}
                    href={item.href}
                    title={item.title}
                    menu={item.popover}
                    value={item.title === 'Messaging' ? unreadThreadCount : getNavbarApprisingCount(apprisings, item.href)}
                    onClick={handleChange}
                    selected={isSamePath(item.href)} // value === i
                    items={item.children}
                    enable={!item.authRequired || auth.isAuthenticated}
                  />
                </Box>
              ))
            }
          </ThemeProvider>
        </Toolbar>
      </DashboardNavbarRoot>
    </ThemeProvider>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  trigger: PropTypes.bool
};

DashboardNavbar.defaultProps = {
  trigger: false
};

export default DashboardNavbar;
