import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Apprising } from 'src/../../Common/Model/apprising';
import type { ApprisingDetail } from 'src/../../Common/Model/common';
import gstore from 'src/store';
import { lambdaRemoveApprising, lambdaGetApprising } from 'src/aws/lambdaDispatch';
import { getPossibleHrefs } from 'src/utils/filterUrlsByRole';

interface ApprisingsState {
  apprisings: Apprising[];
  prevTeamId: string;
  isLoading: boolean;
  details: ApprisingDetail[];
}

const initialState: ApprisingsState = {
  apprisings: [],
  prevTeamId: '',
  isLoading: false,
  details: []
};

const slice = createSlice({
  name: 'apprising',
  initialState,
  reducers: {
    clearState(state: ApprisingsState) {
      state.apprisings.splice(0, state.apprisings.length);
      state.prevTeamId = '';
      state.isLoading = false;
      state.details.splice(0, state.details.length);
    },
    setApprisings(state: ApprisingsState, action: PayloadAction<{ apprisings: Apprising[]; }>) {
      state.apprisings = action.payload.apprisings;
      state.prevTeamId = '';
    },
    addApprisings(state: ApprisingsState, action: PayloadAction<{ apprisings: Apprising[]; }>) {
      state.apprisings = action.payload.apprisings.concat(state.apprisings);
    },
    addTeamApprisings(state: ApprisingsState, action: PayloadAction<{ apprisings: Apprising[]; }>) {
      const teamApprs = action.payload.apprisings;
      if (teamApprs.length > 0) {
        const orgApprs = state.apprisings.filter((appr) => appr.receiverId !== state.prevTeamId);
        state.apprisings = teamApprs.concat(orgApprs);
        state.prevTeamId = teamApprs[0].receiverId;
      } else {
        state.prevTeamId = '';
      }
    },
    removeApprising(state: ApprisingsState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.apprisings.findIndex((item) => item.id === id);
      state.apprisings.splice(index, 1);
    },
    removeApprisings(state: ApprisingsState, action: PayloadAction<{ ids: string[] }>) {
      const { ids } = action.payload;
      state.apprisings = state.apprisings.filter((appr) => !ids.includes(appr.id));
    },
    setState(state: ApprisingsState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setDetails(state: ApprisingsState, action: PayloadAction<ApprisingDetail[]>) {
      state.details = action.payload;
    }
  }
});

export const { reducer } = slice;

export const clearApprisingState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setApprisings = (apprisings: Apprising[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setApprisings({ apprisings }));
};

export const addApprisings = (apprisings: Apprising[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addApprisings({ apprisings }));
};

export const addTeamApprisings = (apprisings: Apprising[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addTeamApprisings({ apprisings }));
};

export const removeApprising = (id: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeApprising({ id }));
};

export const removeApprisings = (ids: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeApprisings({ ids }));
};

export const setState = (state: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setState(state));
};

export const setDetails = (details: ApprisingDetail[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDetails(details));
};

export const sawApprising = (filterFunc: (appr: Apprising) => boolean): Apprising[] => {
  const { apprisings } = gstore.getState().apprising;
  const sawApprs = apprisings.filter(filterFunc);
  const sawApprIds = sawApprs.map((appr) => appr.id);
  if (sawApprIds.length > 0) {
    gstore.dispatch(removeApprisings(sawApprIds));
    lambdaRemoveApprising(sawApprIds);
  }
  return sawApprs;
};

const loadOrgApprising = async (activeOrgId: string, set: boolean = false) => {
  if (activeOrgId) {
    const apprs = await lambdaGetApprising(activeOrgId);
    const { activeOrgId: curOrgId } = gstore.getState().organization;
    if (curOrgId !== activeOrgId) return;
    const hrefs = getPossibleHrefs();
    const filteredApprs = apprs.filter((appr) => hrefs.some((href) => appr.href.startsWith(href)) || appr.href === '/myfiles/share');
    const signApprs = filteredApprs.filter((appr) => appr.type === 'signed');
    const noneSignApprs = filteredApprs.filter((appr) => appr.type !== 'signed');
    if (set) gstore.dispatch(setApprisings(noneSignApprs));
    else gstore.dispatch(addApprisings(noneSignApprs));
    lambdaRemoveApprising(signApprs.map((appr) => appr.type === 'signed'));
  }
};

const loadTeamApprising = async (activeTeamId: string) => {
  if (activeTeamId) {
    const apprs = await lambdaGetApprising(activeTeamId);
    const { activeTeamId: curTeamId } = gstore.getState().organization;
    if (curTeamId !== activeTeamId) return;
    const hrefs = getPossibleHrefs();
    gstore.dispatch(addTeamApprisings(apprs.filter((appr) => hrefs.some((href) => appr.href.startsWith(href)))));
  }
};

export const loadApprising = async (orgId: string, teamId: string) => {
  try {
    gstore.dispatch(setState(true));
    await loadOrgApprising(orgId, true);
    await loadTeamApprising(teamId);
  } catch (err) {
    console.log(JSON.stringify(err));
  } finally {
    gstore.dispatch(setState(false));
  }
};

export default slice;
