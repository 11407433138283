import { Box } from '@material-ui/core';
import type { FC } from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const SpinnerScreen: FC = () => {
  const color = '#01a65b';
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: (theme) => theme.palette.background.default, // '#1c2531',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000
      }}
    >
      <Loader
        type="Bars"
        height={48}
        width={48}
        color={color}
      />
    </Box>
  );
};

export default SpinnerScreen;
