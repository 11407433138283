import Conversations, { User } from '@twilio/conversations';
import { lambdaGetTwilioToken, lambdaGetAttachmentFileDownloadUrl, lambdaGetAttachmentFileUploadUrl, lambdaGetOrgTeamMembers, lambdaGetUserMinInfos } from 'src/aws/lambdaDispatch';
import uploadFile from 'src/utils/uploadFile';
import { Conversation } from '@twilio/conversations/lib/data/conversations';
import { Thread, Message as AccsiomMsg, MessageAttribue, Contact } from 'src/types/chat';
import { Message as TwilioMsg } from '@twilio/conversations/lib/message';
import {
  markThreadAsSeen,
  msgAdded,
  msgSent,
  msgUpdated,
  addRecipientsToThread,
  removeMessage,
  removeParticipants,
  setActiveThread,
  setEditingMsg,
  setMessageImage,
  setMessageVideoThumbnail,
  setMsgUnreadCount,
  setTypingEnded,
  setTypingStarted,
  threadAdded,
  threadDeleted,
  threadJoined,
  updateLastMsgIndex,
  updateLastReadMsgIndex,
  updateUserOnlineStatus,
  updateLastReadMsgIds
} from 'src/slices/chat';
import { updateUserMinInfos } from 'src/slices/organization';
import { Participant } from '@twilio/conversations/lib/participant';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
// modified by Makarov --2021/11/01
import { PAGE_SIZE, tenary, ACCZIOM_USER, ACCZIOM_ORG, getUserIdByType, ACCZIOM_MEMBER } from 'src/globals';
import getUserDisplayName from 'src/utils/getUserDisplayName';
import axios from 'axios';
import gstore from 'src/store';
import { accsiomURIs } from 'src/config';

const TwilioToAccsiomMsg = (msg: TwilioMsg) : AccsiomMsg => ({
  id: msg.index,
  body: msg.body,
  convId: msg.conversation.sid,
  attrib: msg.attributes,
  createdAt: msg.dateCreated.getTime(),
  senderId: msg.author,
  contentType: 'text',
  sent: true
} as AccsiomMsg);

const TwilioToAccsiomThread = (conv: Conversation) : Thread => {
  const attrib = conv.attributes as any;
  return {
    sid: conv.sid,
    title: conv.friendlyName,
    participants: attrib.recipients,
    lastMessage: attrib.lastMessage,
    lastMessageTime: attrib.lastMessageTime,
    type: attrib.type,
    createdBy: conv.createdBy,
    unreadCount: 0,
    isTyping: false,
    lastReadMsgIndex: conv.lastReadMessageIndex === null ? 0 : conv.lastReadMessageIndex,
    lastMsgIndex: (conv.lastMessage === null || conv.lastMessage === undefined) ? 0 : conv.lastMessage.index,
    messages: [],
    typerId: ''
  } as Thread;
};

class ChatApi {
  private client: Conversations = null;

  private uid: string = '';

  private token: string = '';

  private firstMsgForNewConv = '';

  private convs: Conversation[] = [];

  private users: Record<string, User> = {};

  private messages: Record<string, TwilioMsg[]> = {};

  private lastMsgId: Record<string, number> = {};

  private dispatch = null;

  private activeThreadId: string = null;

  private msgId: number = -1;

  private isConnecting: boolean = false;

  public isActive: boolean = false;

  cleanUp(): void {
    if (!(this.client)) return;
    this.isActive = false;
    this.client.shutdown();
    this.dispatch = null;
    this.isConnecting = false;
    this.convs.forEach((_conv) => {
      this.messages[_conv.sid]?.splice(0, this.messages[_conv.sid].length);
    });
    this.messages = {};
    this.lastMsgId = {};
    this.convs.splice(0, this.convs.length);
    this.uid = '';
    this.users = {};
    this.token = '';
    this.activeThreadId = null;
    this.msgId = -1;
    this.client = null;
  }

  initConnection(uid: string, _dispatch): Promise<boolean> {
    if (this.client) {
      if (this.client.connectionState === 'connected' || this.client.connectionState === 'connecting') {
        return Promise.resolve(true);
      }
    }

    if (this.isConnecting) return Promise.resolve(true);

    this.isConnecting = true;

    return new Promise((resolve, reject) => {
      this.getToken(uid)
        .then((_token) => {
          this.token = _token;
          Conversations.create(this.token)
            .then((_conn) => {
              this.client = _conn;
              this.uid = uid;
              this.dispatch = _dispatch;
              this.initListeners();
              this.isActive = true;
              this.isConnecting = false;
              resolve(true);
            })
            .catch(() => {
              reject(new Error('Failed with twilio conversation initialization'));
            });
        })
        .catch(() => {
          reject(new Error('Failed with twilio token request'));
        });
    });
  }

  getUid() : string {
    return this.uid;
  }

  loadImageThumbnail(uri: string, msg: AccsiomMsg): void {
    lambdaGetAttachmentFileDownloadUrl(`${uri}-thumb`).then((url) => {
      this.dispatch(setMessageImage(msg, url));
    });
  }

  loadVideoThumbnail(uri: string, msg: AccsiomMsg): void {
    lambdaGetAttachmentFileDownloadUrl(`${uri}.png`).then((url) => {
      this.dispatch(setMessageVideoThumbnail(msg, url));
    });
  }

  initListeners(): void {
    if (this.client) {
      this.client.on('connectionError', () => {
        this.initConnection(this.uid, this.dispatch);
      });

      this.client.on('tokenAboutToExpire', () => {
        lambdaGetTwilioToken(this.uid)
          .then((token) => {
            if (token !== null) {
              this.client.updateToken(token);
            } else {
              this.cleanUp();
            }
          })
          .catch(() => {
            console.log('updateToken Failed.');
          });
      });

      this.client.on('messageAdded', (msg: TwilioMsg) => {
        const aMsg = TwilioToAccsiomMsg(msg);

        if (!(this.messages[aMsg.convId])) this.messages[aMsg.convId] = [];
        this.messages[aMsg.convId].push(msg);

        if (aMsg.senderId !== this.uid) {
          if (aMsg.attrib) {
            const { type, uri } = aMsg.attrib;

            if (type !== undefined) {
              if (type.includes('image/')) {
                this.loadImageThumbnail(uri, aMsg);
              }
              if (type.includes('video/mp4')) {
                this.loadVideoThumbnail(uri, aMsg);
              }
            }
          }

          this.dispatch(msgAdded(aMsg));
        } else {
          this.dispatch(msgSent(aMsg));
        }

        this.dispatch(updateLastMsgIndex(aMsg.convId, aMsg.id));
        this.lastMsgId[aMsg.convId] = aMsg.id;

        const conv = this.findThreadById(aMsg.convId);
        if (!conv) {
          if (this.activeThreadId === aMsg.convId) {
            this.updateLastReadMsgIndex(aMsg.convId);
          } else {
            conv.getUnreadMessagesCount()
              .then((val) => this.dispatch(setMsgUnreadCount(conv.sid, !val ? 0 : val)))
              .catch(() => {});
          }
        }

        if (this.uid === conv.createdBy) {
          const att: any = conv.attributes;
          att.lastMessage = aMsg.body;
          att.lastMessageTime = aMsg.createdAt;
          conv.updateAttributes(att);
        }

        if (aMsg && aMsg.convId) {
          if (document.hasFocus() === false && window.Notification !== null && Notification.permission === 'granted') this.notifyMessage(aMsg);
        }
      });

      this.client.on('messageUpdated', (msg: any) => {
        const aMsg = TwilioToAccsiomMsg(msg.message);
        this.dispatch?.(msgUpdated(aMsg));
      });

      this.client.on('messageRemoved', (msg: TwilioMsg) => {
        this.dispatch?.(removeMessage(msg.conversation.sid, msg.index));
      });

      this.client.on('typingStarted', (p: Participant) => {
        const { sid } = p.conversation;
        const { identity } = p;

        if (identity === this.uid) return;
        this.dispatch?.(setTypingStarted(sid, identity));
      });

      this.client.on('typingEnded', (p: Participant) => {
        const { sid } = p.conversation;
        this.dispatch?.(setTypingEnded(sid));
      });

      this.client.on('conversationJoined', (_conv: Conversation) => {
        this.dispatch?.(threadJoined(_conv.sid));
      });

      this.client.on('conversationAdded', (_conv: Conversation) => {
        const conv = this.findThreadById(_conv.sid);

        if (conv) return;
        this.addConvDispatch(_conv);
        if (this.firstMsgForNewConv) {
          this.sendMessage(_conv.sid, this.firstMsgForNewConv);
          this.firstMsgForNewConv = '';
        }
      });

      this.client.on('participantUpdated', ({ participant }) => {
        const { sid } = participant.conversation;
        const { identity, lastReadMessageIndex } = participant;
        const lastMsgIds = {};
        lastMsgIds[sid] = {};
        lastMsgIds[sid][identity] = lastReadMessageIndex;
        this.dispatch(updateLastReadMsgIds(lastMsgIds));
      });
    }
  }

  notifyMessage(accMsg: AccsiomMsg): void {
    const conv = this.findThreadById(accMsg.convId);
    if (conv) {
      const attrib = conv.attributes as any;
      const participants = attrib.recipients;
      const sender = participants.find((_participant) => _participant.uid === accMsg.senderId);
      let senderIcon = '/static/unknown-person.png';
      const { userMinInfos } = gstore.getState().organization;
      const sentUserInfo = sender ? userMinInfos.find((miniUser) => miniUser.uid === getUserIdByType(sender)) : null;
      if (sentUserInfo) senderIcon = sentUserInfo.avatar;
      const senderName = sentUserInfo ? getUserDisplayName(sentUserInfo) : 'Unknown';
      const { type, quotedMsg } = accMsg.attrib;
      const isFile = type !== undefined && quotedMsg === undefined;
      const isImage = isFile && type.includes('image/');
      const n = new Notification(conv.friendlyName, {
        body: `${senderName}: ${isFile ? tenary(isImage, 'Sent a photo.', 'Sent a file.') : accMsg.body}`,
        icon: senderIcon
      });
      n.onclick = () => {
        window.focus();
      };
      setTimeout(() => { n.close(); }, 5000);
    }
  }

  loadAvatar(participants: Contact[]): void {
    const { userMinInfos } = gstore.getState().organization;
    const miniUserIds = userMinInfos.map((miniUser) => (miniUser.uid));
    const newParticipants = participants.filter((participant) => (!miniUserIds.includes(getUserIdByType(participant))));
    const newUserIds = newParticipants.filter((participant) => participant.type === ACCZIOM_USER).map((participant) => ({ id: participant.uid, type: ACCZIOM_USER }));
    const newMemberIdsAsOrg = newParticipants.filter((participant) => participant.type === ACCZIOM_ORG).map((participant) => (participant.oid));
    const newOrgIds = newMemberIdsAsOrg.filter((orgId, index) => index === newMemberIdsAsOrg.findIndex((oid) => oid === orgId)).map((orgId) => ({ id: orgId, type: ACCZIOM_ORG }));
    const newUserIdsAsMember = newParticipants.filter((participant) => participant.type === ACCZIOM_MEMBER).map((participant) => ({ id: participant.muid, type: ACCZIOM_USER }));
    const newUsers = [...newUserIds, ...newOrgIds, ...newUserIdsAsMember];
    if (newUsers.length > 0) {
      lambdaGetUserMinInfos(newUsers).then((miniUsers) => {
        this.dispatch(updateUserMinInfos(miniUsers));
      });
    }
  }

  addConvDispatch(_conv: Conversation): void {
    let i = 0;
    const attrib0 = _conv.attributes as any;
    for (i = 0; i < this.convs.length; i++) {
      const attrib1 = this.convs[i].attributes as any;
      if (attrib1.lastMessageTime < attrib0.lastMessageTime) break;
    }
    this.convs.splice(i, 0, _conv);

    const thread: Thread = TwilioToAccsiomThread(_conv);
    this.dispatch(threadAdded(thread));
    this.loadAvatar(thread.participants);

    // if (this.convs.length === 1) this.setActiveThreadId(thread.sid);

    this.lastMsgId[thread.sid] = thread.lastMsgIndex;
    _conv.getUnreadMessagesCount()
      .then((val) => this.dispatch(setMsgUnreadCount(_conv.sid, val === null ? 0 : val)))
      .catch(() => {});

    thread.participants.forEach((item) => {
      if (this.users[item.uid] === undefined) {
        this.users[item.uid] = null;
        this.client.getUser(item.uid)
          .then((u) => {
            this.users[item.uid] = u;
            this.dispatch(updateUserOnlineStatus(u.identity, u.isOnline));
            this.users[item.uid].on('updated', (evt) => {
              evt.updateReasons.forEach((ur) => {
                if (ur === 'reachabilityOnline') this.dispatch(updateUserOnlineStatus(evt.user.identity, evt.user.isOnline));
              });
            });
          })
          .catch(() => {});
      }
    });
  }

  setInitialActiveThreadID(): void {
    const sid = this.convs.length < 1 ? null : this.convs[0].sid;
    this.setActiveThreadId(sid);
    if (sid) this.updateLastReadMsgIndex(sid);
  }

  getUsersLastMsgId(sid: string = ''): void {
    if (!sid) sid = this.activeThreadId;
    const activeConversation = this.findThreadById(sid);
    if (!activeConversation) return;
    const participants = activeConversation.getParticipants();
    participants.then((currentParticipants) => {
      const lastMsgIds = {};
      lastMsgIds[sid] = currentParticipants.reduce((merged, participant) => {
        merged[participant.identity] = participant.lastReadMessageIndex;
        return merged;
      }, {});
      this.dispatch(updateLastReadMsgIds(lastMsgIds));
      this.dispatch(setActiveThread(sid));
    });
  }

  setActiveThreadId(sid: string): void {
    this.activeThreadId = sid;
    this.getUsersLastMsgId();
  }

  getToken(uid: string): Promise<string> {
    return new Promise((resolve, reject) => {
      lambdaGetTwilioToken(uid)
        .then((token) => {
          resolve(token);
        })
        .catch(() => {
          reject(new Error('Failed with twilio token request'));
        });
    });
  }

  deleteMessage(sid: string, msgId: number): Promise<boolean> {
    for (let i = 0; i < this.messages[sid].length; i++) {
      if (this.messages[sid][i].index === msgId) {
        this.dispatch(removeMessage(sid, msgId));
        this.messages[sid][i].remove().then(() => {
          const { convs } = gstore.getState().chat;
          const sconv = convs.find((conv) => conv.sid === sid);
          const { memberInfos, activeOrgId } = gstore.getState().organization;
          const member = memberInfos.find((mem) => mem.oid === activeOrgId);
          axios.post(accsiomURIs.twilio_notification_url, {
            type: 'remove',
            participants: sconv.participants.filter((part) => part.uid !== member.mid).map((part) => ({
              uid: part.uid,
              type: part.type,
              oid: part.oid
            })),
            sid,
            id: msgId
          }).catch(() => {});
        });
        this.messages[sid].splice(i, 1);
        break;
      }
    }

    return Promise.resolve(true);
  }

  deleteMessages(sid: string, msgIds: number[]): Promise<boolean> {
    this.messages[sid].forEach((message) => {
      if (msgIds.includes(message.index)) {
        this.dispatch(removeMessage(sid, message.index));
        message.remove().then(() => {
          const { convs } = gstore.getState().chat;
          const sconv = convs.find((conv) => conv.sid === sid);
          const { memberInfos, activeOrgId } = gstore.getState().organization;
          const member = memberInfos.find((mem) => mem.oid === activeOrgId);
          axios.post(accsiomURIs.twilio_notification_url, {
            type: 'remove',
            participants: sconv.participants.filter((part) => part.uid !== member.mid).map((part) => ({
              uid: part.uid,
              type: part.type,
              oid: part.oid
            })),
            sid,
            id: message.index
          }).catch(() => {});
        });
      }
    });
    this.messages[sid] = this.messages[sid].filter((message) => !msgIds.includes(message.index));

    return Promise.resolve(true);
  }

  createConversation(attrib: any, title: string, type: number, firstMsg?: string): Promise<string> {
    if (!(this.client)) {
      return Promise.reject(new Error('Twilio client is not initiated'));
    }
    return new Promise((resolve, reject) => {
      this.client.createConversation({ friendlyName: title, attributes: { recipients: attrib, lastMessage: firstMsg, lastMessageTime: (new Date()).getTime(), type } })
        .then((conv) => {
          conv.join()
            .then((joinedConv) => {
              attrib.forEach((reiceipt) => {
                if (reiceipt.uid !== this.uid) {
                  joinedConv.add(reiceipt.uid);
                  // this.dispatch(removeRecipient(reiceipt.uid));
                }
              });

              if (firstMsg) this.firstMsgForNewConv = firstMsg;

              resolve(joinedConv.sid);
            })
            .catch((err) => Promise.reject(new Error(`Invitation error: ${err}`)));
        })
        .catch((err) => {
          reject(new Error(`Creating conversation failed with error: ${JSON.stringify(err)}`));
        });
    });
  }

  addParticipantsToConversation(sid:string, newRecipients: Contact[]): Promise<void> {
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.reject();
    newRecipients.forEach((recipient) => {
      conv.add(recipient.uid);
    });
    const att: any = conv.attributes;
    const { recipients } = att;
    att.recipients = [...recipients, ...newRecipients];
    conv.updateAttributes(att);
    this.dispatch(addRecipientsToThread(sid, newRecipients));
    return Promise.resolve();
  }

  removeParticipantsFromConversation(sid: string, uids: string[]): Promise<void> {
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.reject();
    uids.forEach((uid) => {
      conv.removeParticipant(uid);
    });
    const att: any = conv.attributes;
    att.recipients = att.recipients.filter((recipient) => !uids.includes(recipient.uid));
    conv.updateAttributes(att);
    this.dispatch(removeParticipants(sid, uids));
    return Promise.resolve();
  }

  removeConversationFromApp(_conv: Conversation): void {
    const convIdx = this.convs.indexOf(_conv);
    if (convIdx < 0) {
      this.setActiveThreadId(null);
    } else if (convIdx < this.convs.length - 1) {
      this.setActiveThreadId(this.convs[convIdx + 1].sid);
      this.updateLastReadMsgIndex(this.convs[convIdx + 1].sid);
    } else {
      this.setActiveThreadId(this.convs[0].sid);
      this.updateLastReadMsgIndex(this.convs[0].sid);
    }
    if (this.messages[_conv.sid]) {
      this.messages[_conv.sid].splice(0, this.messages[_conv.sid].length);
    }
    this.messages[_conv.sid] = undefined;
    this.lastMsgId[_conv.sid] = undefined;
    this.convs.splice(convIdx, 1);
    this.dispatch(threadDeleted(_conv.sid));
  }

  deleteConversation(sid: string): Promise<string> {
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.reject(new Error('The conversation does not exist.'));
    if (!(this.messages[conv.sid])) {
      conv.delete();
      this.removeConversationFromApp(conv);
      return Promise.resolve(conv.sid);
    }
    return new Promise((resolve, reject) => {
      conv.delete()
        .then((_conv) => {
          this.removeConversationFromApp(_conv);
          resolve(_conv.sid);
        })
        .catch((err) => {
          reject(new Error(`The conversation was failed to delete with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  leaveConversation(sid: string): Promise<string> {
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.resolve('');
    return new Promise((resolve, reject) => {
      conv.leave()
        .then((_conv) => {
          this.removeConversationFromApp(_conv);
          resolve(_conv.sid);
        })
        .catch((err) => {
          reject(new Error(`The conversation was failed to delete with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  getConversations(_threads: Thread[]): Promise<Thread[]> {
    if (!(this.client)) {
      return Promise.reject(new Error('Twilio client is not initiated.'));
    }
    return new Promise((resolve, reject) => {
      this.client.getSubscribedConversations()
        .then((paginator) => {
          paginator.items.forEach((item) => {
            // item.delete();
            const conv = this.findThreadById(item.sid);
            if (!conv) this.addConvDispatch(item);
            _threads.unshift(TwilioToAccsiomThread(item));
          });
          resolve(_threads);
        })
        .catch((err) => {
          reject(new Error(`Get conversations failed with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  findThreadById = (sid: string): Conversation | null => {
    const conv = this.convs.find((item) => item.sid === sid);
    return conv || null;
  };

  updateLastReadMsgIndex(sid: string): Promise<void> {
    const conv = this.findThreadById(sid);
    if (conv) {
      this.dispatch(markThreadAsSeen(conv.sid));
      this.dispatch(updateLastReadMsgIndex(conv.sid, this.lastMsgId[conv.sid]));
      conv.setAllMessagesRead().then(() => {
        const { memberInfos, activeOrgId } = gstore.getState().organization;
        const member = memberInfos.find((mem) => mem.oid === activeOrgId);
        axios.post(accsiomURIs.twilio_notification_url, {
          type: 'saw',
          uid: member.mid,
          sid
        }).catch(() => {});
      }).catch(() => {});
      // conv.updateLastReadMessageIndex(this.lastMsgId[conv.sid]).catch(() => {});
    }

    return Promise.resolve();
  }

  onTyping(sid: string): void {
    const conv = this.findThreadById(sid);
    if (conv) {
      conv.typing()
        .then(() => {})
        .catch(() => {});
    }
  }

  inviteUser(_sid: string, _uid: string): Promise<boolean> {
    if (_uid === this.uid) return Promise.resolve(true);
    const conv = this.convs.find((item) => item.sid === _sid);
    if (conv) {
      conv.add(_uid)
        .then(() => Promise.resolve(true))
        .catch(() => Promise.resolve(false));
    }
    return Promise.resolve(false);
  }

  getMessages(sid: string, from?: number, direction?: 'backwards' | 'forward'): Promise<AccsiomMsg[]> {
    const ret: AccsiomMsg[] = [];
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.reject(new Error('The conversation does not exist.'));
    const twilioMsgs : TwilioMsg[] = [];
    return new Promise((resolve, reject) => {
      conv.getMessages(PAGE_SIZE, from, direction)
        .then((paginator) => {
          paginator.items.forEach((item) => {
            const aMsg = TwilioToAccsiomMsg(item);
            if (!(this.messages[aMsg.convId])) this.messages[aMsg.convId] = [];
            twilioMsgs.push(item);
            if (aMsg.attrib) {
              const { type, uri } = aMsg.attrib;
              if (type !== undefined) {
                if (type.includes('image/')) {
                  aMsg.body = '/static/blank.png';
                  this.loadImageThumbnail(uri, aMsg);
                }
                if (type.includes('video/mp4')) {
                  aMsg.body = '/static/blank.png';
                  this.loadVideoThumbnail(uri, aMsg);
                }
              }
            }
            ret.push(aMsg);
          });
          if (direction === 'forward') {
            this.messages[sid].push(...twilioMsgs);
          } else {
            this.messages[sid].unshift(...twilioMsgs);
          }
          resolve(ret);
        })
        .catch((err) => {
          reject(new Error(`Get messages failed with Error: ${JSON.stringify(err)}`));
        });
    });
  }

  // created by Markov --2021/11/17
  minimizeConversation(sid: string): void {
    if (this.messages[sid] === undefined || this.messages[sid].length < 1) return;
    this.messages[sid].splice(0, this.messages[sid].length - PAGE_SIZE);
  }

  getVideoThumbnail(file: File) {
    return new Promise((resolve) => {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      video.setAttribute('src', URL.createObjectURL(file));
      video.addEventListener('timeupdate', () => {
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        canvas.toBlob((thumb) => {
          resolve({
            thumb,
            width: video.videoWidth,
            height: video.videoHeight
          });
        });
      });
      video.addEventListener('loadedmetadata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.currentTime = 0;
      });
    });
  }

  getImageThumbnail(file: File) {
    return new Promise((resolve) => {
      const image = new Image();
      const canvas = document.createElement('canvas');
      image.setAttribute('src', URL.createObjectURL(file));
      image.addEventListener('load', () => {
        const w = 240;
        const h = 240 * (image.height / image.width);
        canvas.width = w;
        canvas.height = h;
        const canvasCtx = canvas.getContext('2d');
        canvasCtx.drawImage(image, 0, 0, w, h);
        canvas.toBlob((thumb) => {
          resolve({
            thumb,
            width: image.width,
            height: image.height
          });
        });
      });
    });
  }

  getImageSize(file) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };
    });
  }

  async sendFile(sid: string, file: File) {
    const conv = this.findThreadById(sid);
    if (!conv) return -1;
    if (file === undefined || file === null) return 1;
    if (file.size > 20971520) {
      toast.error('File you attached is large, Please send the file which is less than 20MB in size.');
      return 1;
    }
    const uid = uuidv4();
    const attribute = {
      type: file.type,
      size: file.size,
      name: file.name,
      uri: uid
    } as MessageAttribue;
    let sendMsg = 'Sent a file.';
    const newMsg = {
      id: this.msgId,
      convId: sid,
      body: '',
      attrib: attribute,
      createdAt: (new Date()).getTime(),
      sent: false,
      senderId: this.uid,
      contentType: ''
    };
    this.msgId--;
    if (attribute.type === 'video/mp4') {
      sendMsg = 'Sent a video.';
      const { thumb, width, height } = (await this.getVideoThumbnail(file)) as { thumb: Blob; width: number; height: number };
      attribute.size = width * 10000 + height;
      const thumbUrl = URL.createObjectURL(thumb);
      this.dispatch(msgAdded({
        ...newMsg,
        body: thumbUrl
      }));
      const thumbuploadUrl = await lambdaGetAttachmentFileUploadUrl(`${uid}.png`);
      await uploadFile(thumb, thumbuploadUrl);
      const uploadUrl = await lambdaGetAttachmentFileUploadUrl(uid);
      await uploadFile(file, uploadUrl);
    } else if (attribute.type.includes('image/')) {
      sendMsg = 'Sent a image';
      const { thumb, width, height } = (await this.getImageThumbnail(file)) as { thumb: Blob; width: number; height: number };
      const thumbUrl = URL.createObjectURL(thumb);
      attribute.size = width * 10000 + height;
      this.dispatch(msgAdded({
        ...newMsg,
        body: thumbUrl
      }));
      const thumbuploadUrl = await lambdaGetAttachmentFileUploadUrl(`${uid}-thumb`);
      await uploadFile(thumb, thumbuploadUrl);
      const uploadUrl = await lambdaGetAttachmentFileUploadUrl(`${uid}.${attribute.type.replace('image/', '')}`);
      await uploadFile(file, uploadUrl);
    } else {
      this.dispatch(msgAdded(newMsg));
      const uploadUrl = await lambdaGetAttachmentFileUploadUrl(uid);
      await uploadFile(file, uploadUrl);
    }
    const retV = await conv.sendMessage(sendMsg, attribute);
    if (this.uid === conv.createdBy) {
      const att: any = conv.attributes;
      att.lastMessage = sendMsg;
      att.lastMessageTime = (new Date()).getTime();
      conv.updateAttributes(att);
    }
    return retV;
  }

  updateMessage(sid: string, msgId: number, _body: string): Promise<boolean> {
    if (!(this.messages[sid])) return Promise.resolve(true);
    const msg = this.messages[sid].find((item) => item.index === msgId);
    if (!msg) return Promise.resolve(true);
    return new Promise((resolve) => {
      msg.updateBody(_body)
        .then(() => {
          this.dispatch(setEditingMsg('', 0));
          resolve(true);
        })
        .catch(() => {
          this.dispatch(setEditingMsg('', 0));
          resolve(true);
        });
    });
  }

  sendQuote(sid: string, _body: string, attrib?: any): Promise<number> {
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.resolve(-1);
    this.dispatch(msgAdded({
      id: this.msgId,
      body: _body,
      convId: sid,
      attrib,
      createdAt: (new Date()).getTime(),
      sent: false,
      senderId: this.uid,
      contentType: 'quote'
    }));
    this.msgId--;
    return new Promise((resolve, reject) => {
      conv.sendMessage(_body, attrib)
        .then((val) => {
          if (this.uid === conv.createdBy) {
            const att: any = conv.attributes;
            att.lastMessage = _body;
            att.lastMessageTime = (new Date()).getTime();
            conv.updateAttributes(att);
          }
          resolve(val);
        })
        .catch((err) => {
          reject(new Error(`Send message failed with error : ${err}`));
        });
    });
  }

  sendMessage(sid: string, _body: string, attrib?: any): Promise<number> {
    const conv = this.findThreadById(sid);
    if (!conv) return Promise.resolve(-1);
    this.dispatch(msgAdded({
      id: this.msgId,
      body: _body,
      convId: sid,
      attrib: null,
      createdAt: (new Date()).getTime(),
      sent: false,
      senderId: this.uid,
      contentType: 'text'
    }));
    this.msgId--;
    return new Promise((resolve, reject) => {
      conv.sendMessage(_body, attrib)
        .then((val) => {
          const { convs, activeThreadId } = gstore.getState().chat;
          const { memberInfos, activeOrgId } = gstore.getState().organization;
          const member = memberInfos.find((mem) => mem.oid === activeOrgId);
          const sconv = convs.find((item) => item.sid === activeThreadId);
          axios.post(accsiomURIs.twilio_notification_url, {
            type: 'add',
            participants: sconv.participants.filter((part) => part.uid !== member.mid).map((part) => ({
              uid: part.uid,
              type: part.type,
              oid: part.oid,
            })),
            sid,
            id: val
          }).catch(() => {});
          if (this.uid === conv.createdBy) {
            const att: any = conv.attributes;
            att.lastMessage = _body;
            att.lastMessageTime = (new Date()).getTime();
            conv.updateAttributes(att);
          }
          resolve(val);
        })
        .catch((err) => {
          reject(new Error(`Send message failed with error : ${err}`));
        });
    });
  }

  markThreadAsSeen(threadId: string): Promise<true> {
    const conv = this.findThreadById(threadId);
    if (conv) {
      conv.setAllMessagesRead();
      this.dispatch(markThreadAsSeen(threadId));
    }
    return Promise.resolve(true);
  }

  async createConversationFromTask(partner: any, partnerAgent: any, me: any, myAgent: any, title: string, type: number): Promise<string> {
    let firstMsg = 'Hello';
    const partners = [];
    if (partner.type === ACCZIOM_USER) {
      partners.push(partner as Contact);
      firstMsg = `${firstMsg}, ${getUserDisplayName(partner)}!`;
    } else if (partner.type === ACCZIOM_ORG) {
      const resPartner = await lambdaGetOrgTeamMembers(partnerAgent.id);
      if (resPartner && !resPartner.errorMessage) {
        const { memberInfo, roleInfo, orgInfo } = resPartner;
        memberInfo.forEach((memberItem) => {
          const role = roleInfo.find((roleItem) => roleItem.rid === memberItem.rid);
          const transactionRole = JSON.parse(role.transaction);
          const editChat = transactionRole.messaging.messaging;
          if (editChat) {
            partners.push({
              uid: memberItem.mid,
              oid: orgInfo.organizationId,
              type: ACCZIOM_ORG
            } as Contact);
          }
        });
        firstMsg = `${firstMsg}, ${orgInfo.tradingName}!`;
      }
    }
    if (partners.length < 1) {
      toast.error('No users are ready for chat in partner side.');
      return '';
    }
    const mines = [];
    if (me.type === ACCZIOM_USER) {
      mines.push(me as Contact);
      firstMsg = `${firstMsg} I am ${getUserDisplayName(me)}.`;
    } else if (me.type === ACCZIOM_ORG) {
      const resMe = await lambdaGetOrgTeamMembers(myAgent.id);
      if (resMe && !resMe.errorMessage) {
        const { memberInfo, roleInfo, orgInfo } = resMe;
        memberInfo.forEach((memberItem) => {
          const role = roleInfo.find((roleItem) => roleItem.rid === memberItem.rid);
          const transactionRole = JSON.parse(role.transaction);
          const editChat = transactionRole.messaging.messaging;
          if (editChat) {
            mines.push({
              uid: memberItem.mid,
              oid: orgInfo.organizationId,
              type: ACCZIOM_ORG
            });
          }
        });
        firstMsg = `${firstMsg} I am from ${orgInfo.tradingName}.`;
      }
    }
    if (mines.length < 1) {
      toast.error('No users are ready for chat in my side.');
      return '';
    }
    const sid = await this.createConversation([...partners, ...mines], title, type, firstMsg);
    return sid;
  }
}

export const chatApi = new ChatApi();
