// created by Makarov --2021/11/01
export const PAGE_SIZE = 20;
export const PRODUCT_PAGE_SIZE = 15;
export const ITEM_PAGE_SIZE = 10;
export const INVITATION_PAGE_START = 2;
export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51L7w51D5b37GdQHI8J35rJRRrW909ledxjcCDkWeOqOAY4R4elZ20wWovOBbJctGWxzQkbkOhn950RBMTzMEgEHo005jr4zcWm';
export const WALLET_HISTORY_PAGE_SIZE = 15;

export const tenary = (condition, then, otherwise): any => {
  if (condition) {
    return then;
  }

  return otherwise;
};

export const isValidEmail = (custom_str: string): boolean => {
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(custom_str.toLowerCase());
};

export const TAB_LABEL_ACCZIOM_USERS: string = 'Accziom Users';
export const TAB_LABEL_MY_CONTACTS: string = 'My Contacts';
export const TAB_LABEL_MY_ORGANIZATION: string = 'My Organization';
export const TAB_VALUE_ACCZIOM_USERS: string = 'Accziom users';
export const TAB_VALUE_MY_CONTACTS: string = 'contacts';
export const TAB_VALUE_MY_ORGANIZATION: string = 'members';

export const INVITE_ITEM_HEIGHT: number = 80;

export const TAB_LABEL_MICROSERVICES: string = 'Microservices';
export const TAB_LABEL_GOODS: string = 'Goods';
export const TAB_LABEL_ASSETS: string = 'Assets';
export const TAB_VALUE_MICROSERVICES: string = 'microservices';
export const TAB_VALUE_GOODS: string = 'goods';
export const TAB_VALUE_ASSETS: string = 'assets';

export const DELETE_MESSAGES_TITLE: string = 'Delete Messages';
export const DELETE_MESSAGES_CONTENT: string = 'Are you sure want to permanently delete these messages?';
export const DELETE_ROLE_TITLE: string = 'Delete Role';
export const DELETE_ROLE_CONTENT: string = 'Are you sure want to permanently delete this role and some members assigned to it?';
export const DELETE_TEAM_TITLE: string = 'Delete Team';
export const DELETE_TEAM_CONTENT: string = 'Are you sure want to permanently delete this team?';
export const DELETE_MEMBER_TITLE: string = 'Delete Member';
export const DELETE_MEMBER_CONTENT: string = 'Are you sure want to permanently release this member?';
export const DELETE_THREAD_TITLE: string = 'Archive Thread';
export const DELETE_THREAD_CONTENT: string = 'Are you sure want to permanently archive this thread?';
export const LEAVE_THREAD_TITLE: string = 'Leave Thread';
export const LEAVE_THREAD_CONTENT: string = 'Are you sure want to permanently leave this thread?';
export const LEAVE_WINDOW_TITLE: string = 'Leave Window';
export const LEAVE_WINDOW_CONTENT: string = 'Are you sure continue to leave this window? All of your work in this window will be crashed.';
export const CONFIRM_SCHEDULE_TITLE: string = 'Confirm Schedules';
export const CONFIRM_SCHEDULE_CONTENT: string = 'Are you sure want to accept these schedules?';
export const DELETE_SCHEDULE_TITLE: string = 'Delete Schedules';
export const DELETE_SCHEDULE_CONTENT: string = 'Are you sure want to permanently delete all schedules related to this one?';

export const getInviteEmailSubject = (userName: string): string => {
  const invitorName = userName.length > 0 ? userName : 'Anonymous Accziom-user';
  return `${invitorName} invited you to join Accziom`;
};

export const getInviteEmailBody = (receiver: string, sender: string): string => {
  const invitorName = sender.length > 0 ? sender : 'Anonymous Accziom-user';
  const receiverName = receiver.length > 0 ? `. ${receiver},` : ',';
  const sentence1 = `Dear Mr/Ms${receiverName}\n\n`;
  const sentence2 = `${invitorName} kindly invited you to join Accziom and do some works there.\n`;
  const sentence3 = 'Thanks for your help.\n\n';
  const sentence4 = 'https://go.accziom.com/login';
  const sentence5 = '\n\nAccziom Service Team';
  return `${sentence1}${sentence2}${sentence3}${sentence4}${sentence5}`;
};

export const convertTextToHtml = (srcText: string): string => {
  let previousWasASpace: boolean = false;
  let destText: string = '';
  for (let i = 0; i < srcText.length; i++) {
    const oneLetter = srcText.charAt(i);
    if (oneLetter === ' ') {
      if (previousWasASpace) {
        destText = `${destText}&nbsp;`;
        previousWasASpace = false;
      } else {
        previousWasASpace = true;
      }
    } else {
      previousWasASpace = false;
    }
    switch (oneLetter) {
      case '<':
        destText = `${destText}&lt;`;
        break;
      case '>':
        destText = `${destText}&gt;`;
        break;
      case '&':
        destText = `${destText}&amp;`;
        break;
      case '"':
        destText = `${destText}&quot;`;
        break;
      case '\n':
        destText = `${destText}<br />`;
        break;
      default:
        destText = `${destText}${oneLetter}`;
        break;
    }
  }
  return `<p>${destText}</p>`;
};

export const isNullForHtmlText = (text: string): boolean => (!text || text === convertTextToHtml('') || text === '<p><br></p>');

export const convertHtmlToText = (srcHtml: string): string => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = srcHtml;
  return tmp.textContent || tmp.innerText || '';
};

export const linkifyText = (inputText: string): string => {
  let replacedText;

  // URLs starting with http://, https://, or ftp://
  // eslint-disable-next-line
  const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  // eslint-disable-next-line
  const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  // Change email addresses to mailto:: links.
  // eslint-disable-next-line
  const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const contactFields = [
  {
    index: '0',
    label: 'Display Name',
    value: 'displayName',
    needed: true
  },
  {
    index: '1',
    label: 'First Name',
    value: 'firstName',
    needed: false
  },
  {
    index: '2',
    label: 'Middle Name',
    value: 'middleName',
    needed: false
  },
  {
    index: '3',
    label: 'Last Name',
    value: 'lastName',
    needed: false
  },
  {
    index: '4',
    label: 'Legal Name',
    value: 'legalName',
    needed: false
  },
  {
    index: '5',
    label: 'Trading Name',
    value: 'tradingName',
    needed: false
  },
  {
    index: '6',
    label: 'Casual Name',
    value: 'casualName',
    needed: false
  },
  {
    index: '7',
    label: 'Title',
    value: 'title',
    needed: false
  },
  {
    index: '8',
    label: 'Note',
    value: 'note',
    needed: false
  },
  {
    index: '9',
    label: 'Phone',
    value: 'primaryPhone',
    needed: true
  },
  {
    index: '10',
    label: 'Mobile',
    value: 'mobilePhone',
    needed: false
  },
  {
    index: '11',
    label: 'Fax',
    value: 'faxPhone',
    needed: false
  },
  {
    index: '12',
    label: 'Email',
    value: 'primaryEmail',
    needed: true
  },
  {
    index: '13',
    label: 'Tax Number',
    value: 'taxNumber',
    needed: false
  },
  {
    index: '14',
    label: 'Street',
    value: 'street',
    needed: false
  },
  {
    index: '15',
    label: 'Suburb',
    value: 'suburb',
    needed: false
  },
  {
    index: '16',
    label: 'State',
    value: 'state',
    needed: false
  },
  {
    index: '17',
    label: 'Postcode',
    value: 'postcode',
    needed: false
  },
  {
    index: '18',
    label: 'Website',
    value: 'website',
    needed: false
  },
  {
    index: '19',
    label: 'Entity Type',
    value: 'entityType',
    needed: false
  },
  {
    index: '20',
    label: 'ABN',
    value: 'abn',
    needed: false
  },
  {
    index: '21',
    label: 'ACN',
    value: 'acn',
    needed: false
  },
  {
    index: '22',
    label: 'Individual',
    value: 'isIndividual',
    needed: false
  },
];

export const emptyAddress = {
  reason: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  region: '',
  postalCode: '',
  country: ''
};

export const initializedContact = {
  isIndividual: false,
  displayName: '',
  firstName: '',
  middleName: '',
  lastName: '',
  legalName: '',
  tradingName: '',
  casualName: '',
  title: '',
  note: '',
  physicalAddress: { ...emptyAddress, addressType: 'PHYSICAL' },
  postalAddress: { ...emptyAddress, addressType: 'POBOX' },
  additionalAddresses: [],
  addresses: [],
  primaryPhone: '',
  mobilePhone: '',
  faxPhone: '',
  ddiPhone: '',
  additionalPhones: [],
  phoneNumbers: [],
  primaryEmail: '',
  alternativeEmail: '',
  additionalEmails: [],
  emails: [],
  taxNumber: '',
  website: '',
  entityType: 'Trust',
  abn: '',
  acn: ''
};

export const contactSubKeys = [
  'primaryEmail',
  'alternativeEmail',
  'additionalEmails',
  'primaryPhone',
  'mobilePhone',
  'faxPhone',
  'ddiPhone',
  'additionalPhones',
  'physicalAddress',
  'postalAddress',
  'billingAddress',
  'streetAddress',
  'additionalAddresses'
];

export interface DblButtonMessageProps {
  onYesClick?: () => void;
  onNoClick?: () => void;
  messageTitle: string;
  messageContent: string;
  onClose?: (data: boolean) => void;
}

export interface SingButtonMessageProps {
  buttonTitle?: string;
  onOkClick?: any;
  messageContent: string[];
  onClose?: (data: boolean) => void;
}

export const CRYPTO_ETHER = 'Ether';
export const CRYPTO_TETHER = 'Tether';
export const CRYPTO_MRC = 'Merchant';
export const FIAT_AUD = 'AUD';
export const FIAT_USD = 'USD';

export const tokenData = [
  {
    name: CRYPTO_ETHER,
    title: 'Ethereum',
    unit: 'ETH',
    icon: '/static/ethereum.png',
    color: '#627EEA'
  },
  {
    name: CRYPTO_TETHER,
    title: 'Tether USD',
    unit: 'USDT',
    icon: '/static/tether-usdt.svg',
    color: '#53AE94'
  },
  {
    name: CRYPTO_MRC,
    title: 'Merchant Coin',
    unit: 'MERC',
    icon: '/static/MERc.png',
    color: '#226822'
  }
];

export const cryptoCurrencies = [CRYPTO_ETHER, CRYPTO_TETHER, CRYPTO_MRC];
export const fiatCurrencies = [FIAT_AUD, FIAT_USD];

export const currencyTypeToUnit = (type: string) => (
  cryptoCurrencies.includes(type)
    ? tokenData.find((v) => v.name === type).unit
    : type
);

export const currencyUnitToType = (unit: string) => (
  fiatCurrencies.includes(unit)
    ? unit
    : tokenData.find((v) => v.unit === unit).name
);

export const memberRoleInfo = [
  {
    key: 'department',
    title: 'Department',
    value: [
      {
        key: 'account',
        title: 'Profile',
        value: [
          {
            key: 'profile',
            title: 'Edit Profile',
            value: false,
            href: [
              '/organization/profile'
            ],
            desc: 'Members can edit the profile and detailed information of organization.'
          },
          {
            key: 'contact',
            title: 'Manage Contacts',
            value: false,
            href: [
              '/network/contacts'
            ],
            desc: 'Members have full control contacts of organization.'
          },
          {
            key: 'wallet',
            title: 'Create and Restore Wallet',
            value: false,
            href: [
              '/wallet',
              '/organization/crypto-wallet',
              '/organization/payment'
            ],
            desc: 'Members can control wallet of organization. They can create wallet, send and view the history of transactions.'
          }
        ]
      },
      {
        key: 'structure',
        title: 'Structure',
        value: [
          {
            key: 'member',
            title: 'Recruit Member',
            value: false,
            href: [
              '/structure/member'
            ],
            desc: 'This check allows users to be able to add new members or remove current members of organization.'
          },
          {
            key: 'role',
            title: 'Define Member Role',
            value: false,
            href: [
              '/structure/role'
            ],
            desc: 'If a user\'s role checked this, the user can add or remove roles of organization.'
          },
          {
            key: 'team',
            title: 'Form Team',
            value: false,
            href: [
              '/structure/team'
            ],
            desc: 'This check allows users to have full control of team, they can create a new team, remove a exist team or change the members of a team.'
          }
        ]
      }
    ]
  },
  {
    key: 'assignment',
    title: 'Assignment',
    value: [
      {
        key: 'ticket',
        title: 'Ticket',
        value: [
          {
            key: 'demander',
            title: 'Assign Ticket Tasks as a Demander',
            value: false,
            href: [
              '/tickets/demander'
            ],
            desc: 'Members will be able to assign ticket tasks as a demander to teams in organization.'
          },
          {
            key: 'supplier',
            title: 'Assign Ticket Tasks as a Supplier',
            value: false,
            href: [
              '/tickets/supplier'
            ],
            desc: 'Members will be able to assign ticket tasks as a supplier to teams in organization.'
          }
        ]
      },
      {
        key: 'microservice',
        title: 'Microservice',
        value: [
          {
            key: 'management',
            title: 'Assign Microservice Management Tasks',
            value: false,
            href: [
              '/microservices/management'
            ],
            desc: 'Members will be able to assign the owner team of microservices in organization.'
          },
          {
            key: 'demander',
            title: 'Assign Transacted Microservices as a Demander',
            value: false,
            href: [
              '/microservices/business/demander'
            ],
            desc: 'Members will be able to assign microservice purchases to teams in organization.'
          },
          {
            key: 'supplier',
            title: 'Assign Transacted Microservices as a Supplier',
            value: false,
            href: [
              '/microservices/business/supplier'
            ],
            desc: 'Members will be able to assign microservice sales to teams in organization.'
          }
        ]
      },
      {
        key: 'asset',
        title: 'Asset',
        value: [
          {
            key: 'asset',
            title: 'Assign Asset Management Tasks',
            value: false,
            href: [
              '/asset',
              '/asset/new',
              '/asset/edit/:assetId',
              '/asset/detail/:assetId',
            ],
            desc: 'Members will be able to change the owner team of assets in organization.'
          }
        ]
      },
      {
        key: 'file',
        title: 'File',
        value: [
          {
            key: 'file',
            title: 'Assign Filethread Signature Tasks',
            value: false,
            href: [
              '/organization/myfiles'
            ],
            desc: 'Members will be able to assign filethreads to teams in organization. Teams assigned filethreads can sign up them.'
          }
        ]
      },
      {
        key: 'procurement',
        title: 'Procurement',
        value: [
          {
            key: 'demander',
            title: 'Assign Procurement Tasks as a Demander',
            value: false,
            href: [
              '/procurement/demander/contract',
              '/procurement/demander/schedule',
              '/procurement/demander/invoice'
            ],
            desc: 'Members will be able to assign procurement tasks requested by organization to teams.'
          },
          {
            key: 'supplier',
            title: 'Assign Procurement Tasks as a Supplier',
            value: false,
            href: [
              '/procurement/supplier/rfq',
              '/procurement/supplier/contract',
              '/procurement/supplier/schedule',
              '/procurement/supplier/po',
            ],
            desc: 'Members will be able to assign procurement tasks supplied by organization to teams.'
          }
        ]
      },
      {
        key: 'marketplace',
        title: 'Marketplace',
        value: [
          {
            key: 'good',
            title: 'Assign Good Management Tasks in Marketplace',
            value: false,
            href: [
              '/marketplace/good/management'
            ],
            desc: 'Members will be able to change owner teams of goods in organization.'
          },
          {
            key: 'goodTemplate',
            title: 'Assign Good Template Management Tasks in Marketplace',
            value: false,
            href: [
              '/marketplace/template'
            ],
            desc: 'Members will be able to change owner teams of good templates in organization.'
          }
        ]
      },
      {
        key: 'network',
        title: 'Network',
        value: [
          {
            key: 'invitation',
            title: 'Assign Invitation Tasks',
            value: false,
            href: [
              '/network/friends/pending/received'
            ],
            desc: 'If enabled, members are able to set the team that can accept or reject inviting requests as trade partner.'
          },
          {
            key: 'referral',
            title: 'Assign Referral Request Tasks',
            value: false,
            href: [
              '/network/referrals/buyer/received',
              '/network/referrals/seller/received'
            ],
            desc: 'If enabled, members can recommend items in their organization\'s marketplace view or trade partners for the referral requests.'
          }
        ]
      }
    ]
  },
  {
    key: 'production',
    title: 'Production',
    value: [
      {
        key: 'asset',
        title: 'Asset',
        value: [
          {
            key: 'asset',
            title: 'Manage Assets',
            value: true,
            href: [
              '/organization/assets'
            ],
            desc: 'Members with this item checked will have full control of assets of organization. They can create a new asset, edit or delete existing assets.'
          }
        ]
      },
      {
        key: 'microservice',
        title: 'Microservice',
        value: [
          {
            key: 'microservice',
            title: 'Manage Microservices',
            value: true,
            href: [
              '/microservices/management'
            ],
            desc: 'Members will have full control of microservices. They can create a new microservice, edit or publish microservices.'
          }
        ]
      },
      {
        key: 'file',
        title: 'File',
        value: [
          {
            key: 'file',
            title: 'Manage and Share Filethreads',
            value: true,
            href: [
              '/myfiles'
            ],
            desc: 'Members will have full control of filethreads. They can create a new filethread or share filethreads to others.'
          }
        ]
      },
      {
        key: 'marketplace',
        title: 'Marketplace',
        value: [
          {
            key: 'good',
            title: 'Manage Goods',
            value: true,
            href: [
              '/marketplace/good/management'
            ],
            desc: 'Members will be able to create new goods or edit existing goods.'
          },
          {
            key: 'goodTemplate',
            title: 'Manage Good Templates',
            value: true,
            href: [
              '/marketplace/template'
            ],
            desc: 'Members will be able to create good templates, publish or edit good templates.'
          }
        ]
      }
    ]
  },
  {
    key: 'transaction',
    title: 'Transactions',
    value: [
      {
        key: 'messaging',
        title: 'Messaging',
        value: [
          {
            key: 'messaging',
            title: 'Create Conversations and Invite Participants',
            value: true,
            href: [
              '/messaging'
            ],
            desc: 'Members will be able to create a new conversation or invite new participants to conversations.'
          }
        ]
      },
      {
        key: 'microservice',
        title: 'Microservice',
        value: [
          {
            key: 'marketplace',
            title: 'Request Microservices',
            value: true,
            href: [
              '/products'
            ],
            desc: 'Members can send requests of microservices in the name of organization.'
          },
          {
            key: 'demander',
            title: 'Handle Transacted Microservices as a Demander',
            value: true,
            href: [
              '/microservices/business/demander'
            ],
            desc: 'Members can handle microservice purchases in the name of organization after the contract is signed.'
          },
          {
            key: 'supplier',
            title: 'Handle Transacted Microservices as a Supplier',
            value: true,
            href: [
              '/microservices/business/supplier'
            ],
            desc: 'Members can handle microservice sales in the name of organization after the contract is signed.'
          }
        ]
      },
      {
        key: 'ticket',
        title: 'Ticket',
        value: [
          {
            key: 'demander',
            title: 'Treat Ticket Tasks as a Demander',
            value: true,
            href: [
              '/tickets/demander',
              '/procurement/demander/contract'
            ],
            desc: 'Members can manage tickets created by this organization or offered to this organization.'
          },
          {
            key: 'supplier',
            title: 'Treat Ticket Tasks as a Supplier',
            value: true,
            href: [
              '/tickets/supplier',
              '/procurement/supplier/contract'
            ],
            desc: 'Members can manage tickets offered by organization.'
          }
        ]
      },
      {
        key: 'procurement',
        title: 'Procurement',
        value: [
          {
            key: 'demander',
            title: 'Treat Procurement Tasks as a Demander',
            value: true,
            href: [
              '/procurement/demander'
            ],
            desc: 'Members can manage procurement tasks requested by organization.'
          },
          {
            key: 'supplier',
            title: 'Treat Procurement Tasks as a Supplier',
            value: true,
            href: [
              '/procurement/supplier'
            ],
            desc: 'Members will have full control of procurement tasks offerd by organization.'
          }
        ]
      },
      {
        key: 'marketplace',
        title: 'Marketplace',
        value: [
          {
            key: 'good',
            title: 'Purchase Goods',
            value: true,
            href: [
              '/marketplace/good/purchase'
            ],
            desc: 'Members will be able to request goods in the name of organization.'
          }
        ]
      },
      {
        key: 'network',
        title: 'Network',
        value: [
          {
            key: 'invitation',
            title: 'Find and Invite Trading Partners',
            value: false,
            href: [
              '/network',
              '/network/findpartner',
              '/network/partners'
            ],
            desc: 'If enabled, members can invite their organization\'s trade partners.'
          },
          {
            key: 'referral',
            title: 'Handle Referral Requests to Find Buyers or Sellers',
            value: false,
            href: [
              '/network/referrals'
            ],
            desc: 'If enabled, members can send requests to their trade partners to find sellers or buyers to broaden marketplace view and expand business.'
          }
        ]
      }
    ]
  }
];

export const REOFFERDRAFT = -1;
export const REOFFERED = 0;
export const OFFERDRAFT = 1;
export const OFFERED = 2;
export const OFFERREQUESTDRAFT = 3;
export const DRAFT = 4;
export const REQUESTED = 5;
export const PROGRESSING = 6;
export const REJECTED = 7;
export const ARCHIVED = 8;
export const NEGOTIATION = 9;
export const CUSTOMER_REMOVED = 10;
export const SUPPLIER_REMOVED = 11;

export const requestStatusInfo = [
  {
    value: `${OFFERDRAFT}`,
    label: 'Offering Draft',
    color: 'primary'
  },
  {
    value: `${OFFERED}`,
    label: 'Offered',
    color: 'secondary'
  },
  {
    value: `${REOFFERED}`,
    label: 'Draft',
    color: 'primary'
  },
  {
    value: `${DRAFT}`,
    label: 'Draft',
    color: 'primary'
  },
  {
    value: `${REQUESTED}`,
    label: 'Requested',
    color: 'warning'
  },
  {
    value: `${PROGRESSING}`,
    label: 'Progressing',
    color: 'success'
  },
  {
    value: `${REJECTED}`,
    label: 'Rejected',
    color: 'error'
  },
  {
    value: `${ARCHIVED}`,
    label: 'Archived',
    color: 'primary'
  }
];

export const NONE_TYPE_ITEM = 0;
export const MICROSERVICE = 1;
export const GOOD = 2;
export const MICROSERVICE_BUNDLE = 3;
export const ASSET = 4;
export const GOOD_BUNDLE = 5;
export const ASSET_BUNDLE = 6;
export const CHAT = 7;
export const CONTRACT = 8;
export const FILETHREAD = 9;
export const TICKET = 10;
export const INVOICE = 11;
export const RFQ = 12;
export const PURCHASEORDER = 13;
export const FRIEND = 14;
export const REFERRAL = 15;
export const SCHEDULE = 16;
export const PURCHASEDITEM = 17;
export const BS_FAVORITE = 18;
export const BS_SEARCH_HISTORY = 19;
export const INFORMATION = 20;
export const ORGINVITE = 21;

export const itemTypeInfo = [
  {
    value: `${NONE_TYPE_ITEM}`,
    label: 'None',
    color: 'error',
    rColor: '#9c27b0'
  },
  {
    value: `${MICROSERVICE}`,
    label: 'Microservice',
    color: 'warning',
    rColor: '#3f51b5'
  },
  {
    value: `${GOOD}`,
    label: 'Good',
    color: 'primary',
    rColor: '#ff5722'
  },
  {
    value: `${MICROSERVICE_BUNDLE}`,
    label: 'Service Bundle',
    color: 'warning',
    rColor: '#009688'
  },
  {
    value: `${ASSET}`,
    label: 'Asset',
    color: 'secondary',
    rColor: '#ef6c00'
  },
  {
    value: `${GOOD_BUNDLE}`,
    label: 'Good Bundle',
    color: 'primary',
    rColor: '#00e676'
  },
  {
    value: `${ASSET_BUNDLE}`,
    label: 'Asset Bundle',
    color: 'secondary',
    rColor: '#9e9d24'
  }
];

export const null2empty = (value: any) => {
  if (value === null || value === undefined) return '';
  return value;
};

export const calcTotalPrice = (collected: any, b: number): number => {
  let ret: number = b;
  Object.keys(collected).forEach((key) => {
    if (collected[key].value !== 'No' && collected[key].value !== false) {
      if (collected[key].cost) ret += parseFloat(`${collected[key].cost}`);
      if (collected[key].child) ret += calcTotalPrice(collected[key].child, 0);
      if (collected[key].untitled) ret += calcTotalPrice(collected[key].untitled, 0);
    }
  });
  return ret;
};

export const REQUEST_ONE_OFF = 0;
export const REQUEST_CUSTOM = 1;
export const REQUEST_FINANCIAL = 2;

export const ACCZIOM_NONE = 0;
export const ACCZIOM_USER = 1;
export const ACCZIOM_ORG = 2;
export const ACCZIOM_TEAM = 3;
export const ACCZIOM_MEMBER = 4;

export const getUserIdByType = (body: any): string => {
  if (body.type === ACCZIOM_USER) return body.uid;
  if (body.type === ACCZIOM_ORG) return body.oid;
  if (body.type === ACCZIOM_MEMBER) {
    if (body.muid) return body.muid;
    return body.uid;
  }
  return '';
};

export const USUAL_CHAT_THREAD = 0;
export const REQUEST_CHAT_THREAD = 1;
export const INNERORG_CHAT_THREAD = 2;
export const PROPOSAL_CHAT_THREAD = 3;
export const CONTRACT_CHAT_THREAD = 4;
export const FRIEND_CHAT_THREAD = 5;

export const getPrefix = (type: number): string => {
  let strPrefix: string = '';
  switch (type) {
    case MICROSERVICE:
      strPrefix = 'Request ';
      break;
    case MICROSERVICE_BUNDLE:
    case ASSET_BUNDLE:
      strPrefix = 'Bundle ';
      break;
    case ASSET:
      strPrefix = 'Asset ';
      break;
    case GOOD:
      strPrefix = 'Good ';
      break;
    default:
      break;
  }
  return strPrefix;
};

export const GOOD_PAGE_SIZE = 12;

export const TEMPLATE_DRAFT = 0;
export const TEMPLATE_PUBLISHED = 1;

export const NO_MEMBER_MODE = 0;
export const ALL_MEMBER_MODE = 1;
export const CHAT_MEMBER_MODE = 2;

export const NO_USER_MODE = 0;
export const ONLY_USER_MODE = 1;
export const ONLY_ORG_MODE = 2;
export const BOTH_USER_ORG_MODE = 3;

export const NO_TITLE_FIRSTMSG = 0;
export const ONLY_TITLE = 1;
export const BOTH_TITLE_FIRSTMSG = 2;

export const PRICE_PER_UNIT = 0;
export const TOTAL_PRICE = 1;
export const priceOptionModel = [
  {
    value: `${PRICE_PER_UNIT}`,
    label: 'Price Per Unit'
  },
  {
    value: `${TOTAL_PRICE}`,
    label: 'Total Price'
  }
];

export const DEFAULT_NOTIFICATION_TIME = -1;

export const NOTIFICATION_TIME_LIST = [
  {
    value: '15',
    label: '15 minutes',
  },
  {
    value: '30',
    label: 'Half hour',
  },
  {
    value: '60',
    label: '1 hour'
  },
  {
    value: '1440',
    label: '1 day'
  }
];

export const FINANCIAL_ASSET = 0;
export const PLANT_EQUIPMENT = 1;
export const LAND_BUILDING = 2;
export const INTANGIBLE_ASSET = 3;
export const MOTOR_VEHICLE = 4;
export const assetTypeModel = [
  {
    value: `${FINANCIAL_ASSET}`,
    label: 'Financial Asset',
    href: 'financial-asset'
  },
  {
    value: `${PLANT_EQUIPMENT}`,
    label: 'Plant & Equipment',
    href: 'plant-equipment'
  },
  {
    value: `${LAND_BUILDING}`,
    label: 'Land & Building',
    href: 'land-building'
  },
  {
    value: `${INTANGIBLE_ASSET}`,
    label: 'Intangible Asset',
    href: 'intangible-asset'
  },
  {
    value: `${MOTOR_VEHICLE}`,
    label: 'Motor Vehicle',
    href: 'motor-vehicle'
  }
];

export const TODO_RESOURCE_COMMENT = 0;
export const TODO_RESOURCE_ATTACHMENT = 1;

export const DELIVERY_MODE = 0;
export const PAYMENT_MODE = 1;
export const partsOfContract = {
  delivery: DELIVERY_MODE,
  payment: PAYMENT_MODE
};

export const TRADE_CONTRACT = 0;
export const FINANCE_CONTRACT = 1;
export const contractTypeModel = [
  {
    value: `${TRADE_CONTRACT}`,
    label: 'Trade Contract'
  },
  {
    value: `${FINANCE_CONTRACT}`,
    label: 'Finance Contract'
  }
];

export const UPFRONT_PAYMENT = 0;
export const COMPLETION_PAYMENT = 1;
export const TWOTIME_PAYMENT = 2;
export const PERIOD_PAYMENT = 3;
export const DELIVERY_PAYMENT = 4;

export const SIMPLE_INTEREST_TYPE = 0;
export const COMPOUND_INTEREST_TYPE = 1;
export const interestTypeModel = [
  {
    value: `${SIMPLE_INTEREST_TYPE}`,
    label: 'Simple Interest'
  },
  {
    value: `${COMPOUND_INTEREST_TYPE}`,
    label: 'Compound Interest'
  }
];

export const ON_COMMENCEMENT = 0;
export const ON_COMPLETION = 1;
export const paymentPointDelivery = [
  {
    value: `${ON_COMMENCEMENT}`,
    label: 'commencement'
  },
  {
    value: `${ON_COMPLETION}`,
    label: 'completion'
  }
];

export const NOT_REQUIRED_PO = 0;
export const REQUIRED_PO = 1;

export const CONTRACT_MODE = 0;
export const PURCHASE_ORDER_MODE = 1;
export const scheduleFromModel = [
  {
    value: `${CONTRACT_MODE}`,
    label: 'Contract'
  },
  {
    value: `${PURCHASE_ORDER_MODE}`,
    label: 'Purchase Order'
  }
];

export const FROM_NONE = 0;
export const FROM_PROPOSAL = 1;
export const FROM_REQUEST = 2;

export const BUNDLE_GOLD_LIST = 0;
export const BUNDLE_SILVER_LIST = 1;
export const BUNDLE_BRONZE_LIST = 2;

export const SERVICE_BUNDLE_TYPES = [BUNDLE_BRONZE_LIST, BUNDLE_SILVER_LIST, BUNDLE_GOLD_LIST];

export const DAY_MILISECONDS = 86400000;
export const MINUTE_MILISECONDS = 60000;

export const PAY_CREDIT_CARD = 1;
export const PAY_DIRECT_DEBIT = 2;

export const PAY_STRIPE = 1;
export const PAY_TEXT_STRIPE = 'stripe';
export const PAY_BIZCORE = 2;
export const PAY_TEXT_BIZCORE = 'bizcore';
export const PAY_CRYPTOCURRENCY = 3;
export const PAY_TEXT_CRYPTOCURRENCY = 'cryptocurrency';

export const fullTags = [
  {
    type: 'Apps & functions',
    tags: [
      'Accounting systems',
      'Bills & expenses',
      'CRM systems',
      'System Conversion',
      'Custom Integration',
      'Debtor tracking',
      'Ecommerce',
      'Financial services',
      'Inventory',
      'Invoicing & jobs',
      'Payments',
      'Payroll HR',
      'Point of sale',
      'Practice management',
      'Reporting',
      'Time tracking',
      'Document systems'
    ],
    color: '#4caf50'
  },
  {
    type: 'Tax Tags',
    tags: [
      'Goods & services tax',
      'Fuel tax',
      'Income tax',
      'Luxury car tax',
      'Wine equalization tax',
      'Payroll tax',
      'Fringe Benefits Tax'
    ],
    color: '#ff9800'
  },
  {
    type: 'Activities',
    tags: [
      'Bookkeeping',
      'Payroll',
      'Accounting',
      'Advisory',
      'Corporate Affairs'
    ],
    color: '#9c27b0'
  },
  {
    type: 'Industry tags',
    tags: [
      'Agriculture, Forestry and Fishing',
      'Mining',
      'Manufacturing',
      'Electricity, Gas, Water and Waste Services',
      'Construction',
      'Wholesale Trade',
      'Retail Trade',
      'Accommodation and Food Services',
      'Transport, Postal and Warehousing',
      'Information Media and Telecommunications',
      'Financial and Insurance Services',
      'Rental, Hiring and Real Estate Services',
      'Professional, Scientific and Technical Services',
      'Administrative and Support Services',
      'Public Administration and Safety',
      'Education and Training',
      'Health Care and Social Assistance',
      'Arts and Recreation Services'
    ],
    color: '#673ab7'
  }
];

export const PRODUCT_CATEGORIES = [
  'Nursery Production (Under Cover)',
  'Nursery Production (Outdoors)',
  'Turf Growing',
  'Floriculture Production (Under Cover)',
  'Floriculture Production (Outdoors)',
  'Mushroom Growing',
  'Vegetable Growing (Under Cover)',
  'Vegetable Growing (Outdoors)',
  'Grape Growing',
  'Kiwifruit Growing',
  'Berry Fruit Growing',
  'Apple and Pear Growing',
  'Stone Fruit Growing',
  'Citrus Fruit Growing',
  'Olive Growing',
  'Other Fruit and Tree Nut Growing',
  'Sheep Farming (Specialised)',
  'Beef Cattle Farming (Specialised)',
  'Beef Cattle Feedlots (Specialised)',
  'Sheep-Beef Cattle Farming',
  'Grain-Sheep or Grain-Beef Cattle Farming',
  'Rice Growing',
  'Other Grain Growing',
  'Sugar Cane Growing',
  'Cotton Growing',
  'Other Crop Growing n.e.c. ',
  'Dairy Cattle Farming',
  'Poultry Farming (Meat)',
  'Poultry Farming (Eggs)',
  'Deer Farming',
  'Horse Farming',
  'Pig Farming',
  'Beekeeping',
  'Other Livestock Farming n.e.c.',
  'Offshore Longline and Rack Aquaculture',
  'Offshore Caged Aquaculture',
  'Onshore Aquaculture',
  'Forestry',
  'Logging',
  'Rock Lobster and Crab Potting',
  'Prawn Fishing',
  'Line Fishing',
  'Fish Trawling, Seining and Netting',
  'Other Fishing',
  'Hunting and Trapping',
  'Forestry Support Services',
  'Cotton Ginning',
  'Shearing Services',
  'Other Agriculture and Fishing Support Services',
  'Coal Mining',
  'Oil and Gas Extraction',
  'Iron Ore Mining',
  'Bauxite Mining',
  'Copper Ore Mining',
  'Gold Ore Mining',
  'Mineral Sand Mining',
  'Nickel Ore Mining',
  'Silver-Lead-Zinc Ore Mining',
  'Other Metal Ore Mining',
  'Gravel and Sand Quarrying',
  'Other Construction Material Mining',
  'Other Non-Metallic Mineral Mining and Quarrying',
  'Petroleum Exploration',
  'Mineral Exploration',
  'Other Mining Support Services',
  'Meat Processing',
  'Poultry Processing',
  'Cured Meat and Smallgoods Manufacturing',
  'Seafood Processing',
  'Milk and Cream Processing',
  'Ice Cream Manufacturing',
  'Cheese and Other Dairy Product Manufacturing',
  'Fruit and Vegetable Processing',
  'Oil and Fat Manufacturing',
  'Grain Mill Product Manufacturing',
  'Cereal, Pasta and Baking Mix Manufacturing',
  'Bread Manufacturing (Factory based)',
  'Cake and Pastry Manufacturing (Factory based)',
  'Biscuit Manufacturing (Factory based)',
  'Bakery Product Manufacturing (Non-factory based)',
  'Sugar Manufacturing',
  'Confectionery Manufacturing',
  'Potato, Corn and Other Crisp Manufacturing',
  'Prepared Animal and Bird Feed Manufacturing',
  'Other Food Product Manufacturing n.e.c.',
  'Soft Drink, Cordial and Syrup Manufacturing',
  'Beer Manufacturing',
  'Spirit Manufacturing',
  'Wine and Other Alcoholic Beverage Manufacturing',
  'Cigarette and Tobacco Product Manufacturing',
  'Wool Scouring',
  'Natural Textile Manufacturing',
  'Synthetic Textile Manufacturing',
  'Leather Tanning, Fur Dressing and Leather Product Manufacturing',
  'Textile Floor Covering Manufacturing',
  'Rope, Cordage and Twine Manufacturing',
  'Cut and Sewn Textile Product Manufacturing',
  'Textile Finishing and Other Textile Product Manufacturing',
  'Knitted Product Manufacturing',
  'Clothing Manufacturing',
  'Footwear Manufacturing',
  'Log Sawmilling',
  'Wood Chipping',
  'Timber Resawing and Dressing',
  'Prefabricated Wooden Building Manufacturing',
  'Wooden Structural Fitting and Component Manufacturing',
  'Veneer and Plywood Manufacturing',
  'Reconstituted Wood Product Manufacturing',
  'Other Wood Product Manufacturing n.e.c.',
  'Pulp, Paper and Paperboard Manufacturing',
  'Corrugated Paperboard and Paperboard Container Manufacturing',
  'Paper Bag Manufacturing',
  'Paper Stationery Manufacturing',
  'Sanitary Paper Product Manufacturing',
  'Other Converted Paper Product Manufacturing',
  'Printing',
  'Printing Support Services',
  'Reproduction of Recorded Media',
  'Petroleum Refining and Petroleum Fuel Manufacturing',
  'Other Petroleum and Coal Product Manufacturing',
  'Industrial Gas Manufacturing',
  'Basic Organic Chemical Manufacturing',
  'Basic Inorganic Chemical Manufacturing',
  'Synthetic Resin and Synthetic Rubber Manufacturing',
  'Other Basic Polymer Manufacturing',
  'Fertiliser Manufacturing',
  'Pesticide Manufacturing',
  'Human Pharmaceutical and Medicinal Product Manufacturing',
  'Veterinary Pharmaceutical and Medicinal Product Manufacturing',
  'Cleaning Compound Manufacturing',
  'Cosmetic and Toiletry Preparation Manufacturing',
  'Photographic Chemical Product Manufacturing',
  'Explosive Manufacturing',
  'Other Basic Chemical Product Manufacturing n.e.c.',
  'Polymer Film and Sheet Packaging Material Manufacturing',
  'Rigid and Semi-Rigid Polymer Product Manufacturing',
  'Polymer Foam Product Manufacturing',
  'Tyre Manufacturing',
  'Adhesive Manufacturing',
  'Paint and Coatings Manufacturing',
  'Other Polymer Product Manufacturing',
  'Natural Rubber Product Manufacturing',
  'Glass and Glass Product Manufacturing',
  'Clay Brick Manufacturing',
  'Other Ceramic Product Manufacturing',
  'Cement and Lime Manufacturing',
  'Plaster Product Manufacturing',
  'Ready-Mixed Concrete Manufacturing',
  'Concrete Product Manufacturing',
  'Other Non-Metallic Mineral Product Manufacturing',
  'Iron Smelting and Steel Manufacturing',
  'Iron and Steel Casting',
  'Steel Pipe and Tube Manufacturing',
  'Alumina Production',
  'Aluminium Smelting',
  'Copper, Silver, Lead and Zinc Smelting and Refining',
  'Other Basic Non-Ferrous Metal Manufacturing',
  'Non-Ferrous Metal Casting',
  'Aluminium Rolling, Drawing, Extruding',
  'Other Basic Non-Ferrous Metal Product Manufacturing',
  'Iron and Steel Forging',
  'Structural Steel Fabricating',
  'Prefabricated Metal Building Manufacturing',
  'Architectural Aluminium Product Manufacturing',
  'Metal Roof and Guttering Manufacturing (except Aluminium)',
  'Other Structural Metal Product Manufacturing',
  'Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing',
  'Other Metal Container Manufacturing',
  'Sheet Metal Product Manufacturing (except Metal Structural and Container Products)',
  'Spring and Wire Product Manufacturing',
  'Nut, Bolt, Screw and Rivet Manufacturing',
  'Metal Coating and Finishing',
  'Other Fabricated Metal Product Manufacturing n.e.c.',
  'Motor Vehicle Manufacturing',
  'Motor Vehicle Body and Trailer Manufacturing',
  'Automotive Electrical Component Manufacturing',
  'Other Motor Vehicle Parts Manufacturing',
  'Shipbuilding and Repair Services',
  'Boatbuilding and Repair Services',
  'Railway Rolling Stock Manufacturing and Repair Services',
  'Aircraft Manufacturing and Repair Services',
  'Other Transport Equipment Manufacturing n.e.c.',
  'Photographic, Optical and Ophthalmic Equipment Manufacturing',
  'Medical and Surgical Equipment Manufacturing',
  'Other Professional and Scientific Equipment Manufacturing',
  'Computer and Electronic Office Equipment Manufacturing',
  'Communication Equipment Manufacturing',
  'Other Electronic Equipment Manufacturing',
  'Electric Cable and Wire Manufacturing',
  'Electric Lighting Equipment Manufacturing',
  'Other Electrical Equipment Manufacturing',
  'Whiteware Appliance Manufacturing',
  'Other Domestic Appliance Manufacturing',
  'Pump and Compressor Manufacturing',
  'Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing',
  'Agricultural Machinery and Equipment Manufacturing',
  'Mining and Construction Machinery Manufacturing',
  'Machine Tool and Parts Manufacturing',
  'Other Specialised Machinery and Equipment Manufacturing',
  'Lifting and Material Handling Equipment Manufacturing',
  'Other Machinery and Equipment Manufacturing n.e.c.',
  'Wooden Furniture and Upholstered Seat Manufacturing',
  'Metal Furniture Manufacturing',
  'Mattress Manufacturing',
  'Other Furniture Manufacturing',
  'Jewellery and Silverware Manufacturing',
  'Toy, Sporting and Recreational Product Manufacturing',
  'Other Manufacturing n.e.c.',
  'Fossil Fuel Electricity Generation',
  'Hydro-Electricity Generation',
  'Other Electricity Generation',
  'Electricity Transmission',
  'Electricity Distribution',
  'On Selling Electricity and Electricity Market Operation',
  'Gas Supply',
  'Water Supply',
  'Sewerage and Drainage Services',
  'Solid Waste Collection Services',
  'Other Waste Collection Services',
  'Waste Treatment and Disposal Services',
  'Waste Remediation and Materials Recovery Services',
  'House Construction',
  'Other Residential Building Construction',
  'Non-Residential Building Construction',
  'Road and Bridge Construction',
  'Other Heavy and Civil Engineering Construction',
  'Land Development and Subdivision',
  'Site Preparation Services',
  'Concreting Services',
  'Bricklaying Services',
  'Roofing Services',
  'Structural Steel Erection Services',
  'Plumbing Services',
  'Electrical Services',
  'Air Conditioning and Heating Services',
  'Fire and Security Alarm Installation Services',
  'Other Building Installation Services',
  'Plastering and Ceiling Services',
  'Carpentry Services',
  'Tiling and Carpeting Services',
  'Painting and Decorating Services',
  'Glazing Services',
  'Landscape Construction Services',
  'Hire of Construction Machinery with Operator',
  'Other Construction Services n.e.c.',
  'Wool Wholesaling',
  'Cereal Grain Wholesaling',
  'Other Agricultural Product Wholesaling',
  'Petroleum Product Wholesaling',
  'Metal and Mineral Wholesaling',
  'Industrial and Agricultural Chemical Product Wholesaling',
  'Timber Wholesaling',
  'Plumbing Goods Wholesaling',
  'Other Hardware Goods Wholesaling',
  'Agricultural and Construction Machinery Wholesaling',
  'Other Specialised Industrial Machinery and Equipment Wholesaling',
  'Professional and Scientific Goods Wholesaling',
  'Computer and Computer Peripheral Wholesaling',
  'Telecommunication Goods Wholesaling',
  'Other Electrical and Electronic Goods Wholesaling',
  'Other Machinery and Equipment Wholesaling n.e.c.',
  'Car Wholesaling',
  'Commercial Vehicle Wholesaling',
  'Trailer and Other Motor Vehicle Wholesaling',
  'Motor Vehicle New Parts Wholesaling',
  'Motor Vehicle Dismantling and Used Parts Wholesaling',
  'General Line Grocery Wholesaling',
  'Meat, Poultry and Smallgoods Wholesaling',
  'Dairy Produce Wholesaling',
  'Fish and Seafood Wholesaling',
  'Fruit and Vegetable Wholesaling',
  'Liquor and Tobacco Product Wholesaling',
  'Other Grocery Wholesaling',
  'Textile Product Wholesaling',
  'Clothing and Footwear Wholesaling',
  'Pharmaceutical and Toiletry Goods Wholesaling',
  'Furniture and Floor Covering Wholesaling',
  'Jewellery and Watch Wholesaling',
  'Kitchen and Diningware Wholesaling',
  'Toy and Sporting Goods Wholesaling',
  'Book and Magazine Wholesaling',
  'Paper Product Wholesaling',
  'Other Goods Wholesaling n.e.c.',
  'Commission-Based Wholesaling',
  'Car Retailing',
  'Motor Cycle Retailing',
  'Trailer and Other Motor Vehicle Retailing',
  'Motor Vehicle Parts Retailing',
  'Tyre Retailing',
  'Fuel Retailing',
  'Supermarket and Grocery Stores',
  'Fresh Meat, Fish and Poultry Retailing',
  'Fruit and Vegetable Retailing',
  'Liquor Retailing',
  'Other Specialised Food Retailing',
  'Furniture Retailing',
  'Floor Coverings Retailing',
  'Houseware Retailing',
  'Manchester and Other Textile Goods Retailing',
  'Electrical, Electronic and Gas Appliance Retailing',
  'Computer and Computer Peripheral Retailing',
  'Other Electrical and Electronic Goods Retailing',
  'Hardware and Building Supplies Retailing',
  'Garden Supplies Retailing',
  'Sport and Camping Equipment Retailing',
  'Entertainment Media Retailing',
  'Toy and Game Retailing',
  'Newspaper and Book Retailing',
  'Marine Equipment Retailing',
  'Clothing Retailing',
  'Footwear Retailing',
  'Watch and Jewellery Retailing',
  'Other Personal Accessory Retailing',
  'Department Stores',
  'Pharmaceutical, Cosmetic and Toiletry Goods Retailing',
  'Stationery Goods Retailing',
  'Antique and Used Goods Retailing',
  'Flower Retailing',
  'Other Store-Based Retailing n.e.c.',
  'Non-Store Retailing',
  'Retail Commission-Based Buying and/or Selling',
  'Accommodation',
  'Cafes and Restaurants',
  'Takeaway Food Services',
  'Catering Services',
  'Pubs, Taverns and Bars',
  'Clubs (Hospitality)',
  'Road Freight Transport',
  'Interurban and Rural Bus Transport',
  'Urban Bus Transport (Including Tramway)',
  'Taxi and Other Road Transport',
  'Rail Freight Transport',
  'Rail Passenger Transport',
  'Water Freight Transport',
  'Water Passenger Transport',
  'Air and Space Transport',
  'Scenic and Sightseeing Transport',
  'Pipeline Transport',
  'Other Transport n.e.c.',
  'Postal Services',
  'Courier Pick-up and Delivery Services',
  'Stevedoring Services',
  'Port and Water Transport Terminal Operations',
  'Other Water Transport Support Services',
  'Airport Operations and Other Air Transport Support Services',
  'Customs Agency Services',
  'Freight Forwarding Services',
  'Other Transport Support Services n.e.c.',
  'Grain Storage Services',
  'Other Warehousing and Storage Services',
  'Newspaper Publishing',
  'Magazine and Other Periodical Publishing',
  'Book Publishing',
  'Directory and Mailing List Publishing',
  'Other Publishing (except Software, Music and Internet)',
  'Software Publishing',
  'Motion Picture and Video Production',
  'Motion Picture and Video Distribution',
  'Motion Picture Exhibition',
  'Post-production Services and Other Motion Picture and Video Activities',
  'Music Publishing',
  'Music and Other Sound Recording Activities',
  'Radio Broadcasting',
  'Free-to-Air Television Broadcasting',
  'Cable and Other Subscription Broadcasting',
  'Internet Publishing and Broadcasting',
  'Wired Telecommunications Network Operation',
  'Other Telecommunications Network Operation',
  'Other Telecommunications Services',
  'Internet Service Providers and Web Search Portals',
  'Data Processing and Web Hosting Services',
  'Electronic Information Storage Services',
  'Libraries and Archives',
  'Other Information Services',
  'Central Banking',
  'Banking',
  'Building Society Operation',
  'Credit Union Operation',
  'Other Depository Financial Intermediation',
  'Non-Depository Financing',
  'Financial Asset Investing',
  'Life Insurance',
  'Health Insurance',
  'General Insurance',
  'Superannuation Funds',
  'Financial Asset Broking Services',
  'Other Auxiliary Finance and Investment Services',
  'Auxiliary Insurance Services',
  'Passenger Car Rental and Hiring',
  'Other Motor Vehicle and Transport Equipment Rental and Hiring',
  'Farm Animal and Bloodstock Leasing',
  'Heavy Machinery and Scaffolding Rental and Hiring',
  'Video and Other Electronic Media Rental and Hiring',
  'Other Goods and Equipment Rental and Hiring n.e.c.',
  'Non-Financial Intangible Assets (Except Copyrights) Leasing',
  'Residential Property Operators',
  'Non-Residential Property Operators',
  'Real Estate Services',
  'Scientific Research Services',
  'Architectural Services',
  'Surveying and Mapping Services',
  'Engineering Design and Engineering Consulting Services',
  'Other Specialised Design Services',
  'Scientific Testing and Analysis Services',
  'Legal Services',
  'Accounting Services',
  'Advertising Services',
  'Market Research and Statistical Services',
  'Corporate Head Office Management Services',
  'Management Advice and Related Consulting Services',
  'Veterinary Services',
  'Professional Photographic Services',
  'Other Professional, Scientific and Technical Services n.e.c.',
  'Computer System Design and Related Services',
  'Employment Placement and Recruitment Services',
  'Labour Supply Services',
  'Travel Agency and Tour Arrangement Services',
  'Office Administrative Services',
  'Document Preparation Services',
  'Credit Reporting and Debt Collection Services',
  'Call Centre Operation',
  'Other Administrative Services n.e.c.',
  'Building and Other Industrial Cleaning Services',
  'Building Pest Control Services',
  'Gardening Services',
  'Packaging Services',
  'Central Government Administration',
  'State Government Administration',
  'Local Government Administration',
  'Justice',
  'Domestic Government Representation',
  'Foreign Government Representation',
  'Defence',
  'Police Services',
  'Investigation and Security Services',
  'Fire Protection and Other Emergency Services',
  'Correctional and Detention Services',
  'Other Public Order and Safety Services',
  'Regulatory Services',
  'Preschool Education',
  'Primary Education',
  'Secondary Education',
  'Combined Primary and Secondary Education',
  'Special School Education',
  'Technical and Vocational Education and Training',
  'Higher Education',
  'Sports and Physical Recreation Instruction',
  'Arts Education',
  'Adult, Community and Other Education n.e.c.',
  'Educational Support Services',
  'Hospitals (Except Psychiatric Hospitals)',
  'Psychiatric Hospitals',
  'General Practice Medical Services',
  'Specialist Medical Services',
  'Pathology and Diagnostic Imaging Services',
  'Dental Services',
  'Optometry and Optical Dispensing',
  'Physiotherapy Services',
  'Chiropractic and Osteopathic Services',
  'Other Allied Health Services',
  'Ambulance Services',
  'Other Health Care Services n.e.c.',
  'Aged Care Residential Services',
  'Other Residential Care Services',
  'Child Care Services',
  'Other Social Assistance Services',
  'Museum Operation',
  'Zoological and Botanical Gardens Operation',
  'Nature Reserves and Conservation Parks Operation',
  'Performing Arts Operation',
  'Creative Artists, Musicians, Writers and Performers',
  'Performing Arts Venue Operation',
  'Health and Fitness Centres and Gymnasia Operation',
  'Sports and Physical Recreation Clubs and Sports Professionals',
  'Sports and Physical Recreation Venues, Grounds and Facilities Operation',
  'Sports and Physical Recreation Administrative Service',
  'Horse and Dog Racing Administration and Track Operation',
  'Other Horse and Dog Racing Activities',
  'Amusement Parks and Centres Operation',
  'Amusement and Other Recreational Activities n.e.c.',
  'Casino Operation',
  'Lottery Operation',
  'Other Gambling Activities',
  'Automotive Electrical Services',
  'Automotive Body, Paint and Interior Repair',
  'Other Automotive Repair and Maintenance',
  'Domestic Appliance Repair and Maintenance',
  'Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance',
  'Other Machinery and Equipment Repair and Maintenance',
  'Clothing and Footwear Repair',
  'Other Repair and Maintenance n.e.c.',
  'Hairdressing and Beauty Services',
  'Diet and Weight Reduction Centre Operation',
  'Funeral, Crematorium and Cemetery Services',
  'Laundry and Dry-Cleaning Services',
  'Photographic Film Processing',
  'Parking Services',
  'Brothel Keeping and Prostitution Services',
  'Other Personal Services n.e.c.',
  'Religious Services',
  'Business and Professional Association Services',
  'Labour Association Services',
  'Other Interest Group Services n.e.c.',
  'Private Households Employing Staff',
  'Undifferentiated Goods-Producing Activities of Households for Own Use',
  'Undifferentiated Service-Producing Activities of Households for Own Use',
];

export const STRIPE_FEE = 50;
export const STRIPE_MAX_CHARGE = 99999999;

export const LENGTH_MINUTE = 0;
export const LENGTH_HOUR = 1;
export const LENGTH_DAY = 2;
export const LENGTH_WEEK = 3;
export const LENGTH_FORTNIGHT = 4;
export const LENGTH_MONTH = 5;
export const LENGTH_YEAR = 6;

export const periodLengthList = [
  {
    type: LENGTH_MINUTE,
    text: 'Minute',
    adverb: 'Minutely',
    minutes: 1,
    days: 1
  },
  {
    type: LENGTH_HOUR,
    text: 'Hour',
    adverb: 'Hourly',
    minutes: 60,
    days: 1
  },
  {
    type: LENGTH_DAY,
    text: 'Day',
    adverb: 'Daily',
    minutes: 60 * 24,
    days: 1
  },
  {
    type: LENGTH_WEEK,
    text: 'Week',
    adverb: 'Weekly',
    minutes: 60 * 24 * 7,
    days: 7
  },
  {
    type: LENGTH_FORTNIGHT,
    text: 'Fortnight',
    adverb: 'Fortnightly',
    minutes: 60 * 24 * 14,
    days: 14
  },
  {
    type: LENGTH_MONTH,
    text: 'Month',
    adverb: 'Monthly',
    minutes: 60 * 24 * 30,
    days: 30
  },
  {
    type: LENGTH_YEAR,
    text: 'Year',
    adverb: 'Annually',
    minutes: 60 * 24 * 365,
    days: 365
  }
];

export const SCHEDULE_OFF = 0;
export const SCHEDULE_ON = 1;

export const MRC_DEPOSIT = 'deposit';
export const MRC_WITHDRAW = 'withdraw';
export const MRC_BALANCE = 'balance';
export const MRC_HISTORY = 'history';
export const MRC_NFT = 'nft';
export const MRC_INFO = 'info';
export const MRC_SPEND = 'spend';
export const MRC_TRANSFER = 'transfer';

// procurement status string
export const STATUS_TEXT_RESPONDED = 'Responded'; // for rfq, proposal
export const STATUS_TEXT_PROGRESSING = 'Progressing'; // for rfq, proposal
export const STATUS_TEXT_AWAITING = 'Awaiting'; // for rfq, proposal
export const STATUS_TEXT_CLOSED = 'Closed'; // for rfq, proposal
export const STATUS_TEXT_PENDING = 'Pending'; // for purchase order
export const STATUS_TEXT_ACCEPTED = 'Accepted'; // for purchase order
export const STATUS_TEXT_SUBMITTED = 'Submitted'; // for rfq, proposal, contract, schedule, invoice
export const STATUS_TEXT_CUSTOMER_SIGNED = 'Customer-Signed'; // for contract
export const STATUS_TEXT_SUPPLIER_SIGNED = 'Supplier-Signed'; // for contract
export const STATUS_TEXT_CUSTOMER_CONFIRMED = 'Customer-Confirmed'; // for schedule
export const STATUS_TEXT_SUPPLIER_CONFIRMED = 'Supplier-Confirmed'; // for schedule
export const STATUS_TEXT_ACTIVATED = 'Activated'; // for contract, schedule
export const STATUS_TEXT_POSTED = 'Posted'; // for invoice
export const STATUS_TEXT_WAITING = 'Waiting'; // for invoice
export const STATUS_TEXT_PAID = 'Paid'; // for invoice
export const STATUS_TEXT_CONFIRMED = 'Confirmed'; // for acceptence note

export const STATUS_PENDING = 0;
export const STATUS_ACTIVATED = 1;
export const STATUS_DECLINED = 2;
export const STATUS_DEACTIVATED = 3;
export const STATUS_RECOMMENDED = 4;
export const STATUS_REJECTED = 5;
export const STATUS_NORMAL = 6;
export const STATUS_LOANED = 7;
export const STATUS_LEASED = 8;
export const STATUS_BROKEN = 9;
export const STATUS_EXPIRED = 10;

export const itemStatusInfo = [
  {
    value: `${STATUS_NORMAL}`,
    label: 'Normal',
    color: 'success'
  },
  {
    value: `${STATUS_LOANED}`,
    label: 'Loaned',
    color: 'warning'
  },
  {
    value: `${STATUS_LEASED}`,
    label: 'Leased',
    color: 'primary'
  },
  {
    value: `${STATUS_BROKEN}`,
    label: 'Broken',
    color: 'secondary'
  },
  {
    value: `${STATUS_EXPIRED}`,
    label: 'Expired',
    color: 'error'
  }
];

export const BUYER_TO_SELLER = 1;
export const SELLER_TO_BUYER = 2;

export const ITEM_VIEW = 1;

export const TEAM_ITEM_MODE = 1;
export const ORG_ITEM_MODE = 2;
export const REFERED_ITEM_MODE = 3;

export const ONLY_ITEM_MODE = 1;
export const ONLY_BUNDLE_MODE = 2;
export const BOTH_ITEM_BUNDLE_MODE = 3;

export const FIND_SELLER_TYPE = 1;
export const FIND_BUYER_TYPE = 2;
export const findRefereeTypes = [
  {
    value: `${FIND_SELLER_TYPE}`,
    label: 'seller'
  },
  {
    value: `${FIND_BUYER_TYPE}`,
    label: 'buyer'
  }
];

export const ALL_USER_MODE = 0;
export const ONLY_FRIEND_MODE = 1;
export const FRIEND_ME_MODE = 2;

export const SERVICE_PUBLISHED = 'published';
export const SERVICE_READY = 'ready';
export const SERVICE_EDITING = 'in edit';
export const SERVICE_REMOVED = 'removed';

export const serviceStatusList = [
  {
    id: SERVICE_PUBLISHED,
    value: 'Published',
    color: '#4CAF50'
  },
  {
    id: SERVICE_READY,
    value: 'Ready',
    color: '#FF9800'
  },
  {
    id: SERVICE_EDITING,
    value: 'In Edit',
    color: '#F44336'
  }
];

export const activityTypes = [
  {
    href: '/fileshare/progress',
    subType: 'created',
    type: FILETHREAD
  },
  // {
  //   href: '/fileshare/share',
  //   subType: 'shared',
  //   type: FILETHREAD
  // },
  {
    href: '/tickets/demander',
    subType: 'offered',
    type: TICKET
  },
  // {
  //   href: '/procurement/contract',
  //   subType: 'from supplier',
  //   type: CONTRACT
  // },
  // {
  //   href: '/procurement/invoice/delivery',
  //   subType: 'delivery',
  //   type: INVOICE,
  // },
  {
    href: '/procurement/invoice/payment',
    subType: 'payment',
    type: INVOICE
  },
  // {
  //   href: '/procurement/schedule/delivery',
  //   subType: 'created',
  //   type: SCHEDULE
  // },
  // {
  //   href: '/procurement/schedule/payment',
  //   subType: 'created',
  //   type: SCHEDULE
  // },
  // {
  //   href: '/network/referrals/received',
  //   subType: 'received',
  //   type: REFERRAL
  // },
  {
    href: '/network/friends/pending/received',
    subType: 'received',
    type: FRIEND
  }
];

// export const orgActivityTypes = [
//   {
//     href: '/fileshare/progress',
//     subType: 'created',
//     type: FILETHREAD
//   },
//   {
//     href: '/fileshare/share',
//     subType: 'shared',
//     type: FILETHREAD
//   },
//   {
//     href: '/tickets/demander',
//     subType: 'offered',
//     type: TICKET
//   },
//   {
//     href: '/tickets/supplier',
//     subType: 'received',
//     type: TICKET
//   },
//   {
//     href: '/procurement/demander/contract',
//     subType: 'from supplier',
//     type: CONTRACT
//   },
//   {
//     href: '/procurement/supplier/contract',
//     subType: 'from demander',
//     type: CONTRACT
//   },
//   {
//     href: '/procurement/supplier/po',
//     subType: 'created',
//     type: PURCHASEORDER
//   },
//   {
//     href: '/procurement/supplier/rfq',
//     subType: 'created',
//     type: RFQ
//   },
//   {
//     href: '/procurement/demander/invoice/delivery',
//     subType: 'delivery',
//     type: INVOICE,
//   },
//   {
//     href: '/procurement/demander/invoice/payment',
//     subType: 'payment',
//     type: INVOICE
//   },
//   {
//     href: '/network/referrals/buyer/received',
//     subType: 'received',
//     type: REFERRAL
//   },
//   {
//     href: '/network/referrals/seller/received',
//     subType: 'received',
//     type: REFERRAL
//   },
//   {
//     href: '/procurement/supplier/schedule/delivery',
//     subType: 'created',
//     type: SCHEDULE
//   },
//   {
//     href: '/procurement/supplier/schedule/payment',
//     subType: 'created',
//     type: SCHEDULE
//   },
//   {
//     href: '/procurement/demander/schedule/delivery',
//     subType: 'created',
//     type: SCHEDULE
//   },
//   {
//     href: '/procurement/demander/schedule/payment',
//     subType: 'created',
//     type: SCHEDULE
//   },
//   {
//     href: '/network/friends/pending/received',
//     subType: 'received',
//     type: FRIEND
//   }
// ];

// href: '/marketplace/good/management',
// href: '/marketplace/template',
// export const teamActivityTypes = [
//   {
//     href: '/organization/assets/financial-asset',
//     subType: 'assigned',
//     type: ASSET
//   },
//   {
//     href: '/organization/assets/plant-equipment',
//     subType: 'assigned',
//     type: ASSET
//   },
//   {
//     href: '/organization/assets/land-building',
//     subType: 'assigned',
//     type: ASSET
//   },
//   {
//     href: '/organization/assets/intangible-asset',
//     subType: 'assigned',
//     type: ASSET
//   },
//   {
//     href: '/organization/assets/motor-vehicle',
//     subType: 'assigned',
//     type: ASSET
//   },
//   {
//     href: '/microservices/business/demander',
//     subType: 'assigned',
//     type: PURCHASEDITEM
//   },
//   {
//     href: '/microservices/business/supplier',
//     subType: 'assigned',
//     type: PURCHASEDITEM
//   },
//   {
//     href: '/network/friends/pending/sent',
//     subType: 'assigned',
//     type: FRIEND
//   },
//   {
//     href: '/network/friends/pending/received',
//     subType: 'assigned',
//     type: FRIEND
//   },
//   {
//     href: '/network/referrals/buyer/sent',
//     subType: 'assigned',
//     type: REFERRAL
//   },
//   {
//     href: '/network/referrals/buyer/received',
//     subType: 'assigned',
//     type: REFERRAL
//   },
//   {
//     href: '/network/referrals/seller/sent',
//     subType: 'assigned',
//     type: REFERRAL
//   },
//   {
//     href: '/network/referrals/seller/received',
//     subType: 'assigned',
//     type: REFERRAL
//   },
//   {
//     href: '/procurement/supplier/contract',
//     subType: 'assigned',
//     type: CONTRACT
//   },
//   {
//     href: '/procurement/demander/contract',
//     subType: 'assigned',
//     type: CONTRACT
//   },
//   {
//     href: '/procurement/supplier/invoice/delivery',
//     subType: 'assigned',
//     type: INVOICE
//   },
//   {
//     href: '/procurement/supplier/invoice/payment',
//     subType: 'assigned',
//     type: INVOICE
//   },
//   {
//     href: '/procurement/demander/invoice/delivery',
//     subType: 'assigned',
//     type: INVOICE
//   },
//   {
//     href: '/procurement/demander/invoice/payment',
//     subType: 'assigned',
//     type: INVOICE
//   },
//   {
//     href: '/procurement/supplier/po',
//     subType: 'assigned',
//     type: PURCHASEORDER
//   },
//   {
//     href: '/procurement/demander/po',
//     subType: 'assigned',
//     type: PURCHASEORDER
//   },
//   {
//     href: '/procurement/supplier/rfq',
//     subType: 'assigned',
//     type: RFQ
//   },
//   {
//     href: '/procurement/demander/rfq',
//     subType: 'assigned',
//     type: RFQ
//   },
//   {
//     href: '/procurement/supplier/schedule/delivery',
//     subType: 'assigned',
//     type: SCHEDULE
//   },
//   {
//     href: '/procurement/supplier/schedule/payment',
//     subType: 'assigned',
//     type: SCHEDULE
//   },
//   {
//     href: '/procurement/demander/schedule/delivery',
//     subType: 'assigned',
//     type: SCHEDULE
//   },
//   {
//     href: '/procurement/demander/schedule/payment',
//     subType: 'assigned',
//     type: SCHEDULE
//   },
//   {
//     href: '/tickets/demander',
//     subType: 'assigned',
//     type: TICKET
//   },
//   {
//     href: '/tickets/supplier',
//     subType: 'assigned',
//     type: TICKET
//   }
// ];
export const HPCALC_TOOL_DLG = 1;
export const SUPERANNUATION_TOOL_DLG = 2;
export const PROFILING_TOOL_DLG = 3;
export const HPANAL_TOOL_DLG = 3;

export const paymentPlatformTypes = [
  {
    title: 'Fiat Currency Payment Platform Options',
    items: [
      {
        title: 'Allow Credit Card Payments by Stripe',
        paymentMethod: PAY_CREDIT_CARD,
        paymentTool: PAY_STRIPE
      },
      {
        title: 'Allow Direct Debit Payments by Stripe',
        paymentMethod: PAY_DIRECT_DEBIT,
        paymentTool: PAY_STRIPE
      },
      {
        title: 'Allow Credit Card Payments by Bizcore',
        paymentMethod: PAY_CREDIT_CARD,
        paymentTool: PAY_BIZCORE
      },
      {
        title: 'Allow Direct Debit Payments by Bizcore',
        paymentMethod: PAY_DIRECT_DEBIT,
        paymentTool: PAY_BIZCORE
      }
    ]
  },
  {
    title: 'Cryptocurrency Payment Platform Options',
    items: [
      {
        title: 'Allow Manual Payoff by Cryptocurrency Payment System',
        paymentMethod: PAY_CREDIT_CARD,
        paymentTool: PAY_CRYPTOCURRENCY
      },
      {
        title: 'Allow Automatic Payoff by Cryptocurrency Payment System',
        paymentMethod: PAY_DIRECT_DEBIT,
        paymentTool: PAY_CRYPTOCURRENCY
      }
    ]
  }
];

export const UI_PADDING = {
  sm: '80px',
  md: '80px',
  lg: '90px',
  xl: 'calc(5% + 80px)'
};

export function CopyToClipboard(text) {
  navigator.clipboard.writeText(text);
}

export const IPFS_UPLOAD = 1;
export const AWS_UPLOAD = 2;

export const VERIFY_NONE = 0;
export const VERIFY_PROGRESSING = 1;
export const VERIFY_COMPLETED = 2;

export const SERVICE_REQUEST_STEP_INFOCOLLECT = 0;
export const SERVICE_REQUEST_STEP_CONFIRMED = 1;
export const SERVICE_REQUEST_STEP_COMPLETED = 2;

export const SERVICE_OFFER_STEP_INFOCOLLECT = 0;
export const SERVICE_OFFER_STEP_SELECT_CUSTOMERS = 1;

export const SORT_OPTIONS = [
  { label: 'Recently Added', value: 'Descending' },
  { label: 'Recently Updated', value: 'Updated' },
  { label: 'Name', value: 'Ascending' }
];

export const MSG_NONE = 0;
export const MSG_REMOVE = 1;
export const MSG_REMOVE_WITH_OTHER = 2;
export const MSG_REJECT = 3;
export const MSG_SUPPRESS = 4;
export const MSG_NOT_REMOVE = 5;
export const MSG_LEAVE = 6;
export const MSG_ARCHIVE = 7;
export const MSG_NAVIGATE = 8;

export const SUBMIT_NONE = 0;
export const SUBMIT_TO_READY = 1;
export const SUBMIT_PUBLISH = 2;
export const SUBMIT_DUPLICATE = 3;
export const SUBMIT_REMOVE = 4;
export const SUBMIT_SIGN = 5;
export const SUBMIT_PURCHASE = 6;
export const SUBMIT_REJECT = 7;
export const SUBMIT_DOWNLOAD = 8;
export const SUBMIT_CREATE = 9;
export const SUBMIT_INVITE = 10;
export const SUBMIT_AGAIN = 11;
export const SUBMIT_ARCHIVE = 12;
export const SUBMIT_DECLINE = 13;
export const SUBMIT_SUPPRESS = 14;
export const SUBMIT_REGISTER = 15;
export const SUBMIT_RETURN = 16;
export const SUBMIT_CHAT = 17;
export const SUBMIT_POSTPONE = 18;

export const SHOW_NONE = 0;
export const SHOW_DETAIL = 1;
export const SHOW_VERIFY = 2;
export const SHOW_SHARE = 3;
export const SHOW_INVITE = 4;
export const SHOW_ADD_ITEM = 5;

export const BEFORE_RESULT = -1;
export const RESULT_FALSE = 0;
export const RESULT_TRUE = 1;

export const etherscanEndpoint = 'https://goerli.etherscan.io';

export const RATING_PAGE_SIZE = 10;

export const PURPOSE_KEEP = 0;
export const PURPOSE_SALE = 1;
export const PURPOSE_LEASE = 2;

export const ASSET_PURPOSE_KEEP = 0;
export const ASSET_PURPOSE_SALE = 1;
export const ASSET_PURPOSE_LEASE = 2;

export const assetPurposeList = [
  {
    id: ASSET_PURPOSE_KEEP,
    value: 'Keep'
  },
  {
    id: ASSET_PURPOSE_SALE,
    value: 'Sale'
  },
  {
    id: ASSET_PURPOSE_LEASE,
    value: 'Lease'
  }
];

export const ASSET_STATUS_AVAILABLE = 0;
export const ASSET_STATUS_LEASED = 1;
export const ASSET_STATUS_LOANED = 2;

export const assetStatusList = [
  {
    id: ASSET_STATUS_AVAILABLE,
    value: 'Available',
    color: '#4CAF50'
  },
  {
    id: ASSET_STATUS_LEASED,
    value: 'Leased',
    color: '#F44336'
  },
  {
    id: ASSET_STATUS_LOANED,
    value: 'Loaned',
    color: '#FF9800'
  }
];

export const RESOURCE_FILE_TYPE_IMAGE = 0;
export const RESOURCE_FILE_TYPE_VIDEO = 1;
export const RESOURCE_FILE_TYPE_FILE = 2;

export const ASSET_ACTION_TYPE_CREATE = 1;
export const ASSET_ACTION_TYPE_REMOVE = 2;
export const ASSET_ACTION_TYPE_INCREASE = 3;
export const ASSET_ACTION_TYPE_DECREASE = 4;
export const ASSET_ACTION_TYPE_SELL = 5;
export const ASSET_ACTION_TYPE_PURCHASE = 6;
export const ASSET_ACTION_TYPE_LEASE = 7;
export const ASSET_ACTION_TYPE_LOAN = 8;
export const ASSET_ACTION_TYPE_POSTPONE = 9;

export const ASSET_ACTION_STATUS_PENDING = 1;
export const ASSET_ACTION_STATUS_COMPLETED = 2;

export const leaseStatusList = [
  {
    id: ASSET_ACTION_STATUS_PENDING,
    color: '#f381a7',
    text: {
      [ASSET_ACTION_TYPE_LEASE]: 'In Lease',
      [ASSET_ACTION_TYPE_LOAN]: 'In Loan'
    }
  },
  {
    id: ASSET_ACTION_STATUS_COMPLETED,
    color: '#4dabf5',
    text: {
      [ASSET_ACTION_TYPE_LEASE]: 'Completed',
      [ASSET_ACTION_TYPE_LOAN]: 'Completed'
    }
  }
];

export const BANNER_RATIO = '4 / 1';

export const PLATFORM_QBO = 'QBO';
export const PLATFORM_XERO = 'XERO';
