import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { Asset } from 'src/../../Common/Model/asset';
import { Category, SubCategory } from 'src/../../Common/Model/taxonomy';
import { ACCZIOM_TEAM, ASSET_ACTION_STATUS_PENDING, ASSET_ACTION_TYPE_LEASE } from 'src/globals';
import { deepCopy } from 'src/utils/copyObject';
import { AssetAction } from 'src/../../Common/Model/assetAction';

interface AssetState {
  assets: Asset[];
  leaseActions: AssetAction[];
  categories: Category[];
  subCategories: SubCategory[];
  needReload: boolean;
}

const initialState: AssetState = {
  assets: [],
  leaseActions: [],
  categories: [],
  subCategories: [],
  needReload: true
};

const cmpCategoryFn = (a: Category, b: Category): number => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' });
const cmpSubcategoryFn = (a: SubCategory, b: SubCategory): number => a.title.localeCompare(b.title, 'en', { sensitivity: 'base' });

const slice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    clearState(state: AssetState) {
      state.assets.splice(0, state.assets.length);
      state.leaseActions.splice(0, state.leaseActions.length);
      state.categories.splice(0, state.categories.length);
      state.subCategories.splice(0, state.subCategories.length);
      state.needReload = true;
    },
    setNeedReload(state: AssetState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    addAsset(state: AssetState, action: PayloadAction<Asset>): void {
      const asset = action.payload;
      state.assets.push(asset);
    },
    removeAsset(state: AssetState, action: PayloadAction<string>): void {
      const assetId = action.payload as string;
      state.assets = state.assets.filter((asset) => asset.id !== assetId);
    },
    returnAsset(state: AssetState, action: PayloadAction<{ assetId: string[], actionId: string }>) {
      const { assetId, actionId } = action.payload;
      state.assets = state.assets.filter((asset) => !assetId.includes(asset.id));
      state.leaseActions = state.leaseActions.filter((item) => item.id !== actionId);
    },
    setAssets(state: AssetState, action: PayloadAction<{ assets: Asset[]; leaseActions: AssetAction[] }>) {
      const { assets, leaseActions } = action.payload;
      state.assets = assets;
      state.leaseActions = leaseActions;
      state.needReload = false;
    },
    setOwnerAgents(state: AssetState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedAssets = deepCopy(state.assets);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedAssets.find((info) => info.id === id);
        item.ownerAgent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.assets = updatedAssets;
    },
    setTaxonomyInfo(state: AssetState, action: PayloadAction<{ categories: Category[], subCategories: SubCategory[] }>) {
      const { categories, subCategories } = action.payload;
      state.categories = categories;
      state.categories.sort(cmpCategoryFn);
      state.subCategories = subCategories;
      state.subCategories.sort(cmpSubcategoryFn);
    },
    updateAsset(state: AssetState, action: PayloadAction<any>): void {
      const { assetId, asset } = action.payload;
      const index = state.assets.findIndex((item) => item.id === assetId);
      if (index >= 0) state.assets.splice(index, 1, asset);
    },
    updateMultiAssets(state: AssetState, action: PayloadAction<any[]>): void {
      const updateds = action.payload;
      updateds.forEach((updated) => {
        const asset = state.assets.find((item) => item.id === updated.id);
        Object.keys(updated).forEach((key) => {
          if (key !== 'id') asset[key] = updated[key];
        });
      });
    },
    postponeLeaseForDemander(state: AssetState, action: PayloadAction<{ assetId: string, ticketId: string, endDate: Date | string, updatedAt: Date | string }>) {
      const { assetId, ticketId, endDate, updatedAt } = action.payload;
      const leaseAction = state.leaseActions.find((item) => (item.toAssetId.includes(assetId) && item.type === ASSET_ACTION_TYPE_LEASE));
      if (leaseAction) {
        leaseAction.ticketId = [...leaseAction.ticketId, ticketId];
        leaseAction.endDate = endDate;
        leaseAction.status = ASSET_ACTION_STATUS_PENDING;
        leaseAction.updatedAt = updatedAt;
      }
    }
  }
});

export const { reducer } = slice;

export const clearAssetState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};
export const setAssets = (assets: Asset[], leaseActions: AssetAction[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAssets({ assets, leaseActions }));
};
export const addAsset = (asset: Asset): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addAsset(asset));
};
export const updateAsset = (assetId: string, asset: Asset): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateAsset({ assetId, asset }));
};
export const setTaxonomyInfo = (categories: Category[], subCategories: SubCategory[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTaxonomyInfo({ categories, subCategories }));
};
export const setOwnerAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setOwnerAgents(assignedTeams));
};
export const removeAsset = (assetId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeAsset(assetId));
};
export const returnAsset = (assetId: string[], actionId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.returnAsset({ assetId, actionId }));
};
export const updateMultiAssets = (updateds: any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateMultiAssets(updateds));
};
export const postponeLeaseForDemander = (assetId: string, ticketId: string, endDate: Date | string, updatedAt: Date | string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.postponeLeaseForDemander({ assetId, ticketId, endDate, updatedAt }));
};

export default slice;
