import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/pages/authentication/Login';
import { useDispatch, useSelector } from 'src/store';
import { LoadingStatus, setRequestedLocation } from 'src/slices/user';
import SpinnerScreen from './SpinnerScreen';
import initialLoad from 'src/worker/init';
import cleanUp from 'src/utils/cleanUp';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const { pathname } = useLocation();
  const { loadingStatus, requestedLocation } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  if (!auth.isAuthenticated) {
    cleanUp();
    return <Login />;
  }

  if (loadingStatus !== LoadingStatus.COMPLETED) {
    if (loadingStatus === LoadingStatus.NONE) {
      let subdomain = '';
      if (process.env.NODE_ENV === 'production') {
        const domain = window.location.hostname;
        if (domain !== 'orgs.m.accziom.com') {
          const idx = domain.indexOf('.orgs.m.accziom.com');
          if (idx <= 0) return <Navigate to="/error" />;
          subdomain = domain.substring(0, idx);
        }
      }
      initialLoad(auth.user.id, auth.checkLive, dispatch, subdomain);
    }
    return <SpinnerScreen />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    dispatch(setRequestedLocation(null));
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
