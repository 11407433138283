import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type {
  Microservice,
} from 'src/../../Common/Model/microservice';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { MicroserviceData } from 'src/types/microservice';
import { ACCZIOM_TEAM, SERVICE_PUBLISHED, SERVICE_READY, SERVICE_REMOVED } from 'src/globals';
import { deepCopy } from 'src/utils/copyObject';

interface ServiceState {
  activeServiceId?: string;
  services: Microservice[];
  data: {
    past: NodeModel<MicroserviceData>[][],
    present: NodeModel<MicroserviceData>[],
    future: NodeModel<MicroserviceData>[][]
  };
  bChanged: boolean;
  anzicCode: any;
  needReload: boolean;
}

const initialState: ServiceState = {
  activeServiceId: null,
  services: [],
  data: {
    past: [],
    present: [],
    future: []
  },
  bChanged: false,
  anzicCode: null,
  needReload: true
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    clearState(state: ServiceState) {
      state.services.splice(0, state.services.length);
      state.data = {
        past: [],
        present: [],
        future: []
      };
      state.bChanged = false;
      state.needReload = true;
    },
    setServices(state: ServiceState, action: PayloadAction<{ services: Microservice[]; }>) {
      const { services } = action.payload;
      state.services = [...services];
      state.needReload = false;
    },
    setNeedReload(state: ServiceState, action: PayloadAction<boolean>): void {
      state.needReload = action.payload;
    },
    setActiveService(state: ServiceState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.activeServiceId = id;
      state.bChanged = false;
      state.data = {
        past: [],
        present: [],
        future: []
      };
    },
    addNewService(state: ServiceState, action: PayloadAction<{ service: Microservice; }>) {
      const { service } = action.payload;
      state.services.push(service);
      state.activeServiceId = service.id;
    },
    updateService(state: ServiceState, action: PayloadAction<{ service: Microservice; }>) {
      const { service } = action.payload;
      const index = state.services.findIndex((svc) => svc.id === service.id);
      if (index > -1) state.services[index] = service;
      state.bChanged = false;
    },
    removeService(state: ServiceState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.services.findIndex((svc) => svc.id === id);
      state.services.splice(index, 1);
      state.activeServiceId = null;
    },
    removeServiceByStat(state: ServiceState, action: PayloadAction<{ removed: number; id : string; updatedAt: Date | string; }>) {
      const { removed, id, updatedAt } = action.payload;
      if (removed > 0) {
        const index = state.services.findIndex((svc) => svc.id === id);
        state.services.splice(index, 1);
      } else {
        const service = state.services.find((svc) => svc.id === id);
        service.stat = SERVICE_REMOVED;
        service.updatedAt = updatedAt;
      }
      state.activeServiceId = null;
    },
    setData(state: ServiceState, action: PayloadAction<{ data: NodeModel<MicroserviceData>[]; isInitial: boolean; }>) {
      const { data, isInitial } = action.payload;
      if (!isInitial) state.data.past.push(state.data.present);
      state.data.present = data;
      state.data.future = [];
    },
    setChanged(state: ServiceState) {
      state.bChanged = true;
    },
    publishService(state: ServiceState, action: PayloadAction<{ id: string; }>) {
      const { id } = action.payload;
      const index = state.services.findIndex((svc) => svc.id === id);
      if (index === -1) return;
      state.services[index].stat = SERVICE_PUBLISHED;
      state.services[index].publishedAt = new Date().toISOString();
    },
    undo(state: ServiceState) {
      if (state.data.past.length > 0) {
        state.data.future = [
          state.data.present,
          ...state.data.future
        ];
        state.data.present = [
          ...state.data.past[state.data.past.length - 1]
        ];
        state.data.past.splice(-1);
        state.bChanged = true;
      }
    },
    redo(state: ServiceState) {
      if (state.data.future.length > 0) {
        state.data.past = [
          ...state.data.past,
          state.data.present,
        ];
        state.data.present = [
          ...state.data.future[0]
        ];
        state.data.future.shift();
        state.bChanged = true;
      }
    },
    setCreatorAgents(state: ServiceState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      const updatedServices = deepCopy(state.services);
      assignedTeams.forEach(({ id, teamId }) => {
        const item = updatedServices.find((info) => info.id === id);
        item.agent = { id: teamId, type: ACCZIOM_TEAM };
      });
      state.services = updatedServices;
    },
    setAnzicCode(state: ServiceState, action: PayloadAction<any>): void {
      state.anzicCode = action.payload;
    },
    setServiceToReady(state: ServiceState, action: PayloadAction<{ duplicated: number; oldId: string; newId: string; updatedAt: Date | string; }>): void {
      const { duplicated, oldId, newId, updatedAt } = action.payload;
      const oldService = state.services.find((service) => service.id === oldId);
      if (duplicated > 0) {
        const newService = deepCopy(oldService);
        newService.id = newId;
        newService.stat = SERVICE_READY;
        newService.updatedAt = updatedAt;
        state.services.push(newService);
        oldService.stat = newId;
        oldService.updatedAt = updatedAt;
      } else if (oldService.stat === SERVICE_PUBLISHED) {
        oldService.stat = SERVICE_READY;
        oldService.updatedAt = updatedAt;
      }
    }
  }
});

export const { reducer } = slice;

export const clearMsTemplateState = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearState());
};

export const loadServices = (services: Microservice[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setServices({ services }));
};

export const setNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setNeedReload(needReload));
};

export const setActiveService = (value : string): AppThunk => async (dispatch) => {
  const id = value;
  dispatch(slice.actions.setActiveService({ id }));
};

export const addNewService = (service : Microservice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewService({ service }));
};

export const removeService = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeService({ id }));
};

export const removeServiceByStat = (removed: number, id : string, updatedAt: Date | string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeServiceByStat({ removed, id, updatedAt }));
};

export const updateService = (service : Microservice): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateService({ service }));
};

export const resetActiveService = (): AppThunk => async (dispatch) => {
  const id = null;
  dispatch(slice.actions.setActiveService({ id }));
};

export const setData = (data:NodeModel<MicroserviceData>[], isInitial = false): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setData({ data, isInitial }));
};
export const setChanged = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setChanged());
};
export const undo = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.undo());
};
export const redo = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.redo());
};
export const publishService = (id:string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.publishService({ id }));
};
export const setCreatorAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCreatorAgents(assignedTeams));
};
export const setAnzicCode = (code: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAnzicCode(code));
};
export const setServiceToReady = (duplicated: number, oldId: string, newId: string, updatedAt: Date | string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setServiceToReady({ duplicated, oldId, newId, updatedAt }));
};
export default slice;
