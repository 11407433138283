import type { FC } from 'react';
import {
  Avatar,
  Box,
  ListItemText,
  MenuItem,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Item } from './DashboardTopbar';
import { Link as RouterLink } from 'react-router-dom';
import { createCustomTheme } from 'src/theme';
import useSettings from 'src/hooks/useSettings';

interface Props {
  onClose: () => void;
  icon: any;
  title: string;
  items: Item[];
}

const PopularPopover: FC<Props> = ({ onClose, items, title, icon: Icon }) => {
  const { settings } = useSettings();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: 'CUSTOM'
  });
  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          p: 2
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
            backgroundColor: 'primary.main',
            color: 'primary.contrastText'
          }}
        >
          <Icon />
        </Avatar>
        <Typography
          variant="h6"
          sx={{
            height: 40,
            pl: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {title}
        </Typography>
      </Box>
      {items.map((item) => (
        <MenuItem
          key={`topbar_${item.title}`}
          component={RouterLink}
          sx={{
            pl: 6
          }}
          to={item.href}
          onClick={onClose}
        >
          {/* <ListItemIcon>
            <ContactPageOutlined fontSize="small" />
          </ListItemIcon> */}
          <ListItemText
            primary={(
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {item.title}
              </Typography>
            )}
          />
        </MenuItem>
        // <ListItem
        //   divider
        //   key={`topbar_${item.title}`}
        // >
        //   <ListItemText
        //     primary={(
        //       <Link
        //         color="textPrimary"
        //         sx={{ cursor: 'pointer' }}
        //         underline="none"
        //         variant="subtitle2"
        //         href={item.href}
        //         onClick={onClose}
        //       >
        //         {item.title}
        //       </Link>
        //     )}
        //   />
        // </ListItem>
      ))}
    </ThemeProvider>
  );
};

PopularPopover.propTypes = {
  onClose: PropTypes.func,
  items: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
};
export default PopularPopover;
