import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import objFromArray from 'src/utils/objFromArray';
import type {
  Goods,
} from 'src/../../Common/Model/marketplace';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { ACCZIOM_TEAM } from 'src/globals';

interface GoodsState {
  isInitialised: boolean,
  needReload: boolean,
  needReloadProposal: boolean,
  needRefresh: boolean,
  activeGoodsId?: string,
  goodss: {
    byId: Record<string, Goods>;
    allIds: string[];
  },
  dataTemplate: NodeModel[],
  templateId: string,
  dataGoods: any[],
  data: NodeModel[],
  refresh: boolean,
  goodThumbs: Record<string, string>;
  buyingGoods: Goods[];
  requestGood: Goods;
  offerGoodIds: string[];
}

const initialState: GoodsState = {
  isInitialised: false,
  needReload: true,
  needReloadProposal: true,
  needRefresh: true,
  activeGoodsId: null,
  goodss: {
    byId: {},
    allIds: [],
  },
  dataTemplate: [],
  templateId: null,
  dataGoods: [],
  data: [],
  refresh: false,
  goodThumbs: {},
  buyingGoods: [],
  requestGood: null,
  offerGoodIds: []
};

const slice = createSlice({
  name: 'goods',
  initialState,
  reducers: {
    initializeGoodsState(state: GoodsState, action: PayloadAction<{ isInitialised: boolean; }>) {
      const { isInitialised } = action.payload;
      state.isInitialised = isInitialised;
    },
    setGoodss(state: GoodsState, action: PayloadAction<{ goodss: Goods[]; }>) {
      const { goodss } = action.payload;
      state.goodss.byId = objFromArray(goodss);
      state.goodss.allIds = Object.keys(state.goodss.byId);
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
      state.needReloadProposal = false;
      state.needReload = false;
    },
    setActiveGoods(state: GoodsState, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      if (id !== null) state.templateId = state.goodss.byId[id].templateId;
      state.activeGoodsId = id;
      state.refresh = !state.refresh;
    },
    addNewGoods(state: GoodsState, action: PayloadAction<{ goods: Goods; }>) {
      state.isInitialised = false;
      const { goods } = action.payload;
      state.goodss.byId[goods.id] = goods;
      state.goodss.allIds = Object.keys(state.goodss.byId);
      state.activeGoodsId = goods.id;
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
    },
    updateGoods(state: GoodsState, action: PayloadAction<{ goods: Goods; }>) {
      state.isInitialised = false;
      const { goods } = action.payload;
      state.goodss.byId[goods.id] = goods;
      state.goodss.allIds = Object.keys(state.goodss.byId);
      state.activeGoodsId = goods.id;
      state.isInitialised = true;
      state.needRefresh = !state.needRefresh;
      // state.needReloadProposal = true;
    },
    removeGoods(state: GoodsState, action: PayloadAction<{ id: string; }>) {
      state.isInitialised = false;
      const { id } = action.payload;
      delete state.goodss.byId[id];
      state.goodss.allIds = Object.keys(state.goodss.byId);
      state.needRefresh = !state.needRefresh;
      state.isInitialised = true;
      state.activeGoodsId = null;
      // state.needReloadProposal = true;
    },
    setNeedReload(state: GoodsState, action: PayloadAction<{ needReload: boolean; }>) {
      const { needReload } = action.payload;
      state.needReload = needReload;
    },
    setNeedReloadProposal(state: GoodsState) {
      state.needReloadProposal = !state.needReloadProposal;
    },
    setNeedRefresh(state: GoodsState) {
      state.needRefresh = !state.needRefresh;
    },
    clearAllGoodsSlice(state: GoodsState) {
      state.isInitialised = false;
      state.goodss.allIds = [];
      state.goodss.byId = null;
      state.needReload = true;
      state.needReloadProposal = true;
      state.needRefresh = true;
      state.activeGoodsId = null;
      state.dataTemplate = [];
      state.templateId = null;
    },
    setDataTemplate(state: GoodsState, action: PayloadAction<{ dataTemplate: NodeModel[]; }>) {
      const { dataTemplate } = action.payload;
      state.dataTemplate = dataTemplate;
    },
    setTemplateId(state: GoodsState, action: PayloadAction<{ templateId: string; }>) {
      const { templateId } = action.payload;
      state.templateId = templateId;
    },
    setDataGoods(state: GoodsState, action: PayloadAction<{ dataGoods: NodeModel[]; }>) {
      const { dataGoods } = action.payload;
      state.dataGoods = dataGoods;
    },
    setData(state: GoodsState, action: PayloadAction<{ data: any[]; }>) {
      const { data } = action.payload;
      state.data = data;
    },
    setGoodThumbs(state: GoodsState, action: PayloadAction<{ id: string; url: string }[]>) {
      const info = action.payload;
      info.forEach(({ id, url }) => {
        state.goodThumbs[id] = url;
      });
    },
    setBuyingGoods(state: GoodsState, action: PayloadAction<{ goods: Goods[] }>) {
      const { goods } = action.payload;
      state.buyingGoods = goods;
    },
    addBuyingGoods(state: GoodsState, action: PayloadAction<{ goods: Goods[] }>) {
      const { goods } = action.payload;
      state.buyingGoods = [
        ...state.buyingGoods,
        ...goods.filter(
          (good) => !state.buyingGoods.find((ag) => ag.id === good.id)
        )
      ];
    },
    setRequestGood(state: GoodsState, action: PayloadAction<{ good: Goods }>) {
      const { good } = action.payload;
      state.requestGood = good;
    },
    setOfferGoodIds(state: GoodsState, action: PayloadAction<string[]>) {
      state.offerGoodIds = action.payload;
    },
    setAgents(state: GoodsState, action: PayloadAction<any>): void {
      const assignedTeams = action.payload;
      assignedTeams.forEach(({ id, teamId }) => {
        state.goodss.byId[id] = {
          ...state.goodss.byId[id],
          ownerAgent: { id: teamId, type: ACCZIOM_TEAM }
        };
      });
    }
  }
});

export const { reducer } = slice;

export const initializeGoodsState = (value: boolean): AppThunk => async (dispatch) => {
  const isInitialised = value;
  console.log(isInitialised);
  dispatch(slice.actions.initializeGoodsState({ isInitialised }));
};

export const loadGoodss = (goodss: Goods[]): AppThunk => async (dispatch) => {
  goodss.sort((b, a) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  dispatch(slice.actions.setGoodss({ goodss }));
};

export const setActiveGoods = (value : string): AppThunk => async (dispatch) => {
  const id = value;
  dispatch(slice.actions.setActiveGoods({ id }));
};

export const addNewGoods = (goods : Goods): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addNewGoods({ goods }));
};
export const removeGoods = (id : string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeGoods({ id }));
};

export const updateGoods = (goods : Goods): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateGoods({ goods }));
};

export const resetActiveGoods = (): AppThunk => async (dispatch) => {
  const id = null;
  dispatch(slice.actions.setActiveGoods({ id }));
};
export const clearAllGoodsSlice = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAllGoodsSlice());
};
export const setNeedReload = (value:boolean): AppThunk => async (dispatch) => {
  const needReload = value;
  dispatch(slice.actions.setNeedReload({ needReload }));
};
export const setNeedReloadProposal = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNeedReloadProposal());
};
export const setNeedRefresh = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setNeedRefresh());
};
export const setDataTemplate = (dataTemplate:NodeModel[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDataTemplate({ dataTemplate }));
};
export const setTemplateId = (templateId:string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setTemplateId({ templateId }));
};
export const setDataGoods = (dataGoods:NodeModel[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setDataGoods({ dataGoods }));
};
export const setData = (data:any[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setData({ data }));
};
export const setGoodThumbs = (data: { id: string; url: string }[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setGoodThumbs(data));
};
export const setBuyingGoods = (goods: Goods[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setBuyingGoods({ goods }));
};
export const addBuyingGoods = (goods: Goods[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addBuyingGoods({ goods }));
};
export const setRequestGood = (good: Goods): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setRequestGood({ good }));
};
export const setOfferGoodIds = (goodIds: string[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setOfferGoodIds(goodIds));
};
export const setAgents = (assignedTeams: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setAgents(assignedTeams));
};
export default slice;
