import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { Contact } from 'src/../../Common/Model/contact';

interface ContactState {
  contacts: Contact[];
  contactsNeedReload: boolean;
  connectState: { qbo: string; xero: string; },
  connectStateNeedReload: boolean;
  syncState: { qbo: boolean; xero: boolean; },
  syncStateNeedReload: boolean;
  importData: any;
  importedFields: any[];
  contactToUsers: any[];
}

const initialState: ContactState = {
  contacts: [],
  contactsNeedReload: true,
  connectState: { qbo: null, xero: null },
  connectStateNeedReload: true,
  syncState: { qbo: false, xero: false },
  syncStateNeedReload: true,
  importData: {
    filename: '',
    data: null
  },
  importedFields: [],
  contactToUsers: []
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    clearState(state: ContactState) {
      state.contacts.splice(0, state.contacts.length);
      state.contactsNeedReload = true;
      state.connectState = { qbo: null, xero: null };
      state.connectStateNeedReload = true;
      state.syncState = { qbo: false, xero: false };
      state.syncStateNeedReload = true;
      state.contactToUsers.splice(0, state.contactToUsers.length);
    },
    setContacts(state: ContactState, action: PayloadAction<Contact[]>): void {
      state.contacts = action.payload as Contact[];
      state.contactsNeedReload = false;
    },
    setContactsNeedReload(state: ContactState, action: PayloadAction<boolean>): void {
      state.contactsNeedReload = action.payload;
    },
    addContact(state: ContactState, action: PayloadAction<Contact>): void {
      state.contacts.push(action.payload);
    },
    updateContact(state: ContactState, action: PayloadAction<Contact>): void {
      const contact = action.payload;
      state.contacts = state.contacts.map((cont) => (cont.id === contact.id ? contact : cont));
    },
    deleteContact(state: ContactState, action: PayloadAction<string>): void {
      const contactId = action.payload;
      state.contacts = state.contacts.filter((contact) => contact.id !== contactId);
    },
    setConnectState(state: ContactState, action: PayloadAction<any>): void {
      const { connectedQbo, connectedXero } = action.payload;
      state.connectState.qbo = connectedQbo;
      state.connectState.xero = connectedXero;
      state.connectStateNeedReload = false;
    },
    setConnectStateNeedReload(state: ContactState, action: PayloadAction<boolean>): void {
      state.connectStateNeedReload = action.payload;
    },
    setSyncState(state: ContactState, action: PayloadAction<any>): void {
      const { syncQbo, syncXero } = action.payload;
      state.syncState.qbo = syncQbo;
      state.syncState.xero = syncXero;
      state.syncStateNeedReload = false;
    },
    setSyncStateNeedReload(state: ContactState, action: PayloadAction<boolean>): void {
      state.syncStateNeedReload = action.payload;
    },
    setImportData(state: ContactState, action: PayloadAction<any>): void {
      const { filename, data } = action.payload;
      state.importData.filename = filename;
      state.importData.data = data;
    },
    setImportedFields(state: ContactState, action: PayloadAction<any>): void {
      const { importFields, selectFields } = action.payload;
      state.importedFields.splice(0, state.importedFields.length);
      importFields.forEach((idx) => state.importedFields.push({ importIdx: idx, contactIdx: selectFields[idx] }));
    },
    addContactToUsers(state: ContactState, action: PayloadAction<any>): void {
      const { contactId, userInfos } = action.payload;
      if (state.contactToUsers.filter((data) => data.cid === contactId).length < 1) state.contactToUsers.push({ cid: contactId, userData: userInfos });
    }
  }
});

export const clearContactState = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.clearState());
};

export const setContacts = (cs: Contact[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setContacts(cs));
};

export const setContactsNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setContactsNeedReload(needReload));
};

export const addContact = (c: Contact) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addContact(c));
};

export const updateContact = (c: Contact) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateContact(c));
};

export const deleteContact = (contactId: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.deleteContact(contactId));
};

export const setConnectState = (_connectedQbo: string, _connectedXero: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConnectState({ connectedQbo: _connectedQbo, connectedXero: _connectedXero }));
};

export const setSyncState = (_syncQbo: boolean, _syncXero: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSyncState({ syncQbo: _syncQbo, syncXero: _syncXero }));
};

export const setConnectStateNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setConnectStateNeedReload(needReload));
};

export const setSyncStateNeedReload = (needReload: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setSyncStateNeedReload(needReload));
};

export const setImportData = (_filename: string, _data: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setImportData({ filename: _filename, data: _data }));
};

export const setImportedFields = (_importFields: number[], _selectFields: string[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setImportedFields({ importFields: _importFields, selectFields: _selectFields }));
};

export const addContactToUsers = (_cId: string, _userData: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.addContactToUsers({ contactId: _cId, userInfos: _userData }));
};

export const { reducer } = slice;

export default slice;
