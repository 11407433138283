import { io } from 'socket.io-client';
import { accsiomURIs } from 'src/config';
import { Organization } from 'src/../../Common/Model/organization';
import { ACCZIOM_ORG } from 'src/globals';
import store from 'src/store';
import { addApprisings, removeApprising } from 'src/slices/apprising';
import { setInvitationNeedReload } from 'src/slices/network';
import type { Apprising } from 'src/../../Common/Model/apprising';
import { setNeedReload as setFileshareNeedReload } from 'src/slices/fileshare';
import { setNeedReload as setTicketNeedReload } from 'src/slices/request';
import { getPossibleHrefs } from 'src/utils/filterUrlsByRole';

const RECEIVE_CODE = 'receive';
const APPRISING_TYPE_ADD = 'add';
const APPRISING_TYPE_REMOVE = 'remove';

export const wsSocket = io(
  // process.env.NODE_ENV === 'production' ? accsiomURIs.filethread_url : 'http://localhost:8080',
  accsiomURIs.apprising_ws_url,
  { autoConnect: false }
);

export const wsConnect = (orgInfo: Organization) => {
  wsSocket.auth = {
    id: orgInfo.organizationId,
    type: ACCZIOM_ORG,
    name: orgInfo.tradingName
  };
  wsSocket.connect();
};

export const wsDisconnect = () => {
  wsSocket.disconnect();
};

wsSocket.on(RECEIVE_CODE, (params) => {
  const { content } = params;
  const appr = content.apprising as Apprising;
  const hrefs = getPossibleHrefs();
  if (appr.href !== '/myfiles/share' && !hrefs.some((href) => appr.href.startsWith(href))) return;
  if (content.type === APPRISING_TYPE_ADD && appr.type !== 'signed') {
    store.dispatch(addApprisings([appr]));
  } else if (content.type === APPRISING_TYPE_REMOVE) {
    store.dispatch(removeApprising(appr.id));
  }
  if (appr.href === '/network/friends/pending/received') {
    store.dispatch(setInvitationNeedReload(true));
  } else if (appr.href.startsWith('/myfiles')) {
    store.dispatch(setFileshareNeedReload(true));
  } else if (appr.href.startsWith('/tickets') || appr.href.startsWith('/procurement')) {
    store.dispatch(setTicketNeedReload(true));
  }
});
