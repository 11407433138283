import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { MicroserviceInfo } from 'src/../../Common/Model/microservice';
import { SERVICE_REQUEST_STEP_INFOCOLLECT } from 'src/globals';
import { Request } from 'src/../../Common/Model/request';

export interface MicroserviceForReq extends MicroserviceInfo {
  periodInfo?: any;
  selectedPaymentOption?: string;
  selectedPaymentPlatform?: any;
  reqTitle?: string;
  hiMsg?: string;
  sid?: string;
  count?: number;
  answers?: any;
  completedQuestionnaire?: boolean;
}
interface ServiceRequestState {
  service: MicroserviceForReq;
  bundledServices: MicroserviceForReq[];
  step: number;
  agreedEngagement: boolean;
  request: Request;
  curItemToUserId: string;
  activeBannerUrl: string;
}

const initialState: ServiceRequestState = {
  service: null,
  bundledServices: [],
  step: SERVICE_REQUEST_STEP_INFOCOLLECT,
  agreedEngagement: false,
  request: null,
  curItemToUserId: '',
  activeBannerUrl: null
};

const slice = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    clearState(state: ServiceRequestState): void {
      state.service = null;
      state.bundledServices.splice(0, state.bundledServices.length);
      state.step = SERVICE_REQUEST_STEP_INFOCOLLECT;
      state.agreedEngagement = false;
      state.request = null;
      state.curItemToUserId = '';
      if (state.activeBannerUrl) window.URL.revokeObjectURL(state.activeBannerUrl);
      state.activeBannerUrl = null;
    },
    setService(state: ServiceRequestState, action: PayloadAction<MicroserviceForReq>): void {
      state.service = action.payload;
    },
    setBundledServices(state: ServiceRequestState, action: PayloadAction<MicroserviceForReq[]>): void {
      state.bundledServices = action.payload;
    },
    updateService(state: ServiceRequestState, action: PayloadAction<any>): void {
      state.service = {
        ...state.service,
        ...action.payload
      };
    },
    updateBundledService(state: ServiceRequestState, action: PayloadAction<{ id: string; sid: string; info: any; }>): void {
      const { id, sid, info } = action.payload;
      state.bundledServices = state.bundledServices.map((bs) => {
        if (bs.id === id && bs.sid === sid) {
          return {
            ...bs,
            ...info
          };
        }
        return bs;
      });
    },
    setStep(state: ServiceRequestState, action: PayloadAction<number>): void {
      state.step = action.payload;
    },
    setAgreeEngagement(state: ServiceRequestState, action: PayloadAction<boolean>): void {
      state.agreedEngagement = action.payload;
    },
    setRequest(state: ServiceRequestState, action: PayloadAction<Request>): void {
      state.request = action.payload;
    },
    setCurItemToUserId(state: ServiceRequestState, action: PayloadAction<string>): void {
      state.curItemToUserId = action.payload;
    },
    setActiveBannerUrl(state: ServiceRequestState, action: PayloadAction<string>): void {
      if (state.activeBannerUrl) window.URL.revokeObjectURL(state.activeBannerUrl);
      state.activeBannerUrl = action.payload;
    }
  }
});

export const clearServiceRequestState = () : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.clearState());
};

export const setService = (service: MicroserviceForReq) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setService(service));
};

export const setBundledServices = (services: MicroserviceForReq[]) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setBundledServices(services));
};

export const updateService = (info: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateService(info));
};

export const updateBundledService = (id: string, sid: string, info: any) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.updateBundledService({ id, sid, info }));
};

export const setStep = (step: number) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setStep(step));
};

export const setAgreeEngagement = (val: boolean) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setAgreeEngagement(val));
};

export const setRequest = (request: Request) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setRequest(request));
};

export const setCurItemToUserId = (id: string) : AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.setCurItemToUserId(id));
};

export const setActiveBannerUrl = (url: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setActiveBannerUrl(url));
};

export const { reducer } = slice;

export default slice;
