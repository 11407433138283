import { combineReducers } from '@reduxjs/toolkit';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as msTemplateReducer } from 'src/slices/msTemplate';
import { reducer as contactReducer } from 'src/slices/contact';
import { reducer as procurementReducer } from 'src/slices/procurement';
import { reducer as apprisingReducer } from 'src/slices/apprising';
import { reducer as walletReducer } from 'src/slices/wallet';
import { reducer as payAccountReducer } from 'src/slices/payAccount';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as fileshareReducer } from 'src/slices/fileshare';
import { reducer as organizationReducer } from 'src/slices/organization';
import { reducer as requestReducer } from 'src/slices/request';
import { reducer as businessReducer } from 'src/slices/business';
import { reducer as assetReducer } from 'src/slices/asset';
import { reducer as templateReducer } from 'src/slices/marketplace_template';
import { reducer as goodsReducer } from 'src/slices/marketplace_goods';
import { reducer as iouReducer } from 'src/slices/iou';
import { reducer as wsSyncReducer } from 'src/slices/wsSync';
import { reducer as networkReducer } from 'src/slices/network';
import { reducer as itemReducer } from 'src/slices/item';
import { reducer as favoriteReducer } from 'src/slices/favorite';
import { reducer as serviceRequestReducer } from 'src/slices/serviceRequest';

const rootReducer = combineReducers({
  chat: chatReducer,
  user: userReducer,
  msTemplate: msTemplateReducer,
  contact: contactReducer,
  procurement: procurementReducer,
  apprising: apprisingReducer,
  wallet: walletReducer,
  payAccount: payAccountReducer,
  kanban: kanbanReducer,
  fileshare: fileshareReducer,
  organization: organizationReducer,
  request: requestReducer,
  business: businessReducer,
  asset: assetReducer,
  template: templateReducer,
  goods: goodsReducer,
  iou: iouReducer,
  wsSync: wsSyncReducer,
  network: networkReducer,
  item: itemReducer,
  favorite: favoriteReducer,
  serviceRequest: serviceRequestReducer
});

export default rootReducer;
