import store from 'src/store';
import { memberRoleInfo } from 'src/globals';

export const getPossibleHrefs = (): string[] => {
  const hrefs: string[] = [];
  const { memberInfos, roleInfos, activeOrgId, activeTeamId } = store.getState().organization;
  const activeRoleId = memberInfos.find((memberInfo) => memberInfo.oid === activeOrgId).rid;
  const activeRole = roleInfos.find((roleInfo) => roleInfo.rid === activeRoleId);

  memberRoleInfo.forEach((firstItem) => {
    const firstKey = firstItem.key;
    if (firstKey === 'department' || firstKey === 'assignment' || ((firstKey === 'production' || firstKey === 'transaction') && !!activeTeamId)) {
      firstItem.value.forEach((secondItem) => {
        const secondKey = secondItem.key;
        secondItem.value.forEach((thirdItem) => {
          const thirdKey = thirdItem.key;
          const thirdHref = thirdItem.href;
          if (activeRole[firstKey][secondKey][thirdKey]) {
            thirdHref.forEach((href) => {
              if (!hrefs.includes(href)) hrefs.push(href);
            });
          }
        });
      });
    }
  });

  return hrefs;
};

export const getPossibleItems = (items: any[], hrefs: string[] = null): any[] => {
  if (hrefs === null) hrefs = getPossibleHrefs();
  const mm = [];

  items.forEach((item) => {
    if (hrefs.filter((href) => item.href.startsWith(href)).length > 0) mm.push(item);
    else if (item.items?.length > 0) {
      const subItems = [];
      item.items.forEach((subItem) => {
        if (hrefs.filter((href) => subItem.href.startsWith(href)).length > 0) subItems.push(subItem);
        else if (subItem.items?.length > 0) {
          const subSubItems = [];
          subItem.items.forEach((subSubItem) => {
            if (hrefs.filter((href) => subSubItem.href.startsWith(href)).length > 0) subSubItems.push(subSubItem);
          });
          if (subSubItems.length > 0) {
            subItems.push({
              ...subItem,
              items: subSubItems
            });
          }
        }
      });
      if (subItems.length > 0) {
        mm.push({
          ...item,
          items: subItems
        });
      }
    }
  });

  return mm;
};
